import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
// validation Formik
import * as Yup from "yup";
import { ErrorMessage, Field, FieldArray, Formik } from "formik";
import config from "../../../config";
import yupconfig from "../../../yupconfig";
import { InputField, ReactSelectField, SelectField, TextareaField, SwitchField, InputPickerField } from "../../../component/form/Field";
import { swalConfirmInput, swalErrorHtml } from "../../../component/Sweatalert2";
import PropTypes from "prop-types";
import { EditAppointmentForm, appointmentUpdateApi, appointmentListViewApi, ClientAppointmentListViewApi, appointmentDetailApi, appointmentStoreApi, AddAppointmentForm, EditAppointmentFormData, OpenEditAppointmentRefunds, CancellationReason, GetAppointmentcancelRefundStripe, CalendarEditFormSetActivebtnState, OpenInvoiceStripePayModal, OpenDepositPayTab, GetStripeTokenAppointment, AppointmentDetailModal, AppointmentResourceItemsClears, AppointmentResourceItemsApi, AppointmentAutoFillFinalData } from "../../../store/slices/appointmentSlice";
import { servicePriceApi } from "../../../store/slices/serviceSlice";
import DatePicker from "react-multi-date-picker";
import moment from "moment";
import { MinutesToHours, getHours, getMinutes, TimePickerDropdown, HoursToMinutes, usercheckAccess } from "helpers/Functions";
import { decimalOnly } from "../../../component/form/Validation";
import { busytimeListViewApi } from "store/slices/busytimeSlice";
import customToast from "component/Toastr";
import Moment from "react-moment";
import { CancellationreasonListViewApi } from "store/slices/cancellationreasonSlice";
import { GetAvailableTime, GetTotalDurationWithPadding } from "component/form/GetAvailableTime";
import { AddOnData, AddOnDataRemove, AddOnResourceOptionsApi, AddOnServicePriceApi, AddOnServicesApi, AddonStaffOptionsApi } from "store/slices/addonSlice";
import { AddAnotherData, AddAnotherDataRemove, AddAnotherResourceOptionsApi, AddAnotherServicePriceApi, AddAnotherServicesApi, AddAnotherStaffOptionsApi } from "store/slices/addanotherSlice";
import { useNavigate } from "react-router";
import { OpenAppointmentConfirmationAlerts, OpenCustomAlerts } from "store/slices/commonSlice";
import BounceLoader from "react-spinners/BounceLoader";
import { AddonGetAvailableTime } from "component/form/AddonGetAvailableTime";
import { AddAnotherGetAvailableTime } from "component/form/AddAnotherGetAvailableTime";
import BarLoader from "react-spinners/BarLoader";

const AppointmentEditForm = (props) => {
  const [loading, setLoading] = useState(false);
  const [activeLoading, setActiveLoading] = useState({
    addonLoading: false,
    addanotherLoading: false
  });
  const [showModal, setShowModal] = useState(false);
  const [confirmedRefund, setConfirmedRefund] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [NoRefund, setNoRefund] = useState(false);
  const [BtnConfirmation, setBtnConfirmation] = useState(false);
  // const [clientId, setClientId] = useState("");
  const navigate = useNavigate();
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [openStripeSetupModal, setOpenStripeSetupModal] = useState(false);
  const rightDrawerOpened = useSelector((state) => state.appointment.isOpenedEditForm);
  const isOpenDepositPayTab = useSelector((state) => state.appointment.isOpenDepositPayTab);
  const [selectedCancellationReason, setSelectedCancellationReason] = useState('');
  const [showError, setShowError] = useState(false);
  const auth = useSelector((state) => state.auth);
  const currentUser = auth.user;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const notServicesIdRef = useRef();
  const totalAddonServiceCostRef = useRef();
  const totalAddonServiceDurationRef = useRef();
  const notAnotherServicesIdRef = useRef();
  const totalAddanotherServiceCostRef = useRef();
  const totalAddanotherServiceDurationRef = useRef();
  const isRangeInfo = props.isRangeInfo;
  const page = props.page;
  const isCalendarEditFormSetActivebtnState = useSelector((state) => state.appointment.isCalendarEditFormSetActivebtnState);
  const isStripeTokenAppointment = useSelector((state) => state.appointment.isStripeTokenAppointment);
  const isServiceOption = useSelector((state) => state.service.isServiceOption);
  const isStaffOption = useSelector((state) => state.staff.isStaffOption);
  const isServicePrice = useSelector((state) => state.service.isServicePrice);
  const detail = useSelector((state) => state.appointment.isDetailData);
  const cancellationreason = useSelector((state) => state.cancellationreason.isListView);
  const cancellationreasonObjectData = cancellationreason && cancellationreason.data ? cancellationreason.data : cancellationreason;
  const isStaffAvailableTimeApi = useSelector((state) => state.staff.isStaffAvailableTime);
  const isAddOn = useSelector((state) => state.addon.isAddOn);
  const isAddOnApiStatus = useSelector((state) => state.addon.isApiStatus);
  const isAddAnother = useSelector((state) => state.addanother.isAddAnother);
  const isAddAnotherApiStatus = useSelector((state) => state.addanother.isApiStatus);
  const role_id = currentUser && currentUser.role_id;
  const single_staff_access = usercheckAccess("dashboard_staff_view", currentUser);
  const singleStaffAccess = role_id === 8 ? '' : (single_staff_access && currentUser && currentUser.is_owner === 0) ? 1 : '';
  const depositPaid = detail && detail.payment.length > 0 && detail.payment.filter((payment) => payment.type === "Deposit").reduce((sum, payment) => sum + parseFloat(payment.amount), 0);
  const depositRefund = detail && detail.payment.length > 0 && detail.payment.filter((payment) => payment.type === "DepositRefund").reduce((sum, payment) => sum + parseFloat(payment.amount), 0);
  const depositBalance = depositPaid && depositPaid >= depositRefund ? depositPaid - depositRefund : 0;
  const AddOnServiceButtonRenders = useSelector((state) => state.appointment.isAddOnServiceButtonRender);
  const isAppointmentResourceItems = useSelector((state) => state.appointment.isAppointmentResourceItems);
  const appointmentApiStatus = useSelector((state) => state.appointment.isApiStatus);
  const AddAnotherLoader = (isAddAnotherApiStatus && (isAddAnotherApiStatus.StaffAvailableTimeApi === "loading")) || (isAddAnotherApiStatus.AddAnotherServicePriceApi === "loading") || (isAddAnotherApiStatus.AddAnotherStaffOptionsApi === "loading");
  const AddOnLoader = (isAddOnApiStatus && (isAddOnApiStatus.StaffAvailableTimeApi === "loading")) || (isAddOnApiStatus.AddOnServicePriceApi === "loading") || (isAddOnApiStatus.AddonStaffOptionsApi === "loading");
  const isFilter = useSelector((state) => state.appointment.isClientAppointmentFilterStatus);
  // useEffect(() => {
  //   setActiveLoading({
  //     addonLoading: detail?.addonservices && detail.addonservices.length > 0,
  //     addanotherLoading: detail?.addanotherservices && detail.addanotherservices.length > 0
  //   });
  //   if (detail.addonservices) {
  //     detail.addonservices.map((item, getFieldNameIndex) => {
  //       dispatch(AddOnServicePriceApi({ parent_service_id: detail.service_id, parent_staff_id: detail.staff_id, service_id: item.service_id, index_id: getFieldNameIndex })).then((action) => {
  //         if (action.meta.requestStatus === "fulfilled") {
  //           let service = action.payload;
  //           // let indexID = getFieldNameIndex + 1;
  //           let indexID = getFieldNameIndex;
  //           const addOnData = isAddOn.find((event) => event.id === indexID);
  //           let addonobj = { ...addOnData, serviceprice: action.payload, duration: service.duration };


  //           dispatch(AddonStaffOptionsApi({ option: { valueField: "users.id", labelField: "CONCAT(users.first_name,' ',users.last_name)" }, service_id: service.id, pagetype: "Booking" })).then((action1) => {
  //             if (action1.meta.requestStatus === "fulfilled") {
  //               if (addonobj) {
  //                 setActiveLoading(false)
  //                 dispatch(AddOnData({ ...addonobj, staff: action1.payload }));
  //               }
  //             }
  //           });

  //           dispatch(AddOnResourceOptionsApi({ option: { valueField: "id", labelField: "resource_item" }, service_id: service.id, pagetype: "Booking" })).then((action1) => {
  //             if (action1.meta.requestStatus === "fulfilled") {
  //               if (addonobj) {
  //                 setActiveLoading(false)
  //                 dispatch(AddOnData({ ...addonobj, resource_items: action1.payload }));
  //               }
  //             }
  //           });

  //           // const addOnResourceOptionsAction = dispatch(AddOnResourceOptionsApi({ option: { valueField: "id", labelField: "resource_item" }, service_id: service.id, pagetype: "Booking" }));
  //           // const addOnStaffOptionsAction = dispatch(AddonStaffOptionsApi({ option: { valueField: "users.id", labelField: "CONCAT(users.first_name,' ',users.last_name)" }, service_id: service.id, pagetype: "Booking" }));

  //           // Promise.all([addOnResourceOptionsAction, addOnStaffOptionsAction]).then(([action1, action2]) => {
  //           //   setActiveLoading((prevLoading) => ({ ...prevLoading, addonLoading: false }));
  //           //   if (action1.meta.requestStatus === "fulfilled" && action2.meta.requestStatus === "fulfilled") {
  //           //     if (addonobj) {
  //           //       dispatch(AddOnData({ ...addonobj, staff: action2.payload, resource_items: action1.payload }));
  //           //     }
  //           //   }
  //           // });
  //         }
  //       });
  //     });
  //   }
  //   if (detail && detail.addanotherservices && detail.addanotherservices.length > 0) {
  //     detail.addanotherservices.map((item, getFieldNameIndex) => {
  //       dispatch(AddAnotherServicePriceApi({ parent_service_id: detail.service_id, parent_staff_id: detail.staff_id, service_id: item.service_id, index_id: getFieldNameIndex })).then((action) => {
  //         // dispatch(AddAnotherServicePriceApi({ parent_service_id: detail.service_id, parent_staff_id: detail.staff_id, service_id: item.service_id, index_id: getFieldNameIndex })).then((action) => {
  //         if (action.meta.requestStatus === "fulfilled") {
  //           let service = action.payload;
  //           let indexID = getFieldNameIndex + 1;
  //           const addanotherData = isAddAnother.find((event) => event.id === indexID);
  //           let addanotherobj = { ...addanotherData, serviceprice: action.payload, duration: service.duration };
  //           const addAnotherResourceOptionsAction = dispatch(AddAnotherResourceOptionsApi({ option: { valueField: "id", labelField: "resource_item" }, service_id: service.id, pagetype: "Booking", }));
  //           const addAnotherStaffOptionsAction = dispatch(AddAnotherStaffOptionsApi({ option: { valueField: "users.id", labelField: "CONCAT(users.first_name,' ',users.last_name)" }, service_id: service.id, pagetype: "Booking", }));
  //           Promise.all([addAnotherResourceOptionsAction, addAnotherStaffOptionsAction]).then(([action1, action2]) => {
  //             setActiveLoading((prevLoading) => ({ ...prevLoading, addanotherLoading: false }));
  //             if (action1.meta.requestStatus === "fulfilled" && action2.meta.requestStatus === "fulfilled") {
  //               if (addanotherobj) {
  //                 dispatch(AddAnotherData({ ...addanotherobj, staff: action2.payload, resource_items: action1.payload }));
  //               }
  //             }
  //           });
  //         }
  //       });
  //     });
  //   }
  // }, [detail]);

  useEffect(() => {
    setActiveLoading({
      addonLoading: detail?.addonservices && detail.addonservices.length > 0,
      addanotherLoading: detail?.addanotherservices && detail.addanotherservices.length > 0
    });
    const remove_addon = detail?.addonservices?.length > 0
      ? detail?.addonservices.map(item => item.id)
      : [];

    const remove_addanother = detail?.addanotherservices?.length > 0
      ? detail?.addanotherservices.map(item => item.id)
      : [];

    if (detail.addonservices) {
      detail.addonservices.map((item, getFieldNameIndex) => {
        dispatch(AddOnServicePriceApi({ parent_service_id: detail.service_id, parent_staff_id: detail.staff_id, service_id: item.service_id, index_id: getFieldNameIndex })).then((action) => {
          if (action.meta.requestStatus === "fulfilled") {
            let service = action.payload;
            let indexID = getFieldNameIndex + 1;
            const addOnData = isAddOn.find((event) => event.id === indexID);
            // console.log('item',item, addOnData);
            let addonobj = { ...addOnData, serviceprice: action.payload, duration: MinutesToHours(item.duration), padding_time: item.padding_time ? MinutesToHours(item.padding_time) : "" };

            const addonservices = detail && detail.addonservices.length > 0 ? detail.addonservices.slice(0, getFieldNameIndex) : "";

            let parentAppointmentEndTime = "";

            if (addonservices && addonservices.length > 0) {
              const totalAddOnDurationWithPadding = addonservices && addonservices.length > 0 && addonservices.reduce((sum, sduration) => {
                // Check if sduration.durationWithPadding is a valid duration
                const duration = moment.duration(sduration.durationWithPadding);
                if (duration.isValid()) {
                  const sumDurationWithPadding = moment.duration(sduration.durationWithPadding);
                  return sum.add(sumDurationWithPadding);
                }
                return sum;
              }, moment.duration(0));
              // console.log('isNotInResourceIds',isNotInResourceIds);

              const parentAppointmentDuration = item && item.durationWithPadding ? item.durationWithPadding : "";
              const duration1 = moment.duration(parentAppointmentDuration);
              const duration2 = totalAddOnDurationWithPadding;

              // Check if each duration is valid before using it
              const minutes1 = duration1 && duration1.isValid() ? duration1.asMinutes() : 0;
              const minutes2 = duration2 && duration2.isValid() ? duration2.asMinutes() : 0;

              const totalMinutes = minutes1 + minutes2;
              const hours = Math.floor(totalMinutes / 60);
              const minutes = totalMinutes % 60;

              const sumDuration = moment({ hours, minutes });
              const SumOfDuration = sumDuration ? sumDuration.format('HH:mm') : "";

              const durationMoment = moment.duration(SumOfDuration);

              const appointmentStartDateTime = moment(`${detail?.start_datetime}`, "dddd, DD MMMM YYYY HH:mm");
              parentAppointmentEndTime = appointmentStartDateTime.clone().add(durationMoment).format('YYYY-MM-DD HH:mm:00');
              // isNotInResourceIds.push(form.values.resource_item_id);
            } else {
              const appointmentStartDateTime = moment(`${detail?.start_datetime}`, "dddd, DD MMMM YYYY HH:mm");
              const durationMoment = moment.duration(detail?.durationWithPadding);

              parentAppointmentEndTime = appointmentStartDateTime.clone().add(durationMoment).format('YYYY-MM-DD HH:mm:00');
            }

            const availebleTimeObj = {
              appointment_id: item && item.id ? item.id : "",
              parent_id: item && item.parent_id ? item.parent_id : "",
              busytime_id: "",
              dateof: detail && detail.dateof ? detail.dateof : "",
              start_datetime: item && item.start_datetime ? item.start_datetime : "",
              duration: item && item.duration ? MinutesToHours(item.duration) : "",
              padding_time: item && item.padding_time ? MinutesToHours(item.padding_time) : "",
              durationWithPadding: item && item.durationWithPadding ? item.durationWithPadding : "",
              listview: "Appointment",
              salon_id: item && item.salon_id ? item.salon_id : "",
              service_id: item && item.service_id ? item.service_id : "",
              staff_id: item && item.staff_id ? item.staff_id : "",
              resource_item_id: item && item.resource_item_id ? item.resource_item_id : "",
              parentAppointmentEndTime: item && item.start_datetime ? item.start_datetime : "",
              // uid: 1,
              remove_addon: remove_addon || [],
              remove_addanother: remove_addanother || [],
              formtype: "appointmentForm",
              appointment_type: "addonservices",
              formName: "appointmentForm/edit/addon/resource_item_id"
            }
            const addOnResourceOptionsAction = dispatch(AddOnResourceOptionsApi({ option: { valueField: "id", labelField: "resource_item" }, service_id: service.id, availebleTimeObj, pagetype: "Booking", formtype: "edit_appointment", formName: "appointmentForm/edit/addon/resource_item_id" }));
            const addOnStaffOptionsAction = dispatch(AddonStaffOptionsApi({ option: { valueField: "users.id", labelField: "CONCAT(users.first_name,' ',users.last_name)" }, service_id: service.id, pagetype: "Booking", formName: "appointmentForm/edit/addon/staff_id", availebleTimeObj }));
            Promise.all([addOnResourceOptionsAction, addOnStaffOptionsAction]).then(([action1, action2]) => {
              setActiveLoading((prevLoading) => ({ ...prevLoading, addonLoading: false }));
              if (action1.meta.requestStatus === "fulfilled" && action2.meta.requestStatus === "fulfilled") {
                if (addonobj) {
                  dispatch(AddOnData({ ...addonobj, staff: action2.payload, resource_items: action1.payload }));
                }
              }
            });
          }
        });
      });
    }
    if (detail && detail.addanotherservices && detail.addanotherservices.length > 0) {
      detail.addanotherservices.map((item, getFieldNameIndex) => {
        dispatch(AddAnotherServicePriceApi({ parent_service_id: detail.service_id, parent_staff_id: detail.staff_id, service_id: item.service_id, index_id: getFieldNameIndex })).then((action) => {
          // dispatch(AddAnotherServicePriceApi({ parent_service_id: detail.service_id, parent_staff_id: detail.staff_id, service_id: item.service_id, index_id: getFieldNameIndex })).then((action) => {
          if (action.meta.requestStatus === "fulfilled") {
            let service = action.payload;
            let indexID = getFieldNameIndex + 1;
            const addanotherData = isAddAnother.find((event) => event.id === indexID);
            let addanotherobj = { ...addanotherData, serviceprice: action.payload, duration: MinutesToHours(item.duration), padding_time: item.padding_time ? MinutesToHours(item.padding_time) : "" };

            // const addonservices = [...detail.addonservices];
            //   const addanotherservices = [...detail.addanotherservices];

            //   let parentAppointmentEndTime = "";
            //   let isNotInResourceIds = [];
            //   if (detail.resource_item_id) {
            //     isNotInResourceIds.push(detail.resource_item_id);
            //   }
            //   const totalAddOnDurationWithPadding = addonservices && addonservices.length > 0 && addonservices.reduce((sum, sduration) => {
            //     // Check if sduration.durationWithPadding is a valid duration
            //     const duration = moment.duration(sduration.durationWithPadding);

            //     if (duration.isValid()) {
            //       const sumDurationWithPadding = moment.duration(sduration.durationWithPadding);
            //       if (sduration.resource_item_id) {
            //         isNotInResourceIds.push(sduration.resource_item_id);
            //       }
            //       return sum.add(sumDurationWithPadding);
            //     }
            //     return sum;
            //   }, moment.duration(0));
            //   // console.log('form',  addanotherservices);
            //   // if (addanotherservices && addanotherservices.length > 1) {
            //   //   addanotherservices.pop();

            //   if (addanotherservices && addanotherservices.length > 1) {
            //     const addanotherservicesFilter = addanotherservices.slice(0, getFieldNameIndex);
            //     //console.log('addanotherservicesFilter', getFieldNameIndex, addanotherservicesFilter);
            //     const totalAddAnotherDurationWithPadding = addanotherservicesFilter.length > 0 && addanotherservicesFilter.reduce((sum, sduration) => {
            //       // Check if sduration.durationWithPadding is a valid duration
            //       const duration = moment.duration(sduration.durationWithPadding);
            //       if (duration.isValid()) {
            //         const sumDurationWithPadding = moment.duration(sduration.durationWithPadding);
            //         if (sduration.resource_item_id) {
            //           isNotInResourceIds.push(sduration.resource_item_id);
            //         }
            //         return sum.add(sumDurationWithPadding);
            //       }
            //       return sum;
            //     }, moment.duration(0));

            //     const parentAppointmentDuration = detail.durationWithPadding;
            //     const duration1 = moment.duration(parentAppointmentDuration);
            //     const duration2 = totalAddOnDurationWithPadding;
            //     const duration3 = totalAddAnotherDurationWithPadding;

            //     // Check if each duration is valid before using it
            //     const minutes1 = duration1 && duration1.isValid() ? duration1.asMinutes() : 0;
            //     const minutes2 = duration2 && duration2.isValid() ? duration2.asMinutes() : 0;
            //     const minutes3 = duration3 && duration3.isValid() ? duration3.asMinutes() : 0;

            //     const totalMinutes = minutes1 + minutes2 + minutes3;
            //     const hours = Math.floor(totalMinutes / 60);
            //     const minutes = totalMinutes % 60;

            //     const sumDuration = moment({ hours, minutes });
            //     const SumOfDuration = sumDuration ? sumDuration.format('HH:mm') : "";

            //     const durationMoment = moment.duration(SumOfDuration);

            //     const appointmentStartDateTime = moment(`${detail.start_datetime}`, "dddd, DD MMMM YYYY HH:mm");
            //     parentAppointmentEndTime = appointmentStartDateTime.clone().add(durationMoment).format('YYYY-MM-DD HH:mm:00');

            //   } else {
            //     const appointmentStartDateTime = moment(`${detail.start_datetime}`, "dddd, DD MMMM YYYY HH:mm");
            //     const duration1 = moment.duration(detail.durationWithPadding);
            //     const duration2 = totalAddOnDurationWithPadding;
            //     // Check if each duration is valid before using it
            //     const minutes1 = duration1 && duration1.isValid() ? duration1.asMinutes() : 0;
            //     const minutes2 = duration2 && duration2.isValid() ? duration2.asMinutes() : 0;

            //     const totalMinutes = minutes1 + minutes2;
            //     const hours = Math.floor(totalMinutes / 60);
            //     const minutes = totalMinutes % 60;

            //     const sumDuration = moment({ hours, minutes });
            //     const SumOfDuration = sumDuration ? sumDuration.format('HH:mm') : "";

            //     const durationMoment = moment.duration(SumOfDuration);
            //     parentAppointmentEndTime = appointmentStartDateTime.clone().add(durationMoment).format('YYYY-MM-DD HH:mm:00');
            //   }

            const availebleTimeObj = {
              appointment_id: item && item.id ? item.id : "",
              parent_id: item && item.parent_id ? item.parent_id : "",
              busytime_id: "",
              dateof: detail && detail.start_datetime ? detail.start_datetime : "",
              start_datetime: item && item.start_datetime ? item.start_datetime : "",
              duration: item && item.duration ? MinutesToHours(item.duration) : "",
              padding_time: item && item.padding_time ? MinutesToHours(item.padding_time) : "",
              durationWithPadding: item && item.durationWithPadding ? item.durationWithPadding : "",
              listview: "Appointment",
              salon_id: item && item.salon_id ? item.salon_id : "",
              service_id: item && item.service_id ? item.service_id : "",
              staff_id: item && item.staff_id ? item.staff_id : "",
              resource_item_id: item && item.resource_item_id ? item.resource_item_id : "",
              parentAppointmentEndTime: item && item.start_datetime ? item.start_datetime : "",
              // uid: 1,
              remove_addon: remove_addon || [],
              remove_addanother: remove_addanother || [],
              formtype: "appointmentForm",
              appointment_type: "addanotherservices",
            }
            const addAnotherResourceOptionsAction = dispatch(AddAnotherResourceOptionsApi({ option: { valueField: "id", labelField: "resource_item" }, service_id: service.id, pagetype: "Booking", formtype: "edit_appointment", formName: "appointmentForm/edit/addanother/resource_item_id", availebleTimeObj: { ...availebleTimeObj, formName: "appointmentForm/edit/addanother/resource_item_id" } }));
            const addAnotherStaffOptionsAction = dispatch(AddAnotherStaffOptionsApi({ option: { valueField: "users.id", labelField: "CONCAT(users.first_name,' ',users.last_name)" }, service_id: service.id, pagetype: "Booking", formName: "appointmentForm/edit/addanother/staff_id", availebleTimeObj: { ...availebleTimeObj, formName: "appointmentForm/edit/addanother/staff_id" } }));
            Promise.all([addAnotherResourceOptionsAction, addAnotherStaffOptionsAction]).then(([action1, action2]) => {
              setActiveLoading((prevLoading) => ({ ...prevLoading, addanotherLoading: false }));
              if (action1.meta.requestStatus === "fulfilled" && action2.meta.requestStatus === "fulfilled") {
                if (addanotherobj) {
                  dispatch(AddAnotherData({ ...addanotherobj, staff: action2.payload, resource_items: action1.payload }));
                }
              }
            });
          }
        });
      });
    }
  }, [detail]);

  let TimePickerOption = [];
  if (isStaffAvailableTimeApi && isStaffAvailableTimeApi.time && isStaffAvailableTimeApi.time.length > 0) {
    isStaffAvailableTimeApi.time.map((time) => {
      const timef = moment(time, "HH:mm:ss").format("hh:mma");
      TimePickerOption.push({ id: timef, value: timef });
    });
  }

  const handleEditAppointmentForm = () => {
    dispatch(EditAppointmentForm(true));
    setTimeout(() => {
      dispatch(EditAppointmentForm(""));
    }, config.modalCloseTimeOut);
  };

  const initialValues = {
    form_types: "edit_appointment",
    isDepositTabOpen: "",
    client_id: "",
    service_id: "",
    staff_id: "",
    dateof: "",
    start_time: "",
    duration: "",
    padding_time: "",
    durationWithPadding: "",
    cost: "",
    repeats: "",
    booking_notes: "",
    status: "",
    status_manage: "",
    repeats: "",
    repeat_time: "",
    repeat_time_option: "",
    ending: "",
    resource_id: "",
    resource_item_id: "",
    resource_required: false,
    addonservices: [],
    addanotherservices: [],
    service_deposit: [],
    repeat_appointment: "",
    previous_deposit_paid: "",
    type: "UpdateAppointment",
    deposit_payment_by: "",
    stripe_token_id: "",
    totaldeposit: "0.00",
    prev_start_datetime: "",
    deposit_booked_online: [],
    remove_addanother: [],
    remove_addon: [],
  };

  const validationSchema = Yup.object().shape({
    client_id: Yup.lazy((val) => (Array.isArray(val) ? Yup.array().of(Yup.string()).nullable().min(1).required() : Yup.string().nullable().label(t("Client")).required())),
    service_id: Yup.lazy((val) => (Array.isArray(val) ? Yup.array().of(Yup.string()).nullable().min(1).required() : Yup.string().nullable().label(t("Service")).required())),
    staff_id: Yup.lazy((val) => (Array.isArray(val) ? Yup.array().of(Yup.string()).nullable().min(1).required() : Yup.string().nullable().label(t("Staff")).required())),
    dateof: Yup.date().label(t("Date")).required(),
    // .min(new Date(Date.now() - 86400000), t("Date cannot be in the past")),
    start_time: Yup.string().trim().label(t("Start Time")).required(),
    duration: Yup.string().trim().matches(config.duration_pattern, t(config.duration_HM_error)).label(t("Duration")).required(),
    cost: Yup.string().trim().label(t("Cost")).required().test("Decimal only", t("The field should have decimal only"), decimalOnly),
    repeats: Yup.string().trim().label(t("Repeats")).required(),
    booking_notes: Yup.string().trim().label(t("Booking Notes")),
    repeats: Yup.string().trim().label(t("Repeats")).required(),
    repeat_time: Yup.string()
      .nullable()
      .when("repeats", {
        is: "Yes",
        then: () => Yup.string().trim().label(t("Repeat time")).required(t("Required")).test("Digits only", t("Digits only"), decimalOnly),
      }),
    repeat_time_option: Yup.string()
      .nullable()
      .when("repeats", {
        is: "Yes",
        then: () => Yup.string().trim().label(t("Repeat time option")).required(t("Required")),
      }),
    totaldeposit: Yup.number()
      .nullable()
      .when(["isDepositTabOpen"], {
        is: (tabOpen) => {
          if (tabOpen) {
            return true;
          } else {
            return false;
          }
        },
        then: () => Yup.number()
          .required(t("Deposit is required"))
          .typeError(t("Total deposit must be a number"))
          .min(0.0, t("Total deposit must be at least 0"))
          .test('is-not-negative', t("Negative values are not allowed"), value => value >= 0.0),
      }),
    resource_item_id: Yup.string()
      .nullable()
      .when(["service_id", "resource_required"], {
        is: (service, resourceRequired) => {
          if (service && resourceRequired) {
            return true;
          } else {
            return false;
          }
        },
        then: () => Yup.string().trim().label(t("Resource")).required(),
      }),
    // ending: Yup.date()
    //   .label(t("Ending (Optional)"))
    //   .min(Yup.ref("dateof"), t("Ending date must be after the start date"))
    //   .test("is-valid-date", t("Ending date must be after the start date"), function (value) {
    //     const { path, createError } = this;
    //     const dateof = this.resolve(Yup.ref("dateof"));
    //     const minimumDate = new Date(dateof);
    //     minimumDate.setDate(minimumDate.getDate() + 7);
    //     if (!value || value >= minimumDate) {
    //       return true;
    //     }

    //     return createError({ path, message: t("Ending date must be after the start date") });
    //   })
    //   .min(new Date(), t("Date cannot be in the past")),
  });
  yupconfig();

  const handleAppointmentSubmit = (values, { setErrors, setStatus, setSubmitting, resetForm }) => {
    // return;
    setLoading(true);
    try {
      if (values && values.repeat_appointment) {
        dispatch(appointmentStoreApi(values)).then((action) => {
          if (action.meta.requestStatus === "fulfilled") {
            // let data = action.payload;
            // let startdate = data.date + "T" + data.start_time;
            setStatus({ success: true });
            resetForm();
            dispatch(servicePriceApi({ service_id: "" }));
            dispatch(AddAppointmentForm(true));
            setTimeout(() => {
              dispatch(AddAppointmentForm(""));
            }, config.modalOpenTimeOut);
            dispatch(ClientAppointmentListViewApi({ client_id: values.client_id, client: values.client_id, client_id: values.client_id, filter: isFilter, page_name: "client_appointment" }));
            if (isRangeInfo) {
              dispatch(appointmentListViewApi(isRangeInfo));
              dispatch(busytimeListViewApi(isRangeInfo));
            }
            customToast.success(`${t("Appointment Booked Successfully")}`);
            setLoading(false);
          } else if (action.meta.requestStatus === "rejected") {
            const status = action.payload && action.payload.status;
            const errors = action.payload && action.payload.message && action.payload.message.errors;
            const response = action.payload && action.payload.message && action.payload.message;
            if (status === 422) {
              const NotifyContent = () => {
                return (
                  <>
                    <p className="mb-2 text-light text-justify">{response && response.message}</p>
                    <ul className="list-unstyled">
                      {errors &&
                        Object.keys(errors).map((a, n) => {
                          if (errors[a].length > 0) {
                            return (
                              <>
                                {errors[a].map((as, ns) => {
                                  return (
                                    <li key={n + "-" + ns} className="text-light form-text text-start">
                                      {as}
                                    </li>
                                  );
                                })}
                              </>
                            );
                          }
                        })}
                    </ul>
                  </>
                );
              };
              customToast.error(<NotifyContent />);
              // swalErrorHtml({ title: "Warning", html: <NotifyContent />, icon: "error" });
              setErrors(errors);
              setStatus({ success: false });
              setLoading(false);
            } else if (status === 410) {
              const NotifyContent = () => {
                return (
                  <>
                    <p className="mb-2 text-white text-justify">{response && response.message}</p>
                    {response && response.booked && (
                      <>
                        <p className="mb-2 text-white text-justify">{t("Staff are available at this time.")}</p>
                        {response && response.dateof && <Moment format="MMMM DD YYYY">{response.dateof}</Moment>}
                        <ul className="list-unstyled">
                          {response.booked.map((a, n) => (
                            <li key={n} className="text-light form-text">
                              <Moment format="hh:mm A">{response.dateof + "T" + a}</Moment>
                            </li>
                          ))}
                        </ul>
                      </>
                    )}
                  </>
                );
              };
              customToast.success(<NotifyContent />, { icon: false });
              setStatus({ warning: response && response.message, booked: response && response.booked });
              setLoading(false);
            } else {
              setLoading(false);
            }
            setSubmitting(false);
          }
        });
      } else {
        dispatch(appointmentUpdateApi(values)).then((action) => {
          if (action.meta.requestStatus === "fulfilled") {
            const payload = action.payload;
            setStatus({ success: true });
            dispatch(servicePriceApi({ service_id: "" }));
            dispatch(EditAppointmentForm(true));
            setTimeout(() => {
              dispatch(EditAppointmentForm(""));
            }, config.modalCloseTimeOut);
            dispatch(ClientAppointmentListViewApi({ client_id: detail.client_id, client: detail.client_id, filter: isFilter, page_name: "client_appointment" }));
            if (isRangeInfo) {
              dispatch(appointmentListViewApi(isRangeInfo));
              dispatch(appointmentDetailApi(payload));
              dispatch(busytimeListViewApi(isRangeInfo));
            }
            customToast.success(`${t("Appointment Booked Successfully")}`);
            setLoading(false);
          } else if (action.meta.requestStatus === "rejected") {
            const status = action.payload && action.payload.status;
            const errors = action.payload && action.payload.message && action.payload.message.errors;
            const response = action.payload && action.payload.message && action.payload.message;
            if (status === 422) {
              dispatch(
                OpenCustomAlerts({
                  isOpen: "open",
                  errors: (
                    <>
                      <p className="mb-2 text-danger salert text-justify">{response && response.message}</p>
                      <ul className="list-unstyled">
                        {errors &&
                          Object.keys(errors).map((a, n) => {
                            if (errors[a].length > 0) {
                              return (
                                <>
                                  {errors[a].map((as, ns) => {
                                    return (
                                      <li key={n + "-" + ns} className="text-danger salert form-text text-start">
                                        {as}
                                      </li>
                                    );
                                  })}
                                </>
                              );
                            }
                          })}
                      </ul>
                    </>
                  )
                })
              );
              setErrors(errors);
              setStatus({ success: false });
              setLoading(false);
            } else if (status === 410) {
              const NotifyContent = () => {
                return (
                  <>
                    <p className="mb-2 text-white text-justify">{response && response.message}</p>
                    {response && response.booked && (
                      <>
                        <p className="mb-2 text-white text-justify">{t("Staff are available at this time.")}</p>
                        {response && response.dateof && <Moment format="MMMM DD YYYY">{response.dateof}</Moment>}
                        <ul className="list-unstyled">
                          {response.booked.map((a, n) => (
                            <li key={n} className="text-light form-text">
                              <Moment format="hh:mm A">{response.dateof + "T" + a}</Moment>
                            </li>
                          ))}
                        </ul>
                      </>
                    )}
                  </>
                );
              };
              customToast.success(<NotifyContent />);
              setStatus({ warning: response && response.message, booked: response && response.booked });
              setLoading(false);
            } else {
              setLoading(false);
            }
            setSubmitting(false);
          }
        });
      }
    } catch (err) {
      setErrors(err.message);
      setStatus({ success: false });
      setLoading(false);
    }
  };

  const serviceOptionsData = isServiceOption;
  const staffOptionsData = isStaffOption.length > 0 ? isStaffOption : null;
  const resourceOptionsData = isAppointmentResourceItems && isAppointmentResourceItems.length > 0 ? isAppointmentResourceItems : null;
  const repeatsOptionsData = [
    { value: "No", label: t("No") },
    { value: "Yes", label: t("Yes") },
  ];
  const repeattimeOptionsData = [
    { value: "Weekly", label: t("Week(s)") },
    { value: "Monthly", label: t("Month(s)") },
    // { value: "Yearly", label: t("Year(s)") },
  ];
  const statusmanageOptionsData = [
    { value: "Not Started", label: t("Not Started") },
    { value: "Started", label: t("Started") },
  ];
  const removeItem = (array, index) => {
    const newArray = [...array.slice(0, index), ...array.slice(index + 1)];
    return newArray;
  };
  var cancellationreasondatawithvalue = {};
  cancellationreasonObjectData.map((elm, i) => {
    return (cancellationreasondatawithvalue[elm.id] = elm.reason);
  });
  return (
    <>
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleAppointmentSubmit}>
        {(formik) => {
          console.log('formik.values: ', formik.values);

          useEffect(() => {
            formik.setFieldValue("isDepositTabOpen", isOpenDepositPayTab)
          }, [isOpenDepositPayTab]);

          useEffect(() => {
            if (isStripeTokenAppointment) {
              formik.setFieldValue("stripe_token_id", isStripeTokenAppointment, true);
              formik.setFieldValue("deposit_payment_by", "StripeCreditCard", true);
            }
          }, [isStripeTokenAppointment])
          useEffect(() => {
            const remove_addon = detail?.addonservices?.length > 0
              ? detail?.addonservices.map(item => item.id)
              : [];

            const remove_addanother = detail?.addanotherservices?.length > 0
              ? detail?.addanotherservices.map(item => item.id)
              : [];
            formik.setFieldValue('remove_addon', remove_addon);
            formik.setFieldValue('remove_addanother', remove_addanother);
            // if (typeof isServicePrice !== "undefined" && Array.isArray(isServicePrice) && isServicePrice.length === 0 && detail) {
            if (detail) {


              const fields = ["id", "client_id", "service_id", "staff_id", "showdate", "showstartdate", "showenddate", "start_datetime", "duration", 'padding_time', 'durationWithPadding', "cost", "repeats", "repeat_time", "repeat_time_option", "ending", "booking_notes", "status", "status_manage", "addonservices", "addanotherservices", "repeat_appointment", "resource_item_id"];

              fields.forEach((field) => {
                if (["showstartdate"].includes(field)) {
                  let detail_field_dateof = detail[field] ? moment(detail[field]).format("dddd, DD MMMM YYYY") : "";
                  let detail_field_start_time = detail[field] ? moment(detail[field]).format("HH:mm") : "";
                  formik.setFieldValue(field, formik.values[field] ? formik.values[field] : detail[field], false);
                  formik.setFieldValue("dateof", formik.values["dateof"] ? formik.values["dateof"] : detail_field_dateof, false);
                  formik.setFieldValue("start_time", formik.values["start_time"] ? formik.values["start_time"] : detail_field_start_time, false);
                } else if (["showenddate", "showdate"].includes(field)) {
                  let detail_field = detail[field];
                  formik.setFieldValue(field, formik.values[field] ? formik.values[field] : detail_field, false);
                } else if (["start_datetime"].includes(field)) {
                  let detail_field = detail[field];
                  formik.setFieldValue("prev_start_datetime", formik.values[field] ? formik.values[field] : detail_field, false);
                } else if (["cost"].includes(field)) {
                  let detail_field = detail[field] ? detail[field] : "0.00";
                  formik.setFieldValue("cost", formik.values[field] ? formik.values[field] : detail_field, false);
                } else if (["ending"].includes(field)) {
                  let detail_field = detail[field] ? moment(detail[field], "YYYY-MM-DD HH:mm:ss").format("DD MMMM YYYY") : "";
                  formik.setFieldValue(field, formik.values[field] ? formik.values[field] : detail_field, false);
                } else if (["duration", "padding_time"].includes(field)) {
                  let detail_field = detail[field] > 0 ? MinutesToHours(detail[field]) : "";
                  formik.setFieldValue(field, formik.values[field] ? formik.values[field] : detail_field, false);
                } else if (["status_manage"].includes(field)) {
                  let detail_field = detail[field] ? detail[field] : "Not Started";
                  formik.setFieldValue(field, formik.values[field] ? formik.values[field] : detail_field, false);
                } else if (["status"].includes(field)) {
                  // if (detail[field] === "Confirmed") {
                  //   formik.setFieldValue(field, 1, false);
                  // } else {
                  //   formik.setFieldValue(field, "", false);
                  // }
                  let detail_field = detail[field] ? detail[field] : "";
                  formik.setFieldValue(field, formik.values[field] ? formik.values[field] : detail_field, false);
                } else if (["addonservices"].includes(field)) {
                  if (detail[field] && detail[field].length > 0) {
                    let s = [];
                    detail[field].map((item) => {
                      let id = item.id ? item.id : "";
                      let parent_id = item.parent_id ? item.parent_id : "";
                      let service_name = item.service ? item.service.name : "";
                      let duration = item.duration ? MinutesToHours(item.duration) : "";
                      let padding_time = item.padding_time ? MinutesToHours(item.padding_time) : "";
                      let durationWithPadding = item && item.padding_time && item.padding_time > 0 ? MinutesToHours(parseInt(item.padding_time) + parseInt(item.duration)) : (item && item.duration ? MinutesToHours(item.duration) : "")
                      s.push({
                        id: id,
                        parent_id,
                        service_id: item.service_id,
                        service_name: service_name,
                        staff_id: item.staff_id,
                        resource_item_id: item.resource_item_id,
                        duration: duration,
                        padding_time,
                        durationWithPadding,
                        cost: item.cost,
                        prevObj: {
                          id: id,
                          parent_id,
                          service_id: item.service_id,
                          service_name: service_name,
                          staff_id: item.staff_id,
                          resource_item_id: item.resource_item_id,
                          duration: duration,
                          padding_time,
                          durationWithPadding,
                          cost: item.cost
                        }
                      });

                    });
                    formik.setFieldValue(field, s, false);
                  }
                } else if (["addanotherservices"].includes(field)) {
                  if (detail[field] && detail[field].length > 0) {
                    let s = [];
                    detail[field].map((item) => {
                      let id = item.id ? item.id : "";
                      let parent_id = item.parent_id ? item.parent_id : "";
                      let service_name = item.service ? item.service.name : "";
                      let duration = item.duration ? MinutesToHours(item.duration) : "";
                      let padding_time = item.padding_time ? MinutesToHours(item.padding_time) : "";
                      let durationWithPadding = item && item.padding_time && item.padding_time > 0 ? MinutesToHours(parseInt(item.padding_time) + parseInt(item.duration)) : (item && item.duration ? MinutesToHours(item.duration) : "")

                      s.push({
                        id: id, parent_id,
                        service_id: item.service_id,
                        service_name: service_name,
                        staff_id: item.staff_id,
                        resource_item_id: item.resource_item_id,
                        duration: duration,
                        padding_time,
                        durationWithPadding,
                        cost: item.cost,
                        prevObj: {
                          id: id, parent_id,
                          service_id: item.service_id,
                          service_name: service_name,
                          staff_id: item.staff_id,
                          resource_item_id: item.resource_item_id,
                          duration: duration,
                          padding_time,
                          durationWithPadding,
                          cost: item.cost,
                        }
                      });

                    });
                    formik.setFieldValue(field, s, false);
                  }
                } else {
                  let detail_field = detail[field] ? detail[field] : "";
                  formik.setFieldValue(field, formik.values[field] ? formik.values[field] : detail_field, false);
                  let service_id = detail && detail.service && detail.service.id ? detail.service.id : "";
                  let deposit_booked_price = detail && detail.service && detail.service.deposit_booked_price ? detail.service.deposit_booked_price : "";
                  const depositPaid = detail && detail.payment.length > 0 && detail.payment.filter((payment) => payment.type === "Deposit").reduce((sum, payment) => sum + parseFloat(payment.amount), 0);
                  const depositRefund = detail && detail.payment.length > 0 && detail.payment.filter((payment) => payment.type === "DepositRefund").reduce((sum, payment) => sum + parseFloat(payment.amount), 0);
                  const depositBalance = depositPaid && depositPaid >= depositRefund ? depositPaid - depositRefund : 0;
                  let old_cost = detail && detail.cost ? detail.cost : "0.00";
                  formik.setFieldValue("previous_deposit_paid", depositBalance);
                  let addanotherObj = [{ service_id: service_id, deposit_price: deposit_booked_price, service_type: "primary", total_cost: old_cost }];
                  detail['addanotherservices'] && detail['addanotherservices'].length > 0 && detail['addanotherservices'].map((item) => {
                    let service_id = item.service_id;
                    let deposit = item.deposit;
                    let costsTotal = item.cost ? item.cost : "0.00";
                    addanotherObj.push({ service_id: service_id, deposit_price: deposit, service_type: "add_another", total_cost: costsTotal });
                  });
                  formik.setFieldValue("service_deposit", addanotherObj);
                }
              });
            }
            // else if (isServicePrice) {
            //   const duration = isServicePrice.duration ? MinutesToHours(isServicePrice.duration) : "";
            //   const padding_time = isServicePrice.padding_time ? MinutesToHours(isServicePrice.padding_time) : "";
            //   const durationWithPadding = isServicePrice && isServicePrice.padding_time && isServicePrice.padding_time > 0 ? MinutesToHours(parseInt(isServicePrice.padding_time) + parseInt(isServicePrice.duration)) : (isServicePrice && isServicePrice.duration ? MinutesToHours(isServicePrice.duration) : "")

            //   let generalPrice = isServicePrice.serviceprice;
            //   let gprice = generalPrice && generalPrice.length === 1 ? generalPrice[0].price : "0.00";
            //   let add_on_price = generalPrice && generalPrice.length === 1 ? generalPrice[0].add_on_price : "0.00";
            //   // let cost = parseFloat(gprice) + parseFloat(add_on_price);
            //   let cost = parseFloat(gprice);
            //   formik.setFieldValue("service_id", isServicePrice.id);
            //   formik.setFieldValue("duration", duration);
            //   formik.setFieldValue("padding_time", padding_time);
            //   formik.setFieldValue("durationWithPadding", durationWithPadding);
            //   formik.setFieldValue("cost", cost ? cost : "");
            // }
            let duration = detail && detail.duration > 0 ? MinutesToHours(detail.duration) : "";
            let padding_time = detail && detail.padding_time > 0 ? MinutesToHours(detail.padding_time) : "";
            let durationWithPadding = detail && detail.padding_time ? MinutesToHours(parseInt(detail.padding_time) + parseInt(detail.duration)) : (detail && detail.duration ? MinutesToHours(detail.duration) : "")

            const totalDurationWithPadding = GetTotalDurationWithPadding(durationWithPadding, detail.addonservices, detail.addanotherservices);
            GetAvailableTime({
              ...detail,
              duration,
              remove_addon,
              remove_addanother,
              padding_time,
              durationWithPadding,
              totalDurationWithPadding: totalDurationWithPadding && totalDurationWithPadding.totalHours,
              start_datetime: detail.start_datetime,
              listview: 'Appointment',
              formtype: "edit_appointment",
              isDurationCheck: true,
              prev_start_datetime: detail.start_datetime,
              formName: "appointmentForm/edit"
            }, dispatch);
          }, [detail]);

          useEffect(() => {
            if (isAppointmentResourceItems && isAppointmentResourceItems.length > 0) {
              formik.setFieldValue('resource_required', true)
            } else {
              formik.setFieldValue('resource_required', false)
            }
          }, [isAppointmentResourceItems])

          // useEffect(() => {
          //   if (!isStaffAvailableTimeApi) {
          //     formik.setFieldValue("start_time", "", false);
          //   }
          // }, [isStaffAvailableTimeApi]);

          let totalAddonServiceDuration = totalAddonServiceDurationRef && totalAddonServiceDurationRef.current && totalAddonServiceDurationRef.current.value ? totalAddonServiceDurationRef.current.value : 0;
          return (
            <div className={"drawer client-editappoinment " + rightDrawerOpened} id="addappoinment-drawer">
              <div className="drawer-wrp">
                <form noValidate onSubmit={formik.handleSubmit}>
                  <div className="drawer-header">
                    <h2 className="mb-4 pe-md-5 pe-3 d-flex align-items-center"> {t("Edit Appointment")}</h2>
                    <a
                      className={page && page === "calendar" ? "calendars-close-drawer cursor-pointer" : "close cursor-pointer"}
                      onClick={() => {
                        dispatch(servicePriceApi({ service_id: "" }));
                        handleEditAppointmentForm();
                      }}
                    >
                      <img src={config.imagepath + "close-icon.svg"} alt="" />
                    </a>
                  </div>
                  <div className="drawer-body pymd-5 py-3">
                    <div className="mb-3">
                      <ReactSelectField name="service_id" placeholder={t("Choose Service")} value={formik.values.service_id} options={serviceOptionsData} label={t("Service")} controlId="appointmentForm-service_id" isMulti={false} page_name={"calendar"} singlestaffaccess={singleStaffAccess} />
                    </div>
                    <div className="row gx-2">
                      <div className="col-sm-6 mb-3">
                        <ReactSelectField name="staff_id" placeholder={t("Choose Staff")} value={formik.values.staff_id} options={staffOptionsData} label={t("Staff")} controlId="appointmentForm-staff_id" isMulti={false} service_id={formik.values.service_id} />
                      </div>
                      <div className="col-3 mb-3">
                        <InputField name="duration" value={formik.values.duration} label={t("Duration")} mask="99:99" controlId="appointmentForm-duration" placeholder="--:--"
                          onChange={(e) => {
                            const { value } = e.target;
                            formik.setFieldValue("duration", value);
                            if (/^\d{1,2}:\d{2}$/.test(value)) {
                              if (formik.values.service_id && value) {
                                // formik.setFieldValue("addonservices", []);
                                // formik.setFieldValue("addanotherservices", []);
                                formik.setFieldValue("start_time", "", false);

                                const updatedServiceDeposit = formik.values.service_deposit && formik.values.service_deposit.length > 0 && formik.values.service_deposit.filter((item) => item.service_type != "add_another");
                                formik.setFieldValue("service_deposit", updatedServiceDeposit);

                                let totalDeposit = formik.values.totaldeposit;
                                updatedServiceDeposit && updatedServiceDeposit.length > 0 && updatedServiceDeposit.forEach((item) => {
                                  totalDeposit = parseFloat(item.deposit_price);
                                });
                                updatedServiceDeposit && updatedServiceDeposit.length > 0 && formik.setFieldValue("totaldeposit", parseFloat(totalDeposit).toFixed(2));

                                let durationWithPadding = value
                                if (formik.values.padding_time) {
                                  const duration1 = moment.duration(value);
                                  const duration2 = moment.duration(formik.values.padding_time);;

                                  const totalMinutes = duration1.asMinutes() + duration2.asMinutes();
                                  const hours = Math.floor(totalMinutes / 60);
                                  const minutes = totalMinutes % 60;

                                  const sumDuration = moment({ hours, minutes });
                                  const SumOfDuration = sumDuration ? sumDuration.format('HH:mm') : "";

                                  const durationMoment = moment.duration(SumOfDuration);
                                  durationWithPadding = SumOfDuration
                                }
                                formik.setFieldValue("durationWithPadding", durationWithPadding);

                                // dispatch(AddOnData(""));
                                GetAvailableTime({
                                  ...formik.values,
                                  duration: value,
                                  durationWithPadding,
                                  listview: 'Appointment',
                                  pagetype: "edit_appointment",
                                  isDurationCheck: true,
                                  prev_start_datetime: detail.start_datetime,
                                  formName: "appointmentForm/edit/duration"
                                }, dispatch);
                              }
                            }
                          }}
                        />
                      </div>

                      <div className="col-3 mb-3">
                        <InputField name="cost" value={formik.values.cost} label={t("Cost")} controlId="appointmentForm-cost" placeholder="" />
                      </div>
                      <div className="col-4 mb-3 d-none">
                        <InputField name="padding_time" value={formik.values.padding_time} label={t("Padding")} mask="99:99" controlId="appointmentForm-padding_time" placeholder="--:--"
                          onChange={(e) => {
                            const { value } = e.target;
                            formik.setFieldValue("padding_time", value);
                            if (/^\d{1,2}:\d{2}$/.test(value) || value === "") {
                              if (formik.values.service_id) {
                                // formik.setFieldValue("addonservices", []);
                                // formik.setFieldValue("addanotherservices", []);
                                formik.setFieldValue("start_time", "", false);

                                const updatedServiceDeposit = formik.values.service_deposit && formik.values.service_deposit.length > 0 && formik.values.service_deposit.filter((item) => item.service_type != "add_another");
                                formik.setFieldValue("service_deposit", updatedServiceDeposit);

                                let totalDeposit = formik.values.totaldeposit;
                                updatedServiceDeposit && updatedServiceDeposit.length > 0 && updatedServiceDeposit.forEach((item) => {
                                  totalDeposit = parseFloat(item.deposit_price);
                                });
                                updatedServiceDeposit && updatedServiceDeposit.length > 0 && formik.setFieldValue("totaldeposit", parseFloat(totalDeposit).toFixed(2));

                                let durationWithPadding = formik.values.duration;
                                if (/^\d{1,2}:\d{2}$/.test(formik.values.duration)) {
                                  const duration1 = moment.duration(formik.values.duration);;
                                  const duration2 = moment.duration(value);

                                  const totalMinutes = duration1.asMinutes() + duration2.asMinutes();
                                  const hours = Math.floor(totalMinutes / 60);
                                  const minutes = totalMinutes % 60;

                                  const sumDuration = moment({ hours, minutes });
                                  const SumOfDuration = sumDuration ? sumDuration.format('HH:mm') : "";

                                  const durationMoment = moment.duration(SumOfDuration);
                                  durationWithPadding = SumOfDuration
                                }
                                formik.setFieldValue("durationWithPadding", durationWithPadding);

                                // dispatch(AddOnData(""));
                                dispatch(AppointmentAutoFillFinalData(""));
                                GetAvailableTime({
                                  ...formik.values,
                                  padding_time: value,
                                  durationWithPadding,
                                  listview: 'Appointment',
                                  pagetype: "add_appointment",
                                  isDurationCheck: true,
                                  formName: "appointmentForm/edit/duration"
                                }, dispatch);
                              }
                            }
                          }} />
                      </div>
                    </div>
                    <div className="row gx-2">
                      <div className="col-sm-8 mb-3">
                        {/* <InputField type="date" name="date" value={formik.values.date_of_birth} label={t("Date")} controlId="appointmentForm-date" placeholder={t("Select Date")}/> */}
                        <label htmlFor="">{t("Date")}</label>
                        <DatePicker
                          weekDays={config.weekDays}
                          className="purple"
                          renderButton={(direction, handleClick) => (
                            <a className="btn btn-transparent shadow-none" onClick={handleClick}>
                              {direction === "right" ? <i className="fas fa-chevron-right p-0"></i> : <i className="fas fa-chevron-left p-0"></i>}
                            </a>
                          )}
                          name="dateof"
                          id="appointmentForm-dateof"
                          value={formik.values.dateof}
                          inputClass={(formik.touched && formik.touched.dateof && formik.errors && formik.errors.dateof ? "is-invalid" : "") + " form-control date"}
                          placeholder={t("Select Date")}
                          format={"dddd, DD MMMM YYYY"}
                          // minDate={new Date().setHours(0, 0, 0, 0)}
                          onChange={(e) => {
                            let getselectedDatePicker = e ? moment(e?.toString(), "dddd, DD MMMM YYYY").format("dddd, DD MMMM YYYY") : "";
                            // let getselectedDatePicker = e ? moment(e?.toString()).format("dddd, DD MMMM YYYY") : "";
                            formik.setFieldValue("dateof", getselectedDatePicker);
                            // formik.setFieldValue("addonservices", []);
                            // formik.setFieldValue("addanotherservices", []);
                            // formik.setFieldValue("start_time", "", false);
                            const updatedServiceDeposit = formik.values.service_deposit && formik.values.service_deposit.length > 0 && formik.values.service_deposit.filter((item) => item.service_type != "add_another");
                            formik.setFieldValue("service_deposit", updatedServiceDeposit);
                            let totalDeposit = formik.values.totaldeposit;
                            updatedServiceDeposit && updatedServiceDeposit.length > 0 && updatedServiceDeposit.forEach((item) => {
                              totalDeposit = parseFloat(item.deposit_price) - parseFloat(formik.values.previous_deposit_paid);
                            });
                            updatedServiceDeposit && updatedServiceDeposit.length > 0 && formik.setFieldValue("totaldeposit", parseFloat(totalDeposit).toFixed(2));
                            // dispatch(AddOnData(""));
                            GetAvailableTime({
                              ...formik.values,
                              dateof: getselectedDatePicker,
                              listview: 'Appointment',
                              isDurationCheck: true,
                              prev_start_datetime: detail.start_datetime,
                              formName: "appointmentForm/edit/dateof"
                            }, dispatch);

                            // formik.setFieldValue("addonservices", []);
                            // formik.setFieldValue("addanotherservices", []);
                            formik.setFieldValue("start_time", "", false);
                          }}
                        />
                        {formik.touched && formik.touched.dateof && formik.errors && formik.errors.dateof && <div className="invalid-feedback d-block">{formik.errors.dateof}</div>}
                      </div>
                      <div className="col-sm-4 mb-3">
                        <InputPickerField
                          type="time"
                          name="start_time"
                          value={formik.values.start_time}
                          formikData={formik}
                          label={t("Start Time")}
                          controlId="appointmentForm-start_time"
                          options={TimePickerOption}
                          onChange={(e) => {
                            if (e.target.value.match(/([0-9]{1,2}):([0-9]{2})(am|pm)/)) {
                              const start_date_time = e.target.value ? moment(e.target.value, "h:mma").format("HH:mm") : "";
                              formik.setFieldValue("start_time", start_date_time, true);
                              const dateTimeString = formik.values && formik.values.dateof ? moment(formik.values.dateof, "dddd, DD MMMM YYYY").format("YYYY-MM-DD") + " " + moment(start_date_time, "HH:mm").format("HH:mm:ss") : "";

                              const TotalDurationWithPadding = GetTotalDurationWithPadding(formik.values.durationWithPadding, formik.values.addonservices, formik.values.addanotherservices);
                              const obj = {
                                appointment_id: formik.values.form_types && formik.values.form_types === "edit_appointment" && formik.values.id ? formik.values.id : "",
                                busytime_id: "",
                                remove_addon: formik?.values?.remove_addon || [],
                                remove_addanother: formik?.values?.remove_addanother || [],
                                dateof: formik.values.dateof ? formik.values.dateof : "",
                                start_datetime: dateTimeString,
                                duration: formik.values.duration ? formik.values.duration : "",
                                padding_time: formik.values.padding_time ? formik.values.padding_time : "",
                                durationWithPadding: formik.values.durationWithPadding ? formik.values.durationWithPadding : "",
                                TotaldurationWithPadding: TotalDurationWithPadding && TotalDurationWithPadding.totalHours,
                                listview: "Appointment",
                                salon_id: currentUser.salon.id ? currentUser.salon.id : "",
                                service_id: formik.values.service_id ? formik.values.service_id : "",
                                staff_id: formik.values.staff_id ? formik.values.staff_id : "",
                                resource_item_id: formik.values.resource_item_id ? formik.values.resource_item_id : "",
                                uid: 1,
                                formtype: "appointmentForm",
                                formName: "appointmentForm/edit/start_time"
                              }
                              //addon and add another remove array id push logic

                              let addonservicesValues = formik.values.addonservices;
                              let addanotherservicesValues = formik.values.addanotherservices;
                              if (addonservicesValues?.length > 0) {
                                let currentRemoveAddonList = new Set(formik.values.remove_addon || []);
                                addonservicesValues.forEach(service => {
                                  if (service.id) {
                                    currentRemoveAddonList.add(service.id);
                                  }
                                });
                                formik.setFieldValue("remove_addon", Array.from(currentRemoveAddonList));
                              }
                              if (addanotherservicesValues?.length > 0) {
                                let currentRemoveAnotherList = new Set(formik.values.remove_addanother || []);
                                addanotherservicesValues.forEach(service => {
                                  console.log('service OF SET: ', service);
                                  if (service.id) {
                                    currentRemoveAnotherList.add(service.id);
                                  }
                                });
                                formik.setFieldValue("remove_addanother", Array.from(currentRemoveAnotherList));
                              }

                              //addon and add another remove array id push logic
                              dispatch(AppointmentResourceItemsApi({ option: { valueField: "id", labelField: "resource_item" }, service_id: formik.values.service_id ? formik.values.service_id : "", availebleTimeObj: obj }));
                              // formik.handleChange(e);
                            } else {
                              formik.setFieldValue("start_time", "", true);
                              dispatch(AppointmentResourceItemsClears());
                              formik.setFieldValue("resource_item_id", "", true);
                              // formik.handleChange(e);
                            }
                          }
                          }
                        />
                        {/* <InputField type="time" name="start_time" value={formik.values.start_time} label={t("Start Time")} controlId="appointmentForm-start_time" /> */}
                      </div>
                    </div>
                    <div className="row">
                      {isAppointmentResourceItems && isAppointmentResourceItems.length > 0 ? (
                        <div className="col-sm-12 mb-3">
                          <ReactSelectField name="resource_item_id" placeholder={t("Choose Resource")} value={formik.values.resource_item_id} options={resourceOptionsData} label={t("Resource")} controlId="appointmentForm-resource_item_id" isMulti={false} service_id={formik.values.service_id} staff_id={formik.values.staff_id} />
                        </div>
                      ) : appointmentApiStatus && appointmentApiStatus.AppointmentResourceItemsApi == "loading" ? (
                        <div className="col-12">
                          <label >{t("Resource")}</label>
                          <div className="placeholder-glow mb-3">
                            <span className="placeholder w-100" style={{ height: "34px", borderRadius: "4px" }}></span>
                          </div>
                        </div>
                      ) : ""}
                    </div>
                    {formik.values.service_id && formik.values.dateof && (
                      <>
                        {AddOnServiceButtonRenders ? (
                          <FieldArray name="addonservices">
                            {({ insert, remove, push }) => {
                              let not_service_ids = [];
                              let totaladdonservicecost = 0;
                              let totaladdonserviceduration = 0;

                              let dataRef = notServicesIdRef && notServicesIdRef.current && notServicesIdRef.current.value ? notServicesIdRef.current.value.split(",") : [];
                              const filteredData = dataRef.filter(Boolean);
                              return (
                                <div>
                                  {formik.values.addonservices &&
                                    formik.values.addonservices.length > 0 &&
                                    formik.values.addonservices.map((addonservice, index) => {
                                      let addonServiceOptionsData = isAddOn.length > 0 && isAddOn[index] ? isAddOn[index].services : null;
                                      let addonStaffOptionsData = isAddOn.length > 0 && isAddOn[index] ? isAddOn[index].staff : null;
                                      let addonResourceOptionsData = isAddOn.length > 0 && isAddOn[index] ? isAddOn[index].resource_items : null;
                                      // let addonServiceOptionsData = [];
                                      if (formik.values && formik.values.addonservices && formik.values.addonservices.length > 0 && formik.values.addonservices[index]) {
                                        not_service_ids.push(formik.values.addonservices[index].service_id);
                                      }
                                      totaladdonservicecost += formik.values.addonservices[index].cost ? parseFloat(formik.values.addonservices[index].cost) : 0;
                                      totaladdonserviceduration += formik.values.addonservices[index].duration ? HoursToMinutes(moment(formik.values.addonservices[index].duration, "HH:mm").format("HH:mm")) : 0;
                                      return (
                                        <div className="row gx-2 bg-Apricot rounded p-4 mb-2 position-relative" key={index}>
                                          <div className="position-absolute custom-position-calendar">
                                            {(activeLoading.addonLoading || AddOnLoader) && <span className="spinner-border spinner-border-sm spinner-border-color-custom"></span>}
                                          </div>
                                          <div className="col d-flex justify-content-around px-3">
                                            <div className="col-12">
                                              <h6 className="fw-bold text-white mb-2">Add an Add-On Service</h6>

                                            </div>
                                            <a
                                              className=""
                                              onClick={() => {
                                                remove(index);
                                                let id = index + 1;
                                                dispatch(AddOnDataRemove({ id: id }));
                                                let addonservicesValues = formik.values.addonservices;
                                                // let currentRemoveList = formik.values.remove_addon || [];
                                                // if (addonservicesValues[index] && addonservicesValues[index].id) {
                                                //   currentRemoveList.push(addonservicesValues[index].id);
                                                // }
                                                // formik.setFieldValue("remove_addon", currentRemoveList);
                                                let currentRemoveList = formik.values.remove_addon || [];
                                                if (addonservicesValues[index] && addonservicesValues[index].id) {
                                                  const startId = addonservicesValues[index].id;
                                                  let startIndex = addonservicesValues.findIndex(item => item.id === startId);
                                                  if (startIndex !== -1) {
                                                    let idsToAdd = addonservicesValues.slice(startIndex).map(item => item.id);
                                                    idsToAdd = idsToAdd.filter(id => !currentRemoveList.includes(id));
                                                    currentRemoveList.push(...idsToAdd);
                                                  }
                                                }
                                                formik.setFieldValue("remove_addon", currentRemoveList);
                                                // addonservicesValues = addonservicesValues ? addonservicesValues.filter((item) => item.id != id) : addonservicesValues.filter((item) => item.id != id);
                                                formik.setFieldValue("addonservices", removeItem(addonservicesValues, index));
                                              }}
                                            >
                                              <i className="fas fa-times p-0" style={{ fontSize: "22px", fontWeight: "300", color: "#FFF" }}></i>
                                            </a>
                                          </div>
                                          <div className="col-md-12 mb-3">{formik.values && formik.values.addonservices && formik.values.addonservices.length > 0 && formik.values.addonservices[index] && formik.values.addonservices[index].service_id ? <span className={`${formik.values.addonservices[index].service_name ? "form-control disabled-div-style" : ""}`}>{formik.values.addonservices[index].service_name}</span> : <ReactSelectField name={`addonservices.${index}.service_id`} placeholder={t("Choose Service")} options={addonServiceOptionsData} controlId={`appointmentForm-addonservices-${index}-service_id`} isMulti={false} isAddOn={isAddOn} indexID={index + 1} />}</div>

                                          <div className="col-sm-6 mb-3">
                                            <label className="text-white fw-semibold">{t("Staff")}</label>
                                            <ReactSelectField name={`addonservices.${index}.staff_id`} placeholder={t("Choose Staff")} options={addonStaffOptionsData} label={t("")} controlId={`appointmentForm-addonservices-${index}-staff_id`} isMulti={false} service_id={formik.values.addonservices[index].service_id} isAddOn={isAddOn} indexID={index + 1} />
                                          </div>

                                          <div className="col-md-3 col-4 mb-3">
                                            <label className="text-white fw-semibold">{t("Duration")}</label>
                                            <InputField name={`addonservices.${index}.duration`} label={t("")} mask="99:99" controlId={`appointmentForm-addonservices-${index}-duration`} placeholder="--:--"
                                              onChange={(e) => {
                                                const { value } = e.target;
                                                // const formattedValue = formatCardNumber(value);
                                                formik.setFieldValue(`addonservices.${index}.duration`, value);
                                                if (/^\d{1,2}:\d{2}$/.test(value)) {
                                                  // If valid, set the result field value
                                                  if (formik.values.addonservices[index].service_id && value) {
                                                    formik.setFieldValue(`addonservices.${index}.staff_id`, "");
                                                    formik.setFieldValue(`addonservices.${index}.resource_item_id`, "");
                                                    formik.values.addonservices[index].staff_id = ""
                                                    formik.values.addonservices[index].resource_item_id = ""
                                                    let durationWithPadding = value
                                                    if (formik.values && formik.values.addonservices && formik.values.addonservices[index] && formik.values.addonservices[index].padding_time) {
                                                      const duration1 = moment.duration(value);
                                                      const duration2 = moment.duration(formik.values.addonservices[index].padding_time);;

                                                      const totalMinutes = duration1.asMinutes() + duration2.asMinutes();
                                                      const hours = Math.floor(totalMinutes / 60);
                                                      const minutes = totalMinutes % 60;

                                                      const sumDuration = moment({ hours, minutes });
                                                      const SumOfDuration = sumDuration ? sumDuration.format('HH:mm') : "";

                                                      // const durationMoment = moment.duration(SumOfDuration);
                                                      durationWithPadding = SumOfDuration
                                                    }
                                                    formik.setFieldValue(`addonservices.${index}.durationWithPadding`, durationWithPadding);

                                                    const addonservices = formik.values.addonservices && formik.values.addonservices.length > 0 ? formik.values.addonservices.slice(0, index) : "";

                                                    let parentAppointmentEndTime = "";
                                                    if (addonservices && addonservices.length > 0) {
                                                      const totalAddOnDurationWithPadding = addonservices && addonservices.length > 0 && addonservices.reduce((sum, sduration) => {
                                                        // Check if sduration.durationWithPadding is a valid duration
                                                        const duration = moment.duration(sduration.durationWithPadding);
                                                        if (duration.isValid()) {
                                                          const sumDurationWithPadding = moment.duration(sduration.durationWithPadding);
                                                          return sum.add(sumDurationWithPadding);
                                                        }
                                                        return sum;
                                                      }, moment.duration(0));

                                                      const parentAppointmentDuration = formik.values.durationWithPadding;
                                                      const duration1 = moment.duration(parentAppointmentDuration);
                                                      const duration2 = totalAddOnDurationWithPadding;

                                                      // Check if each duration is valid before using it
                                                      const minutes1 = duration1 && duration1.isValid() ? duration1.asMinutes() : 0;
                                                      const minutes2 = duration2 && duration2.isValid() ? duration2.asMinutes() : 0;

                                                      const totalMinutes = minutes1 + minutes2;
                                                      const hours = Math.floor(totalMinutes / 60);
                                                      const minutes = totalMinutes % 60;

                                                      const sumDuration = moment({ hours, minutes });
                                                      const SumOfDuration = sumDuration ? sumDuration.format('HH:mm') : "";
                                                      // console.log(SumOfDuration);
                                                      const durationMoment = moment.duration(SumOfDuration);
                                                      // appointmentStartDateTime = moment(`${form.values.dateof} ${addonservices.start_time}`, "dddd, DD MMMM YYYY HH:mm");
                                                      const appointmentStartDateTime = moment(`${formik.values.dateof} ${formik.values.start_time}`, "dddd, DD MMMM YYYY HH:mm");
                                                      parentAppointmentEndTime = appointmentStartDateTime.clone().add(durationMoment).format('YYYY-MM-DD HH:mm:00');

                                                    } else {
                                                      const appointmentStartDateTime = moment(`${formik.values.dateof} ${formik.values.start_time}`, "dddd, DD MMMM YYYY HH:mm");
                                                      // Parse the duration string to a moment duration
                                                      const durationMoment = moment.duration(formik.values.durationWithPadding);
                                                      parentAppointmentEndTime = appointmentStartDateTime.clone().add(durationMoment).format('YYYY-MM-DD HH:mm:00');
                                                    }

                                                    const obj = {
                                                      ...formik.values.addonservices[index],
                                                      remove_addon: formik?.values?.remove_addon || [],
                                                      remove_addanother: formik?.values?.remove_addanother || [],
                                                      parent_id: formik.values && formik.values.id ? formik.values.id : "",
                                                      staff_id: formik.values.addonservices[index].staff_id ? formik.values.addonservices[index].staff_id : "",
                                                      dateof: formik.values.dateof,
                                                      duration: value,
                                                      durationWithPadding: durationWithPadding,
                                                      parentAppointmentEndTime: parentAppointmentEndTime,
                                                      listview: "Appointment",
                                                      formtype: "appointmentform",
                                                      formName: "appointmentForm/edit/addonservices/duration"
                                                    }
                                                    const addOnData = isAddOn.find((event) => event.id === (index + 1));
                                                    let addonobj = { ...addOnData, duration: value };
                                                    const addOnResourceOptionsAction = dispatch(AddOnResourceOptionsApi({ option: { valueField: "id", labelField: "resource_item" }, service_id: formik.values.addonservices[index].service_id, pagetype: "Booking", isNotInResourceIds: "", availebleTimeObj: { ...obj } }));
                                                    const addOnStaffOptionsAction = dispatch(AddonStaffOptionsApi({ option: { valueField: "users.id", labelField: "CONCAT(users.first_name,' ',users.last_name)" }, pagetype: "Booking", service_id: formik.values.addonservices[index].service_id, availebleTimeObj: { ...obj } }));
                                                    Promise.all([addOnResourceOptionsAction, addOnStaffOptionsAction]).then(([action1, action2]) => {
                                                      if (action1.meta.requestStatus === "fulfilled" && action2.meta.requestStatus === "fulfilled") {
                                                        if (addonobj) {
                                                          dispatch(AddOnData({ ...addonobj, staff: action2.payload, resource_items: action1.payload }));
                                                        }
                                                      }
                                                    });
                                                    // dispatch(AddOnResourceOptionsApi({ option: { valueField: "id", labelField: "resource_item" }, service_id: formik.values.addonservices[index].service_id, pagetype: "Booking", isNotInResourceIds: "", availebleTimeObj: { ...obj } })).then((actionResource) => {
                                                    //   if (actionResource.meta.requestStatus === "fulfilled") {
                                                    //     if (addonobj) {
                                                    //       dispatch(AddOnData({ ...addonobj, resource_items: actionResource.payload }));
                                                    //     }
                                                    //   } else {
                                                    //     dispatch(AddOnData(addonobj));
                                                    //   }
                                                    // });
                                                    AddonGetAvailableTime({
                                                      ...formik.values.addonservices[index],
                                                      remove_addon: formik?.values?.remove_addon || [],
                                                      remove_addanother: formik?.values?.remove_addanother || [],
                                                      parent_id: formik.values && formik.values.id ? formik.values.id : "",
                                                      dateof: formik.values.dateof,
                                                      duration: value,
                                                      durationWithPadding,
                                                      parentAppointmentEndTime,
                                                      listview: 'Appointment',
                                                      pagetype: "addon_appointment",
                                                      formName: "appointmentForm/edit/addonservices/duration"
                                                    }, dispatch);
                                                  }
                                                }
                                              }}
                                            />
                                          </div>
                                          <div className="col-md-2 col-4 mb-3 d-none">
                                            <label className="text-white fw-semibold">{t("Padding")}</label>
                                            <InputField name={`addonservices.${index}.padding_time`} mask="99:99" controlId={`appointmentForm-addonservices-${index}-padding_time`} placeholder="--:--"
                                              onChange={(e) => {
                                                const { value } = e.target;
                                                // const formattedValue = formatCardNumber(value);
                                                formik.setFieldValue(`addonservices.${index}.padding_time`, value);
                                                if (/^\d{1,2}:\d{2}$/.test(value) || value === "") {
                                                  // If valid, set the result field value
                                                  if (formik.values.addonservices[index].service_id) {

                                                    let durationWithPadding = formik.values.addonservices[index].duration;
                                                    if (formik.values && formik.values.addonservices && formik.values.addonservices[index] && formik.values.addonservices[index].duration) {
                                                      const duration1 = moment.duration(formik.values.addonservices[index].duration);
                                                      const duration2 = moment.duration(value);

                                                      const totalMinutes = duration1.asMinutes() + duration2.asMinutes();
                                                      const hours = Math.floor(totalMinutes / 60);
                                                      const minutes = totalMinutes % 60;

                                                      const sumDuration = moment({ hours, minutes });
                                                      const SumOfDuration = sumDuration ? sumDuration.format('HH:mm') : "";

                                                      // const durationMoment = moment.duration(SumOfDuration);
                                                      durationWithPadding = SumOfDuration
                                                    }
                                                    formik.setFieldValue(`addonservices.${index}.durationWithPadding`, durationWithPadding);

                                                    const addonservices = [...formik.values.addonservices];

                                                    let parentAppointmentEndTime = "";
                                                    if (addonservices && addonservices.length > 1) {
                                                      addonservices.pop();

                                                      const totalAddOnDurationWithPadding = addonservices && addonservices.length > 0 && addonservices.reduce((sum, sduration) => {
                                                        // Check if sduration.durationWithPadding is a valid duration
                                                        const duration = moment.duration(sduration.durationWithPadding);
                                                        if (duration.isValid()) {
                                                          const sumDurationWithPadding = moment.duration(sduration.durationWithPadding);
                                                          return sum.add(sumDurationWithPadding);
                                                        }
                                                        return sum;
                                                      }, moment.duration(0));

                                                      const parentAppointmentDuration = formik.values.durationWithPadding;
                                                      const duration1 = moment.duration(parentAppointmentDuration);
                                                      const duration2 = totalAddOnDurationWithPadding;

                                                      // Check if each duration is valid before using it
                                                      const minutes1 = duration1 && duration1.isValid() ? duration1.asMinutes() : 0;
                                                      const minutes2 = duration2 && duration2.isValid() ? duration2.asMinutes() : 0;

                                                      const totalMinutes = minutes1 + minutes2;
                                                      const hours = Math.floor(totalMinutes / 60);
                                                      const minutes = totalMinutes % 60;

                                                      const sumDuration = moment({ hours, minutes });
                                                      const SumOfDuration = sumDuration ? sumDuration.format('HH:mm') : "";
                                                      // console.log(SumOfDuration);
                                                      const durationMoment = moment.duration(SumOfDuration);
                                                      // appointmentStartDateTime = moment(`${form.values.dateof} ${addonservices.start_time}`, "dddd, DD MMMM YYYY HH:mm");
                                                      const appointmentStartDateTime = moment(`${formik.values.dateof} ${formik.values.start_time}`, "dddd, DD MMMM YYYY HH:mm");
                                                      parentAppointmentEndTime = appointmentStartDateTime.clone().add(durationMoment).format('YYYY-MM-DD HH:mm:00');

                                                    } else {
                                                      const appointmentStartDateTime = moment(`${formik.values.dateof} ${formik.values.start_time}`, "dddd, DD MMMM YYYY HH:mm");
                                                      // Parse the duration string to a moment duration
                                                      const durationMoment = moment.duration(formik.values.durationWithPadding);
                                                      parentAppointmentEndTime = appointmentStartDateTime.clone().add(durationMoment).format('YYYY-MM-DD HH:mm:00');
                                                    }
                                                    const obj = {
                                                      ...formik.values.addonservices[index],
                                                      remove_addon: formik?.values?.remove_addon || [],
                                                      remove_addanother: formik?.values?.remove_addanother || [],
                                                      parent_id: formik.values && formik.values.id ? formik.values.id : "",
                                                      staff_id: formik.values.addonservices[index].staff_id ? formik.values.addonservices[index].staff_id : "",
                                                      dateof: formik.values.dateof,
                                                      padding_time: value,
                                                      durationWithPadding: durationWithPadding,
                                                      parentAppointmentEndTime: parentAppointmentEndTime,
                                                      listview: "Appointment",
                                                      formtype: "appointmentform",
                                                      formName: "appointmentForm/edit/addonservices/padding_time"
                                                    }
                                                    const addOnData = isAddOn.find((event) => event.id === (index + 1));
                                                    let addonobj = { ...addOnData, duration: formik.values.addonservices[index].duration };
                                                    dispatch(AddOnResourceOptionsApi({ option: { valueField: "id", labelField: "resource_item" }, service_id: formik.values.addonservices[index].service_id, pagetype: "Booking", isNotInResourceIds: "", availebleTimeObj: { ...obj } })).then((actionResource) => {
                                                      if (actionResource.meta.requestStatus === "fulfilled") {
                                                        if (addonobj) {
                                                          dispatch(AddOnData({ ...addonobj, resource_items: actionResource.payload }));
                                                        }
                                                      } else {
                                                        dispatch(AddOnData(addonobj));
                                                      }
                                                    });
                                                    AddonGetAvailableTime({
                                                      ...formik.values.addonservices[index],
                                                      remove_addon: formik?.values?.remove_addon || [],
                                                      remove_addanother: formik?.values?.remove_addanother || [],
                                                      dateof: formik.values.dateof,
                                                      padding_time: value,
                                                      durationWithPadding,
                                                      parentAppointmentEndTime,
                                                      listview: 'Appointment',
                                                      pagetype: "addon_appointment",
                                                      formName: "appointmentForm/edit/addonservices/padding_time"
                                                    }, dispatch);
                                                  }
                                                }
                                              }}

                                            />
                                          </div>
                                          <div className="col-md-3 col-4 mb-3">
                                            <label className="text-white fw-semibold">{t("Cost")}</label>
                                            <InputField name={`addonservices.${index}.cost`} label={t("")} controlId={`appointmentForm-addonservices-${index}-cost`} placeholder="" />
                                          </div>
                                          {isAddOn.length > 0 && isAddOn[index] && isAddOn[index].resource_items && isAddOn[index].resource_items.length > 0 ? (
                                            <div className="col-sm-12 mb-3">
                                              <label className="text-white fw-semibold">{t("Resource")}</label>
                                              <ReactSelectField name={`addonservices.${index}.resource_item_id`} placeholder={t("Choose Resource")} options={addonResourceOptionsData} controlId={`appointmentForm-addonservices-${index}-resource_item_id`} isMulti={false} service_id={formik.values.addonservices[index].service_id} staff_id={formik.values.addonservices[index].staff_id} isAddOn={isAddOn} indexID={index + 1} />
                                            </div>
                                          ) : isAddOnApiStatus && isAddOnApiStatus.AddOnResourceOptionsApi == "loading" ? (
                                            <div className="col-sm-12 mb-3">
                                              <label className="text-white fw-semibold">{t("Resource")}</label>
                                              <div className="box-image-cover notification-boxs w-100 mx-0 px-2 py-2 text-start">
                                                <div className="col-12 placeholder-glow">
                                                  <BarLoader color="#ffc1b0" className="w-100" height={"18px"} />
                                                </div>
                                              </div>
                                            </div>
                                          ) : ""}

                                          {/* <div className="col">
                                  <label htmlFor={`addonservices.${index}.service_id`}>Service</label>
                                  <Field name={`addonservices.${index}.service_id`} placeholder="Jane Doe" type="text" />
                                  <ErrorMessage name={`addonservices.${index}.service_id`} component="div" className="field-error" />
                                </div>
                                <div className="col">
                                  <label htmlFor={`addonservices.${index}.staff_id`}>Staff</label>
                                  <Field name={`addonservices.${index}.staff_id`} placeholder="jane@acme.com" type="text" />
                                  <ErrorMessage name={`addonservices.${index}.staff_id`} component="div" className="field-error" />
                                </div> */}
                                          {/* <div className="position-absolute custom-bar-loader-calendar">
                                            <BarLoader color="#8c1c4d" className="w-100" />
                                          </div> */}
                                        </div>
                                      );
                                    })}
                                  <input ref={notServicesIdRef} type="hidden" value={not_service_ids} />
                                  <input ref={totalAddonServiceCostRef} type="hidden" value={totaladdonservicecost} />
                                  <input ref={totalAddonServiceDurationRef} type="hidden" value={totaladdonserviceduration} />
                                  {filteredData.length == formik.values.addonservices.length && (
                                    <button
                                      type="button"
                                      className="btn btn-cancel bg-Apricot col-12 me-1 cursor-pointer mb-3 fw-semibold text-light-whites"
                                      onClick={() => {
                                        if (notServicesIdRef && notServicesIdRef.current) {
                                        }
                                        push({ service_id: "", staff_id: "", resource_item_id: "", duration: "", cost: "" });
                                        // dispatch(AddOnData(addOnData));
                                        dispatch(AddOnServicesApi({ service_id: formik.values.service_id, isNotId: not_service_ids })).then((action) => {
                                          if (action.meta.requestStatus === "fulfilled") {
                                            // addOnData.services = action.payload;
                                            let addOnData = { id: formik.values.addonservices.length + 1, parent_id: formik.values.id, serviceprice: "", services: [], resource_items: [], staff: [], duration: "", cost: "" };
                                            dispatch(AddOnData({ ...addOnData, services: action.payload }));
                                          }
                                        });
                                      }}
                                    >
                                      {t("Add an Add-On Service")}
                                    </button>
                                  )}
                                </div>
                              );
                            }}
                          </FieldArray>
                        ) : ""}
                        <FieldArray name="addanotherservices">
                          {({ insert, remove, push }) => {
                            let not_service_ids = [];
                            let totaladdanotherservicecost = 0;
                            let totaladdanotherserviceduration = 0;
                            let dataRef = notAnotherServicesIdRef && notAnotherServicesIdRef.current && notAnotherServicesIdRef.current.value ? notAnotherServicesIdRef.current.value.split(",") : [];
                            const filteredData = dataRef.filter(Boolean);
                            return (
                              <div>
                                {formik.values.addanotherservices.length > 0 &&
                                  formik.values.addanotherservices.map((addanotherservice, index) => {
                                    let addonServiceOptionsData = isAddAnother.length > 0 && isAddAnother[index] ? isAddAnother[index].services : null;
                                    let addonStaffOptionsData = isAddAnother.length > 0 && isAddAnother[index] ? isAddAnother[index].staff : null;
                                    let addanotherResourceOptionsData = isAddAnother.length > 0 && isAddAnother[index] ? isAddAnother[index].resource_items : null;
                                    // let addonServiceOptionsData = [];
                                    if (formik.values && formik.values.addanotherservices && formik.values.addanotherservices.length > 0 && formik.values.addanotherservices[index]) {
                                      not_service_ids.push(formik.values.addanotherservices[index].service_id);
                                    }
                                    totaladdanotherservicecost += formik.values.addanotherservices[index].cost ? parseFloat(formik.values.addanotherservices[index].cost) : 0;

                                    totaladdanotherserviceduration += formik.values.addanotherservices[index].duration ? HoursToMinutes(moment(formik.values.addanotherservices[index].duration, "HH:mm").format("HH:mm")) : 0;

                                    return (
                                      <div className="row gx-2 bg-pink rounded p-4 mb-2 position-relative" key={index}>
                                        <div className="position-absolute custom-position-calendar">
                                          {(activeLoading.addanotherLoading || AddAnotherLoader) && <span className="spinner-border spinner-border-sm spinner-border-color-custom"></span>}
                                        </div>
                                        <div className="col d-flex justify-content-around px-3">
                                          <div className="col-12">
                                            <h6 className="fw-bold mb-2" style={{ color: "#6b7280" }}>Add Another Service</h6>
                                          </div>
                                          <a
                                            className=""
                                            onClick={() => {
                                              remove(index);
                                              let id = index + 1;
                                              dispatch(AddAnotherDataRemove({ id: id }));
                                              let addanotherservicesValues = formik.values.addanotherservices;
                                              let currentRemoveList = formik.values.remove_addanother || [];
                                              if (addanotherservicesValues[index] && addanotherservicesValues[index].id) {
                                                const startId = addanotherservicesValues[index].id;
                                                let startIndex = addanotherservicesValues.findIndex(item => item.id === startId);
                                                if (startIndex !== -1) {
                                                  let idsToAdd = addanotherservicesValues.slice(startIndex).map(item => item.id);
                                                  idsToAdd = idsToAdd.filter(id => !currentRemoveList.includes(id));
                                                  currentRemoveList.push(...idsToAdd);
                                                }
                                              }
                                              formik.setFieldValue("remove_addanother", currentRemoveList);
                                              formik.setFieldValue("addanotherservices", removeItem(addanotherservicesValues, index));
                                              const updatedServiceDeposit = formik.values.service_deposit.filter((item) => item.service_id !== addanotherservice.service_id);
                                              formik.setFieldValue("service_deposit", updatedServiceDeposit);
                                              const DepositDeductionData = addanotherservice && addanotherservice.service_id && formik.values.service_deposit && formik.values.service_deposit.length > 0 && formik.values.service_deposit.find((item) => item.service_id === addanotherservice.service_id)
                                              if (DepositDeductionData && parseFloat(formik.values.totaldeposit) - parseFloat(DepositDeductionData.deposit_price) > 0) {
                                                formik.setFieldValue("totaldeposit", (parseFloat(formik.values.totaldeposit) - parseFloat(DepositDeductionData.deposit_price)).toFixed(2));
                                              } else if (DepositDeductionData && parseFloat(formik.values.totaldeposit) - parseFloat(DepositDeductionData.deposit_price) <= 0) {
                                                dispatch(OpenDepositPayTab(false));
                                                formik.setFieldValue("totaldeposit", 0);
                                              }
                                            }}
                                          >
                                            <i className="fas fa-times p-0" style={{ fontSize: "22px", fontWeight: "300", color: "#999999" }}></i>
                                          </a>
                                        </div>
                                        <div className="col-md-12 mb-3">{formik.values && formik.values.addanotherservices && formik.values.addanotherservices.length > 0 && formik.values.addanotherservices[index] && formik.values.addanotherservices[index].service_id ? <span className={`${formik.values.addanotherservices[index].service_name ? "form-control disabled-div-style" : ""}`}>{formik.values.addanotherservices[index].service_name}</span> : <ReactSelectField name={`addanotherservices.${index}.service_id`} placeholder={t("Choose Service")} options={addonServiceOptionsData} controlId={`appointmentForm-addanotherservices-${index}-service_id`} isMulti={false} isAddAnother={isAddAnother} indexID={index + 1} />}</div>

                                        <div className="col-sm-6 mb-3">
                                          <ReactSelectField name={`addanotherservices.${index}.staff_id`} placeholder={t("Choose Staff")} options={addonStaffOptionsData} label={t("Staff")} controlId={`appointmentForm-addanotherservices-${index}-staff_id`} isMulti={false} service_id={formik.values.addanotherservices[index].service_id} isAddAnother={isAddAnother} indexID={index + 1} />
                                        </div>

                                        <div className="col-md-3 col-4 mb-3">
                                          <InputField name={`addanotherservices.${index}.duration`} label={t("Duration")} mask="99:99" controlId={`appointmentForm-addanotherservices-${index}-duration`} placeholder="--:--"
                                            onChange={(e) => {
                                              const { value } = e.target;
                                              // const formattedValue = formatCardNumber(value);
                                              formik.setFieldValue(`addanotherservices.${index}.duration`, value);
                                              if (/^\d{1,2}:\d{2}$/.test(value)) {
                                                // console.log('duration', formik.values);
                                                // If valid, set the result field value
                                                if (formik.values.addanotherservices[index].service_id && value) {
                                                  formik.setFieldValue(`addanotherservices.${index}.staff_id`, "");
                                                  formik.setFieldValue(`addanotherservices.${index}.resource_item_id`, "");
                                                  formik.values.addanotherservices[index].staff_id = ""
                                                  formik.values.addanotherservices[index].resource_item_id = ""
                                                  let durationWithPadding = value
                                                  if (formik.values && formik.values.addanotherservices && formik.values.addanotherservices[index] && formik.values.addanotherservices[index].padding_time) {
                                                    const duration1 = moment.duration(value);
                                                    const duration2 = moment.duration(formik.values.addanotherservices[index].padding_time);

                                                    const totalMinutes = duration1.asMinutes() + duration2.asMinutes();
                                                    const hours = Math.floor(totalMinutes / 60);
                                                    const minutes = totalMinutes % 60;

                                                    const sumDuration = moment({ hours, minutes });
                                                    const SumOfDuration = sumDuration ? sumDuration.format('HH:mm') : "";

                                                    // const durationMoment = moment.duration(SumOfDuration);
                                                    durationWithPadding = SumOfDuration
                                                  }
                                                  formik.setFieldValue(`addanotherservices.${index}.durationWithPadding`, durationWithPadding);

                                                  const addonservices = formik.values.addonservices && formik.values.addonservices.length > 0 ? formik.values.addonservices.slice(0, index + 1) : "";
                                                  const addanotherservices = formik.values.addanotherservices && formik.values.addanotherservices.length > 0 ? formik.values.addanotherservices.slice(0, index) : "";
                                                  let parentAppointmentEndTime = "";
                                                  const totalAddOnDurationWithPadding = addonservices && addonservices.length > 0 && addonservices.reduce((sum, sduration) => {
                                                    // Check if sduration.durationWithPadding is a valid duration
                                                    const duration = moment.duration(sduration.durationWithPadding);
                                                    if (duration.isValid()) {
                                                      const sumDurationWithPadding = moment.duration(sduration.durationWithPadding);
                                                      return sum.add(sumDurationWithPadding);
                                                    }
                                                    return sum;
                                                  }, moment.duration(0));

                                                  if (addanotherservices && addanotherservices.length > 0) {
                                                    const totalAddAnotherDurationWithPadding = addanotherservices.length > 0 && addanotherservices.reduce((sum, sduration) => {
                                                      // Check if sduration.durationWithPadding is a valid duration
                                                      const duration = moment.duration(sduration.durationWithPadding);
                                                      if (duration.isValid()) {
                                                        const sumDurationWithPadding = moment.duration(sduration.durationWithPadding);
                                                        return sum.add(sumDurationWithPadding);
                                                      }
                                                      return sum;
                                                    }, moment.duration(0));

                                                    const parentAppointmentDuration = formik.values.durationWithPadding;
                                                    const duration1 = moment.duration(parentAppointmentDuration);
                                                    const duration2 = totalAddOnDurationWithPadding;
                                                    const duration3 = totalAddAnotherDurationWithPadding;

                                                    // Check if each duration is valid before using it
                                                    const minutes1 = duration1 && duration1.isValid() ? duration1.asMinutes() : 0;
                                                    const minutes2 = duration2 && duration2.isValid() ? duration2.asMinutes() : 0;
                                                    const minutes3 = duration3 && duration3.isValid() ? duration3.asMinutes() : 0;

                                                    const totalMinutes = minutes1 + minutes2 + minutes3;
                                                    const hours = Math.floor(totalMinutes / 60);
                                                    const minutes = totalMinutes % 60;

                                                    const sumDuration = moment({ hours, minutes });
                                                    const SumOfDuration = sumDuration ? sumDuration.format('HH:mm') : "";
                                                    // console.log(SumOfDuration);
                                                    const durationMoment = moment.duration(SumOfDuration);
                                                    // appointmentStartDateTime = moment(`${form.values.dateof} ${addanotherservicesPrevObj.start_time}`, "dddd, DD MMMM YYYY HH:mm");
                                                    const appointmentStartDateTime = moment(`${formik.values.dateof} ${formik.values.start_time}`, "dddd, DD MMMM YYYY HH:mm");
                                                    parentAppointmentEndTime = appointmentStartDateTime.clone().add(durationMoment).format('YYYY-MM-DD HH:mm:00');

                                                  } else {
                                                    const appointmentStartDateTime = moment(`${formik.values.dateof} ${formik.values.start_time}`, "dddd, DD MMMM YYYY HH:mm");
                                                    // Parse the duration string to a moment duration
                                                    const duration1 = moment.duration(formik.values.durationWithPadding);
                                                    const duration2 = totalAddOnDurationWithPadding;

                                                    // Check if each duration is valid before using it
                                                    const minutes1 = duration1 && duration1.isValid() ? duration1.asMinutes() : 0;
                                                    const minutes2 = duration2 && duration2.isValid() ? duration2.asMinutes() : 0;

                                                    const totalMinutes = minutes1 + minutes2;
                                                    const hours = Math.floor(totalMinutes / 60);
                                                    const minutes = totalMinutes % 60;

                                                    const sumDuration = moment({ hours, minutes });
                                                    const SumOfDuration = sumDuration ? sumDuration.format('HH:mm') : "";

                                                    const durationMoment = moment.duration(SumOfDuration);
                                                    // Add the duration to the date and time
                                                    parentAppointmentEndTime = appointmentStartDateTime.clone().add(durationMoment).format('YYYY-MM-DD HH:mm:00');
                                                  }
                                                  const obj = {
                                                    ...formik.values.addanotherservices[index],
                                                    remove_addon: formik?.values?.remove_addon || [],
                                                    remove_addanother: formik?.values?.remove_addanother || [],
                                                    parent_id: formik.values && formik.values.id ? formik.values.id : "",
                                                    staff_id: formik.values.addanotherservices[index].staff_id ? formik.values.addanotherservices[index].staff_id : "",
                                                    dateof: formik.values.dateof,
                                                    duration: value,
                                                    durationWithPadding: durationWithPadding,
                                                    parentAppointmentEndTime: parentAppointmentEndTime,
                                                    listview: "Appointment",
                                                    formtype: "appointmentform",
                                                    formName: "appointmentForm/edit/addanotherservices/duration"
                                                  }

                                                  const addAnotherData = isAddAnother.find((event) => event.id === (index + 1));

                                                  let addanotherobj = { ...addAnotherData, duration: value };
                                                  const addAnotherResourceOptionsAction = dispatch(AddAnotherResourceOptionsApi({ option: { valueField: "id", labelField: "resource_item" }, service_id: formik.values.addanotherservices[index].service_id, pagetype: "Booking", isNotInResourceIds: "", availebleTimeObj: { ...obj } }));
                                                  const addAnotherStaffOptionsAction = dispatch(AddAnotherStaffOptionsApi({ option: { valueField: "users.id", labelField: "CONCAT(users.first_name,' ',users.last_name)" }, service_id: formik.values.addanotherservices[index].service_id, pagetype: "Booking", availebleTimeObj: { ...obj } }));
                                                  Promise.all([addAnotherResourceOptionsAction, addAnotherStaffOptionsAction]).then(([action1, action2]) => {
                                                    if (action1.meta.requestStatus === "fulfilled" && action2.meta.requestStatus === "fulfilled") {
                                                      if (addanotherobj) {
                                                        dispatch(AddAnotherData({ ...addanotherobj, staff: action2.payload, resource_items: action1.payload }));
                                                      }
                                                    }
                                                  });

                                                  // dispatch(AddAnotherResourceOptionsApi({ option: { valueField: "id", labelField: "resource_item" }, service_id: formik.values.addanotherservices[index].service_id, pagetype: "Booking", isNotInResourceIds: "", availebleTimeObj: { ...obj } })).then((actionResource) => {
                                                  //   if (actionResource.meta.requestStatus === "fulfilled") {
                                                  //     if (addAnotherData) {
                                                  //       dispatch(AddAnotherData({ ...addAnotherData, resource_items: actionResource.payload }));
                                                  //     }
                                                  //   } else {
                                                  //     dispatch(AddAnotherData(addanotherobj));
                                                  //   }
                                                  // });
                                                  AddAnotherGetAvailableTime({
                                                    ...formik.values.addanotherservices[index],
                                                    remove_addon: formik?.values?.remove_addon || [],
                                                    remove_addanother: formik?.values?.remove_addanother || [],
                                                    parent_id: formik.values && formik.values.id ? formik.values.id : "",
                                                    dateof: formik.values.dateof,
                                                    duration: value,
                                                    durationWithPadding,
                                                    parentAppointmentEndTime,
                                                    listview: 'Appointment',
                                                    pagetype: "addanother_appointment",
                                                    formName: "appointmentForm/edit/addanotherservices/duration"
                                                  }, dispatch);
                                                }
                                              }
                                            }}
                                          />
                                        </div>
                                        <div className="col-md-2 col-4 mb-3 d-none">
                                          <InputField name={`addanotherservices.${index}.padding_time`} label={t("Padding")} mask="99:99" controlId={`appointmentForm-addanotherservices-${index}-padding_time`} placeholder="--:--"
                                            onChange={(e) => {
                                              const { value } = e.target;
                                              // const formattedValue = formatCardNumber(value);
                                              formik.setFieldValue(`addanotherservices.${index}.padding_time`, value);
                                              if (/^\d{1,2}:\d{2}$/.test(value) || value === "") {
                                                // console.log('duration', formik.values);
                                                // If valid, set the result field value
                                                if (formik.values.addanotherservices[index].service_id) {
                                                  let durationWithPadding = formik.values.addanotherservices[index].duration

                                                  if (formik.values && formik.values.addanotherservices && formik.values.addanotherservices[index] && formik.values.addanotherservices[index].padding_time) {
                                                    const duration1 = moment.duration(formik.values.addanotherservices[index].duration);
                                                    const duration2 = moment.duration(value);

                                                    const totalMinutes = duration1.asMinutes() + duration2.asMinutes();
                                                    const hours = Math.floor(totalMinutes / 60);
                                                    const minutes = totalMinutes % 60;

                                                    const sumDuration = moment({ hours, minutes });
                                                    const SumOfDuration = sumDuration ? sumDuration.format('HH:mm') : "";

                                                    // const durationMoment = moment.duration(SumOfDuration);
                                                    durationWithPadding = SumOfDuration
                                                  }
                                                  formik.setFieldValue(`addanotherservices.${index}.durationWithPadding`, durationWithPadding);

                                                  const addonservices = [...formik.values.addonservices];
                                                  const addanotherservices = [...formik.values.addanotherservices];
                                                  let parentAppointmentEndTime = "";

                                                  const totalAddOnDurationWithPadding = addonservices && addonservices.length > 0 && addonservices.reduce((sum, sduration) => {
                                                    // Check if sduration.durationWithPadding is a valid duration
                                                    const duration = moment.duration(sduration.durationWithPadding);
                                                    if (duration.isValid()) {
                                                      const sumDurationWithPadding = moment.duration(sduration.durationWithPadding);
                                                      return sum.add(sumDurationWithPadding);
                                                    }
                                                    return sum;
                                                  }, moment.duration(0));

                                                  if (addanotherservices && addanotherservices.length > 1) {
                                                    addanotherservices.pop();

                                                    const totalAddAnotherDurationWithPadding = addanotherservices.length > 0 && addanotherservices.reduce((sum, sduration) => {
                                                      // Check if sduration.durationWithPadding is a valid duration
                                                      const duration = moment.duration(sduration.durationWithPadding);
                                                      if (duration.isValid()) {
                                                        const sumDurationWithPadding = moment.duration(sduration.durationWithPadding);
                                                        return sum.add(sumDurationWithPadding);
                                                      }
                                                      return sum;
                                                    }, moment.duration(0));

                                                    const parentAppointmentDuration = formik.values.durationWithPadding;
                                                    const duration1 = moment.duration(parentAppointmentDuration);
                                                    const duration2 = totalAddOnDurationWithPadding;
                                                    const duration3 = totalAddAnotherDurationWithPadding;

                                                    // Check if each duration is valid before using it
                                                    const minutes1 = duration1 && duration1.isValid() ? duration1.asMinutes() : 0;
                                                    const minutes2 = duration2 && duration2.isValid() ? duration2.asMinutes() : 0;
                                                    const minutes3 = duration3 && duration3.isValid() ? duration3.asMinutes() : 0;

                                                    const totalMinutes = minutes1 + minutes2 + minutes3;
                                                    const hours = Math.floor(totalMinutes / 60);
                                                    const minutes = totalMinutes % 60;

                                                    const sumDuration = moment({ hours, minutes });
                                                    const SumOfDuration = sumDuration ? sumDuration.format('HH:mm') : "";
                                                    // console.log(SumOfDuration);
                                                    const durationMoment = moment.duration(SumOfDuration);
                                                    // appointmentStartDateTime = moment(`${form.values.dateof} ${addanotherservicesPrevObj.start_time}`, "dddd, DD MMMM YYYY HH:mm");
                                                    const appointmentStartDateTime = moment(`${formik.values.dateof} ${formik.values.start_time}`, "dddd, DD MMMM YYYY HH:mm");
                                                    parentAppointmentEndTime = appointmentStartDateTime.clone().add(durationMoment).format('YYYY-MM-DD HH:mm:00');

                                                  } else {
                                                    const appointmentStartDateTime = moment(`${formik.values.dateof} ${formik.values.start_time}`, "dddd, DD MMMM YYYY HH:mm");
                                                    // Parse the duration string to a moment duration
                                                    const duration1 = moment.duration(formik.values.durationWithPadding);
                                                    const duration2 = totalAddOnDurationWithPadding;
                                                    // Check if each duration is valid before using it
                                                    const minutes1 = duration1 && duration1.isValid() ? duration1.asMinutes() : 0;
                                                    const minutes2 = duration2 && duration2.isValid() ? duration2.asMinutes() : 0;

                                                    const totalMinutes = minutes1 + minutes2;
                                                    const hours = Math.floor(totalMinutes / 60);
                                                    const minutes = totalMinutes % 60;

                                                    const sumDuration = moment({ hours, minutes });
                                                    const SumOfDuration = sumDuration ? sumDuration.format('HH:mm') : "";

                                                    const durationMoment = moment.duration(SumOfDuration);
                                                    // Add the duration to the date and time
                                                    parentAppointmentEndTime = appointmentStartDateTime.clone().add(durationMoment).format('YYYY-MM-DD HH:mm:00');
                                                  }
                                                  const obj = {
                                                    ...formik.values.addanotherservices[index],
                                                    remove_addon: formik?.values?.remove_addon || [],
                                                    remove_addanother: formik?.values?.remove_addanother || [],
                                                    parent_id: formik.values && formik.values.id ? formik.values.id : "",
                                                    staff_id: formik.values.addanotherservices[index].staff_id ? formik.values.addanotherservices[index].staff_id : "",
                                                    dateof: formik.values.dateof,
                                                    padding_time: value,
                                                    durationWithPadding: durationWithPadding,
                                                    parentAppointmentEndTime: parentAppointmentEndTime,
                                                    listview: "Appointment",
                                                    formtype: "appointmentform",
                                                    formName: "appointmentForm/edit/addanotherservices/padding_time"
                                                  }
                                                  const addAnotherData = isAddAnother.find((event) => event.id === formik.values.addanotherservices[index].id);
                                                  let addanotherobj = { ...addAnotherData, duration: formik.values.addanotherservices[index].duration };
                                                  dispatch(AddAnotherResourceOptionsApi({ option: { valueField: "id", labelField: "resource_item" }, service_id: formik.values.addanotherservices[index].service_id, pagetype: "Booking", isNotInResourceIds: "", availebleTimeObj: { ...obj } })).then((actionResource) => {
                                                    if (actionResource.meta.requestStatus === "fulfilled") {
                                                      if (addAnotherData) {
                                                        dispatch(AddAnotherData({ ...addAnotherData, resource_items: actionResource.payload }));
                                                      }
                                                    } else {
                                                      dispatch(AddAnotherData(addanotherobj));
                                                    }
                                                  });
                                                  AddAnotherGetAvailableTime({
                                                    ...formik.values.addanotherservices[index],
                                                    remove_addon: formik?.values?.remove_addon || [],
                                                    remove_addanother: formik?.values?.remove_addanother || [],
                                                    dateof: formik.values.dateof,
                                                    padding_time: value,
                                                    durationWithPadding,
                                                    parentAppointmentEndTime,
                                                    listview: 'Appointment',
                                                    pagetype: "addanother_appointment",
                                                    formName: "appointmentForm/edit/addanotherservices/padding_time"
                                                  }, dispatch);
                                                }
                                              }
                                            }}
                                          />
                                        </div>
                                        <div className="col-md-3 col-4 mb-3">
                                          <InputField name={`addanotherservices.${index}.cost`} label={t("Cost")} controlId={`appointmentForm-addanotherservices-${index}-cost`} placeholder="" />
                                        </div>
                                        {isAddAnother.length > 0 && isAddAnother[index] && isAddAnother[index].resource_items && isAddAnother[index].resource_items.length > 0 ? (
                                          <div className="col-sm-12 mb-3">
                                            <ReactSelectField name={`addanotherservices.${index}.resource_item_id`} placeholder={t("Choose Resource")} label={t("Resource")} options={addanotherResourceOptionsData} controlId={`appointmentForm-addanotherservices-${index}-resource_item_id`} isMulti={false} service_id={formik.values.addanotherservices[index].service_id} staff_id={formik.values.addanotherservices[index].staff_id} isAddOn={isAddOn} indexID={index + 1} />
                                          </div>
                                        ) : isAddAnotherApiStatus && isAddAnotherApiStatus.AddAnotherResourceOptionsApi == "loading" ? (
                                          <div className="col-sm-12 mb-3">
                                            <label className="fw-semibold">{t("Resource")}</label>
                                            <div className="box-image-cover notification-boxs w-100 mx-0 px-2 py-2 text-start">
                                              <div className="col-12 placeholder-glow">
                                                <BarLoader color="#f4eeeb" className="w-100" height={"18px"} />
                                              </div>
                                            </div>
                                          </div>
                                        ) : ""}

                                        {/* <div className="col">
                             <label htmlFor={`addanotherservices.${index}.service_id`}>Service</label>
                             <Field name={`addanotherservices.${index}.service_id`} placeholder="Jane Doe" type="text" />
                             <ErrorMessage name={`addanotherservices.${index}.service_id`} component="div" className="field-error" />
                           </div>
                           <div className="col">
                             <label htmlFor={`addanotherservices.${index}.staff_id`}>Staff</label>
                             <Field name={`addanotherservices.${index}.staff_id`} placeholder="jane@acme.com" type="text" />
                             <ErrorMessage name={`addanotherservices.${index}.staff_id`} component="div" className="field-error" />
                           </div> */}
                                      </div>
                                    );
                                  })}
                                <input ref={notAnotherServicesIdRef} type="hidden" value={not_service_ids} />
                                <input ref={totalAddanotherServiceCostRef} type="hidden" value={totaladdanotherservicecost} />
                                <input ref={totalAddanotherServiceDurationRef} type="hidden" value={totaladdanotherserviceduration} />
                                {filteredData.length == formik.values.addanotherservices.length && (
                                  <button
                                    type="button"
                                    className="btn btn-cancel col-12 me-1 cursor-pointer mb-3"
                                    onClick={() => {
                                      if (notAnotherServicesIdRef && notAnotherServicesIdRef.current) {
                                      }
                                      push({ service_id: "", staff_id: "", duration: "", cost: "" });
                                      // dispatch(AddAnotherData(addOnData));
                                      dispatch(AddAnotherServicesApi({ service_id: formik.values.service_id, isNotId: not_service_ids, fieldtype: "addanother" })).then((action) => {
                                        if (action.meta.requestStatus === "fulfilled") {
                                          // addOnData.services = action.payload;
                                          let addOnData = { id: formik.values.addanotherservices.length + 1, parent_id: formik.values.id, serviceprice: "", services: [], staff: [], duration: "", cost: "" };
                                          dispatch(AddAnotherData({ ...addOnData, services: action.payload, fieldtype: "addanother" }));
                                        }
                                      });
                                    }}
                                  >
                                    {t("Add Another Service")}
                                  </button>
                                )}
                              </div>
                            );
                          }}
                        </FieldArray>
                      </>
                    )}
                    <div className="mb-3">
                      <SelectField name="repeats" value={formik.values.repeats} options={repeatsOptionsData} label={t("Repeats")} controlId="appointmentForm-repeats" />
                      {/* <a href="#" className="btn btn-outline-primary mt-3">
                        Add Another Service
                      </a> */}
                    </div>
                    {formik.values.repeats && formik.values.repeats === "Yes" && (
                      <div className="row mb-3">
                        <div className="col-6">
                          <div className="col-12">
                            <label className="mb-0">{t("Repeat this every")}</label>
                          </div>
                          <div className="row">
                            <div className="col-md-4">
                              <InputField type="text" name="repeat_time" value={formik.values.repeat_time} label={""} controlId="busytimeForm-repeat_time" />
                            </div>
                            <div className="col-md-8">
                              <SelectField name="repeat_time_option" placeholder={t("--Select--")} value={formik.values.repeat_time_option} options={repeattimeOptionsData} label={""} controlId="busytimeForm-repeat_time_option" />
                            </div>
                          </div>
                        </div>
                        <div className="col-6">
                          <label htmlFor="">{t("Ending (Optional)")}</label>
                          <DatePicker
                            weekDays={config.weekDays}
                            className="purple"
                            renderButton={(direction, handleClick) => (
                              <a className="btn btn-transparent shadow-none" onClick={handleClick}>
                                {direction === "right" ? <i className="fas fa-chevron-right p-0"></i> : <i className="fas fa-chevron-left p-0"></i>}
                              </a>
                            )}
                            name="ending"
                            id="busytimeForm-ending"
                            value={formik.values.ending}
                            inputClass={"form-control ending"}
                            placeholder={t("Select Date")}
                            format={"DD MMMM YYYY"}
                            minDate={formik.values.dateof ? moment(formik.values.dateof).add(9, "days").toDate() : new Date()}
                            onChange={(e) => {
                              let getselectedDatePicker = e ? moment(e?.toString(), "DD MMMM YYYY").format("DD MMMM YYYY") : "";
                              formik.setFieldValue("ending", getselectedDatePicker);
                            }}
                          />
                          {formik.touched && formik.touched.ending && formik.errors && formik.errors.ending && <div className="invalid-feedback d-block">{formik.errors.ending}</div>}
                        </div>
                      </div>
                    )}
                    <div className="mb-3">
                      <TextareaField type="text" name="booking_notes" placeholder={t("Add any notes about the appointment")} value={formik.values.booking_notes} label={t("Booking notes")} controlId="appointmentForm-booking_notes" />
                    </div>
                    {parseFloat(depositBalance) > 0 && (
                      <a className="btn bg-beige w-100 color-wine mb-2">
                        <div className="d-flex align-items-center justify-content-between">
                          <h5 className="font-bold mb-0">Deposit Paid</h5>
                          <h5 className="font-bold mb-0">${depositBalance}</h5>
                        </div>
                      </a>
                    )}
                    {openStripeSetupModal && (
                      <>
                        <div className={openStripeSetupModal ? "modal fade show" : "modal fade"} id="add-emailverification" tabIndex="-1" aria-labelledby="addemailverificationModalLabel" style={{ display: openStripeSetupModal ? "block" : "none" }}>
                          <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                              <div className="modal-body pb-0">
                                <div className="p-3">
                                  <h5 className="mb-3 fw-semibold text-dark">{t("Online Payment")}</h5>
                                  <p className="fw-semibold mb-2">Connect your <span className="color-wine font-semibold text-decoration-underline cursor-pointer" onClick={() => navigate("/settings?tab=Integrations")}>Stripe account</span> to accept online bookings and payments.</p>
                                </div>
                                <div className="row mt-3 bg-cream p-4 justify-content-center">
                                  <div className="col-6">
                                    <button className="btn btn-primary w-100" onClick={() => {
                                      setOpenStripeSetupModal(false);
                                    }} type="button">
                                      {t("OK")}
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {openStripeSetupModal && <div className="modal-backdrop show"></div>}
                      </>
                    )}
                    {openConfirmationModal && (
                      <>
                        <div className={openConfirmationModal ? "modal fade show" : "modal fade"} id="add-emailverification" tabIndex="-1" aria-labelledby="addemailverificationModalLabel" style={{ display: openConfirmationModal ? "block" : "none" }}>
                          <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                              <div className="modal-body pb-0">
                                <div className="p-3">
                                  <h5 className="mb-3 fw-semibold text-dark">{t("Confirm Payment")}</h5>
                                  <p className="fw-semibold mb-2">{t(`Are you sure you want to confirm this payment ${isCalendarEditFormSetActivebtnState && isCalendarEditFormSetActivebtnState === "credit_card" ? "via credit card" : isCalendarEditFormSetActivebtnState && isCalendarEditFormSetActivebtnState === "cash" ? "via cash" : ""} ?`)}</p>
                                </div>
                                <div className="row mt-3 bg-cream p-4 justify-content-center">
                                  <div className="col-6">
                                    <button className="preview btn me-1 cursor-pointer btn-preview w-100" type="button" onClick={() => {
                                      setOpenConfirmationModal(false);
                                    }}>
                                      {t("Cancel")}
                                    </button>
                                  </div>
                                  <div className="col-6">
                                    <button className="btn btn-primary w-100" onClick={() => {
                                      if (isCalendarEditFormSetActivebtnState === "CreditCard") {
                                        dispatch(GetStripeTokenAppointment(""))
                                        formik.setFieldValue("deposit_payment_by", "CreditCard", true)
                                        formik.setFieldValue("stripe_token_id", "", true)
                                        setOpenConfirmationModal(false);
                                      } else if (isCalendarEditFormSetActivebtnState === "Cash") {
                                        dispatch(GetStripeTokenAppointment(""))
                                        formik.setFieldValue("deposit_payment_by", "Cash", true)
                                        formik.setFieldValue("stripe_token_id", "", true)
                                        setOpenConfirmationModal(false);
                                      }
                                    }} type="button">
                                      {t("Confirm")}
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {openConfirmationModal && <div className="modal-backdrop show"></div>}
                      </>
                    )}
                    {!(isOpenDepositPayTab) ? (
                      <>
                        <a className="btn bg-beige w-100 cursor-default color-wine mb-2">
                          <div className="d-flex align-items-center justify-content-between">
                            <h6 className="font-bold text-dark mb-0">Deposit</h6>
                            <h6 className="color-wine mb-0 text-decoration-underline cursor-pointer" onClick={() => {
                              dispatch(OpenDepositPayTab(true));
                              setBtnConfirmation(false);
                              if (formik.values.totaldeposit !== null && formik.values.totaldeposit !== undefined && !isNaN(parseFloat(formik.values.totaldeposit)) && parseFloat(formik.values.totaldeposit) > 0) {
                              } else {
                                formik.setFieldValue("totaldeposit", 0, false)
                              }
                            }}>Add Deposit</h6>
                          </div>
                        </a>
                        {/* <button
                        type="button"
                        className="btn btn-apricots w-100"
                        onClick={() => {
                          dispatch(OpenDepositPayTab(true));
                          setBtnConfirmation(false);
                        }}
                      >
                        {t("Add Deposit")}
                      </button>*/}
                      </>
                    ) : ""}
                    {isOpenDepositPayTab ? (
                      <div className="row gx-2 bg-beige rounded p-4 mb-2">
                        <div className="pay-card-details-wr">
                          {/* <label htmlFor="" className="text-dark fw-semibold">Card Details</label> */}
                          <div className="col-12 mb-3">
                            <div className="d-flex align-items-center justify-content-between">
                              <div className="input-deposit-label">
                                <label htmlFor="appointmentForm-deposit">Remaining Deposit</label>
                              </div>
                              <div className="remove-deposits">
                                <a className="color-wine text-decoration-underline cursor-pointer" onClick={() => {
                                  dispatch(OpenDepositPayTab(false));
                                  formik.setFieldValue("deposit_payment_by", "", true);
                                  formik.setFieldValue("stripe_token_id", "", true);
                                  dispatch(GetStripeTokenAppointment(""));
                                  setBtnConfirmation(true);
                                }}>Remove deposit</a>
                              </div>
                            </div>
                            <Field type='text' name="totaldeposit" className='form-control' value={formik.values.totaldeposit} id="appointmentForm-deposit"
                              onKeyDown={(e) => {
                                if (e.key === '-' || e.key === 'Minus') {
                                  e.preventDefault();
                                }
                              }}
                              onChange={(e) => {
                                const inputValue = e.target.value;
                                const sanitizedInput = inputValue.replace(/[^0-9.-]/g, '');
                                const firstMinusIndex = sanitizedInput.indexOf('-');
                                const hasMultipleMinus = firstMinusIndex !== -1 && sanitizedInput.indexOf('-', firstMinusIndex + 1) !== -1;
                                const numericInput = hasMultipleMinus ? sanitizedInput.replace(/-/g, '').replace('-', '') : sanitizedInput;
                                formik.setFieldValue("totaldeposit", numericInput);
                              }}
                            />
                            {/* <InputField type="text" name="totaldeposit" value={formik.values.totaldeposit} controlId="appointmentForm-deposit" /> */}
                            <ErrorMessage name="totaldeposit" component="div" className="text-danger mt-1" />
                          </div>
                          <div className="row justify-content-center">
                            <div className="col-12 col-sm-4 mb-md-0 mb-3 pe-md-1">
                              <button type="button" className={`btn ${formik.values.deposit_payment_by === "StripeCreditCard" ? "btn-primary" : "btn-dark"} w-100`} style={{ padding: "09px 12px" }} onClick={() => {
                                if (currentUser && currentUser.salon && currentUser.salon.is_stripe_verify == 1) {
                                  dispatch(CalendarEditFormSetActivebtnState(""));
                                  dispatch(OpenInvoiceStripePayModal("open"));
                                } else {
                                  setOpenStripeSetupModal(true);
                                }
                              }}>
                                {t("Pay with Stripe")}
                              </button>
                            </div>
                            <div className="col-12 col-sm-4 mb-md-0 mb-3 px-sm-1 px-auto">
                              <button type="button" className={`btn ${formik.values.deposit_payment_by === "CreditCard" ? "btn-primary" : "btn-dark"} w-100`} onClick={() => {
                                setOpenConfirmationModal(true);
                                dispatch(CalendarEditFormSetActivebtnState("CreditCard"));
                              }}>
                                {t("Paid by Card")}
                              </button>
                            </div>
                            <div className="col-12 col-sm-4 ps-sm-1">
                              <button type="button" className={`btn ${formik.values.deposit_payment_by === "Cash" ? "btn-primary" : "btn-dark"} w-100`} onClick={() => {
                                setOpenConfirmationModal(true);
                                dispatch(CalendarEditFormSetActivebtnState("Cash"));
                              }}>
                                {t("Paid by Cash")}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : ""}
                    {formik.status && formik.status.warning && <p className="text-danger mb-0 pt-1 pb-1"> {formik.status.warning}</p>}
                  </div>
                  <div className="drawer-footer pt-0">
                    <div className="row gx-2 align-items-center footer-top">
                      <div className="col-md-6 mb-md-0 mb-3">
                        <SwitchField
                          name="status"
                          label={t("Confirmed")}
                          controlId="appointmentForm-status"
                          value="1"
                          onChange={(e) => {
                            if (e.currentTarget.checked) {
                              // setTimeout(() => {
                              formik.setFieldValue("status", 1, false);
                              // }, 100);
                            } else {
                              // setTimeout(() => {
                              formik.setFieldValue("status", "", false);
                              // }, 100);
                            }
                            //formik.handleChange(e);
                          }}
                        />
                      </div>
                      <div className="col-md-6">
                        <SelectField name="status_manage" placeholder={""} value={formik.values.status_manage} options={statusmanageOptionsData} label={"0"} controlId="appointmentForm-status_manage" />
                      </div>
                    </div>
                    <div className="row justify-content-between mt-3 mb-lg-2">
                      <div className="col-auto h5 mb-3 fw-semibold">
                        {/* {(getHours(formik.values.duration, "H:m") || getMinutes(formik.values.duration, "H:m")) &&
                          t("Total of {{hour}}hr {{minute}}minutes", {
                            hour: getHours(moment(formik.values.duration, "HH:mm").add(totalAddonServiceDuration, "minutes").format("HH:mm"), "H:m"),
                            minute: getMinutes(moment(formik.values.duration, "HH:mm").add(totalAddonServiceDuration, "minutes").format("HH:mm"), "H:m"),
                          })} */}
                        {(() => {
                          let totalDuration = 0;
                          if (formik.values.duration && /^\d{2}:\d{2}$/.test(formik.values.duration)) {
                            const [hours, minutes] = formik.values.duration.split(':');
                            totalDuration += parseInt(hours) * 60 + parseInt(minutes);
                          }

                          if (formik.values.addonservices && formik.values.addonservices.length > 0) {
                            formik.values.addonservices.forEach((service) => {
                              const duration = (service.duration && /^\d{2}:\d{2}$/.test(service.duration)) ? service.duration : '00:00';
                              const [hours, minutes] = duration.split(':');
                              totalDuration += (parseInt(hours) || 0) * 60 + (parseInt(minutes) || 0);
                            });
                          }

                          if (formik.values.addanotherservices && formik.values.addanotherservices.length > 0) {
                            formik.values.addanotherservices.forEach((service) => {
                              const duration = (service.duration && /^\d{2}:\d{2}$/.test(service.duration)) ? service.duration : '00:00';
                              const [hours, minutes] = duration.split(':');
                              totalDuration += (parseInt(hours) || 0) * 60 + (parseInt(minutes) || 0);
                            });
                          }

                          // Calculate total hours and minutes
                          const totalHours = Math.floor(totalDuration / 60);
                          const totalMinutes = totalDuration % 60;

                          // Format the total duration
                          return `Total of ${totalHours} hr ${totalMinutes} minutes`;
                        })()}
                      </div>
                      <div className="col-auto h5 mb-3 fw-semibold float-end">
                        ${(() => {
                          let totalCost = 0;

                          // Calculate total cost from the main appointment
                          if (!isNaN(parseFloat(formik.values.cost))) {
                            totalCost += parseFloat(formik.values.cost);
                          }

                          // Calculate total cost from addonservices
                          if (formik.values.addonservices && formik.values.addonservices.length > 0) {
                            formik.values.addonservices.forEach((service) => {
                              if (!isNaN(parseFloat(service.cost))) {
                                totalCost += parseFloat(service.cost);
                              }
                            });
                          }

                          // Calculate total cost from addanotherservices
                          if (formik.values.addanotherservices && formik.values.addanotherservices.length > 0) {
                            formik.values.addanotherservices.forEach((service) => {
                              if (!isNaN(parseFloat(service.cost))) {
                                totalCost += parseFloat(service.cost);
                              }
                            });
                          }

                          // Display the total cost
                          return totalCost.toFixed(2); // Ensure the total cost is displayed with 2 decimal places
                        })()}
                      </div>
                      {/* <div className="col-auto h5 mb-3 fw-semibold float-end">${formik.values.cost ? parseFloat(formik.values.cost) + parseFloat(totalAddonServiceCostRef && totalAddonServiceCostRef.current && totalAddonServiceCostRef.current.value && totalAddonServiceCostRef.current.value) : "00.00"}</div> */}
                    </div>
                    {/* MY CUSTOM MODAL */}
                    <div className={showModal ? "modal fade show" : "modal fade"} id="add-emailverification" tabIndex="-1" aria-labelledby="addemailverificationModalLabel" style={{ display: showModal ? "block" : "none" }}>
                      <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                          <div className="modal-body pb-0">
                            <div className="p-3">
                              {confirmedRefund ? (
                                <>
                                  <div className="d-flex justify-content-between">
                                    <h5 className="mb-3 fw-semibold text-dark">{t("Initiate Refund?")}</h5>
                                    <a onClick={() => {
                                      setConfirmedRefund(false);
                                    }}>
                                      <i className="fas fa-times p-0" style={{ fontSize: "22px", fontWeight: "300", color: "#8c1c4d" }}></i>
                                    </a>
                                  </div>
                                  <p className="fw-semibold mb-2">{t("Do you want to initiate a refund for this cancelled appointment?")}</p>
                                </>
                              ) : (
                                <>
                                  <h5 className="mb-3 fw-semibold text-dark">{t("Are you sure you want to cancel this appointment?")}</h5>
                                  <div className="col-12 mb-2">
                                    <select
                                      name="cancellation_reason"
                                      className="form-control"
                                      value={selectedCancellationReason}
                                      onChange={(e) => {
                                        setSelectedCancellationReason(e.target.value);
                                        setShowError(false);
                                      }}
                                    >
                                      <option value="">{t("--Reason for Cancellation--")}</option>
                                      {cancellationreasonObjectData.map((reason) => (
                                        <option key={reason.id} value={reason.id}>
                                          {reason.reason}
                                        </option>
                                      ))}
                                    </select>
                                    {showError && !selectedCancellationReason && <p className="mb-0 text-danger">Please select a cancellation reason</p>}
                                  </div>
                                </>
                              )}
                            </div>
                            {confirmedRefund ? (
                              <div className="row mt-3 bg-cream p-4 justify-content-center">
                                <div className="col-6">
                                  <button className="btn btn-primary w-100" type="button" onClick={() => {
                                    dispatch(GetAppointmentcancelRefundStripe(""));
                                    dispatch(CancellationReason({ selectedCancellationReason: selectedCancellationReason, isRangeInfo: isRangeInfo, depositBalance: depositBalance }));
                                    dispatch(EditAppointmentFormData(formik.values));
                                    setShowModal(false);
                                    setConfirmedRefund(false);
                                    dispatch(OpenEditAppointmentRefunds("open"));
                                  }}>
                                    {t("Yes")}
                                  </button>
                                </div>
                                <div className="col-6">
                                  <button
                                    className="preview btn me-1 cursor-pointer btn-preview w-100"
                                    onClick={() => {
                                      const cancellation_reason = selectedCancellationReason;
                                      if (!cancellation_reason || cancellation_reason === "") {
                                        setShowError(true);
                                      } else {
                                        setLoading(true);
                                        dispatch(appointmentUpdateApi({
                                          id: formik.values.id,
                                          client_id: formik.values.client_id,
                                          status: "Cancelled",
                                          clickEvent: "statusupdate",
                                          cancellation_reason: cancellation_reason,
                                        })).then((action) => {
                                          setLoading(false); // Hide the loading spinner
                                          if (action.meta.requestStatus === "fulfilled") {
                                            const payload = action.payload;
                                            dispatch(AppointmentDetailModal(""));
                                            formik.setStatus({ success: true });
                                            formik.resetForm();
                                            dispatch(servicePriceApi({ service_id: "" }));
                                            dispatch(AppointmentDetailModal(""));
                                            dispatch(EditAppointmentForm());
                                            dispatch(ClientAppointmentListViewApi({ client: detail.id, client_id: detail.id, filter: isFilter, page_name: "client_appointment" }));
                                            if (isRangeInfo) {
                                              dispatch(appointmentListViewApi(isRangeInfo));
                                              dispatch(appointmentDetailApi(payload));
                                            }
                                            customToast.success(`${t("Appointment booking Cancelled")}`);
                                          } else {
                                            customToast.error(t("Error occurred during cancellation"));
                                          }
                                        });
                                        setShowModal(false);
                                      }
                                    }}
                                    type="button"
                                    disabled={loading}
                                  >
                                    {loading && <span className="spinner-border spinner-border-sm"></span>}
                                    {t("No")}
                                  </button>
                                </div>
                              </div>
                            ) : (
                              <div className="row mt-3 bg-cream p-4 justify-content-center">
                                <div className="col-6">
                                  <button className="preview btn me-1 cursor-pointer btn-preview w-100" type="button" onClick={() => setShowModal(false)}>
                                    {t("Cancel")}
                                  </button>
                                </div>
                                <div className="col-6">
                                  <button
                                    className="btn btn-primary w-100"
                                    onClick={() => {
                                      const cancellation_reason = selectedCancellationReason;
                                      if (!cancellation_reason || cancellation_reason === "") {
                                        setShowError(true);
                                      } else {
                                        if (!confirmedRefund && depositBalance && depositBalance > 0) {
                                          setConfirmedRefund(true);
                                        } else {
                                          setLoading(true);
                                          dispatch(appointmentUpdateApi({
                                            id: formik.values.id,
                                            client_id: formik.values.client_id,
                                            status: "Cancelled",
                                            clickEvent: "statusupdate",
                                            cancellation_reason: cancellation_reason,
                                          })).then((action) => {
                                            setLoading(false); // Hide the loading spinner
                                            if (action.meta.requestStatus === "fulfilled") {
                                              const payload = action.payload;
                                              dispatch(AppointmentDetailModal(""));
                                              formik.setStatus({ success: true });
                                              formik.resetForm();
                                              dispatch(servicePriceApi({ service_id: "" }));
                                              dispatch(EditAppointmentForm());
                                              dispatch(ClientAppointmentListViewApi({ client: detail.id, client_id: detail.id, filter: isFilter, page_name: "client_appointment" }));
                                              if (isRangeInfo) {
                                                dispatch(appointmentListViewApi(isRangeInfo));
                                                dispatch(appointmentDetailApi(payload));
                                              }
                                              customToast.success(`${t("Appointment booking Cancelled")}`);
                                            } else {
                                              customToast.error(t("Error occurred during cancellation"));
                                            }
                                          });
                                          setShowModal(false);
                                        }
                                      }
                                    }}
                                    type="button"
                                    disabled={loading}
                                  >
                                    {loading && <span className="spinner-border spinner-border-sm"></span>}
                                    {t("Confirm")}
                                  </button>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    {showModal && <div className="modal-backdrop show"></div>}
                    <div className="row">
                      <div className="col-6">
                        <button
                          type="button"
                          className="btn btn-danger w-100 btn-lg"
                          disabled={loading}
                          onClick={(e) => {
                            dispatch(CancellationreasonListViewApi())
                            setShowModal(true);
                            // console.log("Cancellation Reason Data:", cancellationreasondatawithvalue); // Add this console log
                            // let confirmbtn = swalConfirmInput(e.currentTarget, { title: t("Are you sure you want to cancel the appointment?"), message: "", confirmButtonText: t("Yes, cancelled it!"), options: cancellationreasondatawithvalue });

                            // if (confirmbtn == true) {
                            //   let cancellation_reason = e.target.getAttribute("reason");
                            //   dispatch(appointmentUpdateApi({ id: formik.values.id, client_id: formik.values.client_id, status: "Cancelled", clickEvent: "statusupdate", cancellation_reason: cancellation_reason })).then((action) => {
                            //     if (action.meta.requestStatus === "fulfilled") {
                            //       formik.setStatus({ success: true });
                            //       formik.resetForm();
                            //       dispatch(servicePriceApi({ service_id: "" }));
                            //       dispatch(EditAppointmentForm());
                            //       dispatch(ClientAppointmentListViewApi({ client: detail.id }));
                            //       if (isRangeInfo) {
                            //         dispatch(appointmentListViewApi(isRangeInfo));
                            // dispatch(appointmentDetailApi({ id: detail.id, client_id: detail.client_id, showdate: detail.showdate }));
                            //       }
                            //       customToast.success(`${t("Appointment booking Cancelled")}`);
                            //     }
                            //   });
                            // }
                          }}
                        >
                          {loading && <span className="spinner-border spinner-border-sm"></span>}
                          {t("Cancel Appointment")}
                        </button>
                      </div>
                      <div className="col-6">
                        <button type="submit" className="btn btn-appointments btn-primary w-100 btn-lg" disabled={loading || ((parseFloat(formik.values.totaldeposit) > 0 && !(formik.values.deposit_payment_by === "StripeCreditCard" || formik.values.deposit_payment_by === "Cash" || formik.values.deposit_payment_by === "CreditCard")) && isOpenDepositPayTab)}>
                          {loading && <span className="spinner-border spinner-border-sm"></span>}
                          {t("Save Appointment")}
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          );
        }}
      </Formik>
    </>
  );
};
AppointmentEditForm.propTypes = {
  isRangeInfo: PropTypes.oneOfType([PropTypes.node, PropTypes.array, PropTypes.object]),
  page: PropTypes.string,
};
export default AppointmentEditForm;
