import React, { useRef } from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import config from "../../config";
import { useTranslation } from "react-i18next";
import { servicePriceApi } from "store/slices/serviceSlice";
import { useDispatch, useSelector } from "react-redux";
import { StaffOptionsAutofillApi, staffOptions } from "store/slices/staffSlice";
import { SaleServiceToCartApi } from "store/slices/saleSlice";
import { BookingLinkServiceOptionReset, BookingLinkServiceOptions, BookingLinkStaffOptionReset, BookingLinkStaffOptions } from "store/slices/bookinglinkSlice";
import { GetAvailableTime } from "./GetAvailableTime";
import { AddOnData, AddOnResourceOptionsApi, AddOnServicePriceApi, AddonStaffOptionsApi } from "store/slices/addonSlice";
import { AddonGetAvailableTime } from "./AddonGetAvailableTime";
import { HoursToMinutes, MinutesToHours } from "helpers/Functions";
import { AddAnotherData, AddAnotherResourceOptionsApi, AddAnotherServicePriceApi, AddAnotherStaffOptionsApi } from "store/slices/addanotherSlice";
import { AddAnotherGetAvailableTime } from "./AddAnotherGetAvailableTime";
import { AddOnServiceButtonRender, AppointmentAutoFillFinalData, AppointmentAvailableTime, AppointmentResourceItemsApi, AppointmentResourceItemsClears, BookNextAvailableResourceApi, BookNextModalOpen, OpenDepositPayTab } from "store/slices/appointmentSlice";
import BeatLoader from "react-spinners/BeatLoader";
import moment from "moment";
import customToast from "component/Toastr";
import { Link } from "react-router-dom";
// interface Option {
//   label: string;
//   value: string;
// }

// interface CustomSelectProps extends FieldProps {
//   options: OptionsType;
//   isMulti?: boolean;
//   className?: string;
//   placeholder?: string;
// }

export const CustomSelect = ({ className, placeholder, field, form, options, isMulti = false, controlId, service_id = "", staff_id = "", page = "", isAddOn = "", isAddAnother = "", indexID = "", page_name = "", singlestaffaccess = "" }) => {
  const selectRef = useRef();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const ServiceApisLoading = useSelector((state) => state.service.isApiStatus);
  const AppointmentApiStatus = useSelector((state) => state.appointment.isApiStatus);
  const StaffApiStatus = useSelector((state) => state.staff.isApiStatus);
  const isAppointmentAutofillData = useSelector((state) => state.appointment.isAppointmentAutofillData);
  let noOptionsMessage = () => {
    if (field.name === "staff_id" && controlId && controlId != "busytimeForm-staff_id") {
      return t("Please Select a Service First.");
    }
    if (field.name === "service_id") {
      return t("No Services have been created yet. Please create one.");
    }
    if (field.name === "category_id") {
      return t("No categories have been created yet. Please create one first.");
    }
    if (field.name === "supplier_id") {
      return t("No suppliers have been created yet. Please create one.");
    }
    if (field.name === "resource_item_id") {
      if (AppointmentApiStatus && AppointmentApiStatus.AppointmentResourceItemsApi === "loading") {
        return (<BeatLoader color="#8c1c4d" className="text-center" />);
      } else {
        if (form && form.values) {
          if (!form.values.service_id) {
            return t("Please Select a Service First.");
          } else if (!form.values.staff_id) {
            return t("Please Select a Staff First.");
          } else if (!form.values.dateof) {
            return t("Please Select a Date First.");
          } else if (!form.values.start_time) {
            return t("Please Select a Service Start Time First.");
          } else {
            return t("No resource has been created yet or assigned to this service.");
          }
        } else {
          return t("No resource has been created yet or assigned to this service.");
        }
      }
    }
    if (field.name === "resource_id" && controlId && controlId === "serviceForm-resource_id") {
      if (ServiceApisLoading && ServiceApisLoading.ServiceResourceDropdownApi === "loading") {
        return (<BeatLoader color="#8c1c4d" className="text-center" />)
      } else {
        return (
          <span>
            {t('No resource has been created yet.')}
            <br />
            <Link to={"/resource"}>{t('Please create one.')}</Link>
          </span>
        );
      }
    }
    return t("No options");
  };

  const onChange = (option) => {
    if (field.name === "category_id" && controlId === "bookingButtonForm-category_id") {
      dispatch(BookingLinkStaffOptionReset());
      dispatch(BookingLinkServiceOptionReset());
      form.setFieldValue('service_id', "");
      form.setFieldValue('staff_id', "");
      if (option && option.value) {
        dispatch(BookingLinkServiceOptions({ option: { valueField: "id", labelField: "name" }, category_id: option && option.value, pagetype: 'Booking' }));
      } else {
        dispatch(BookingLinkServiceOptionReset());
      }
    }
    if (field.name === "service_id" && controlId === "bookingButtonForm-service_id") {
      if (option && option.value) {
        dispatch(BookingLinkStaffOptions({ option: { valueField: "users.id", labelField: "CONCAT(users.first_name,' ',users.last_name)" }, service_id: option && option.value, singlestaffaccess: singlestaffaccess, page_name: page_name, pagetype: 'Booking' }));
      } else {
        dispatch(BookingLinkStaffOptionReset());
        form.setFieldValue('staff_id', "");
      }
    }
    if (field.name === "service_id" && controlId === "appointmentautofillForm-service_id") {
      if (option && option.value) {
        dispatch(AddOnServiceButtonRender(false));
        dispatch(servicePriceApi({ service_id: option && option.value, staff_id: form.values.staff_id })).then((action) => {
          if (action.meta.requestStatus === "fulfilled") {
            // form.setFieldValue("warningMessage","");
            let service = action.payload;
            // if (!form.values.lastObj) {
            //   form.setFieldValue("lastObj", { ...form.values });
            // } else {
            //   const lastObj = form.values.lastObj;
            //   form.setFieldValue("available_time_obj", lastObj.available_time_obj);
            //   form.setFieldValue("resource_item_id", lastObj.resource_item_id);
            //   form.setFieldValue("dateof", lastObj.dateof);
            //   form.setFieldValue("start_datetime", lastObj.start_datetime);
            //   form.setFieldValue("start_time", lastObj.start_time);
            //   form.setFieldValue("display_text", lastObj.display_text);
            //   form.setFieldValue("BookNextModalOpen", lastObj.BookNextModalOpen);
            //   form.values.available_time_obj = lastObj.available_time_obj
            //   form.values.resource_item_id = lastObj.resource_item_id
            //   form.values.dateof = lastObj.dateof
            //   form.values.start_datetime = lastObj.start_datetime
            //   form.values.start_time = lastObj.start_time
            //   form.values.display_text = lastObj.display_text
            //   form.values.BookNextModalOpen = lastObj.BookNextModalOpen
            // }

            const duration = service && service.duration && service.duration > 0 ? MinutesToHours(service.duration) : "";
            const padding_time = service && service.padding_time && service.padding_time > 0 ? MinutesToHours(service.padding_time) : "";
            const durationWithPadding = service && service.padding_time && service.padding_time > 0 ? MinutesToHours(parseInt(service.padding_time) + parseInt(service.duration)) : (service && service.duration ? MinutesToHours(service.duration) : "")

            if (service && service.add_on_services_count && service.add_on_services_count > 0) {
              dispatch(AddOnServiceButtonRender(true));
            } else if (service && service.add_on_services_count && service.add_on_services_count <= 0) {
              dispatch(AddOnServiceButtonRender(false));
            }

            form.setFieldValue("duration", duration);
            form.setFieldValue("padding_time", padding_time);
            form.setFieldValue("durationWithPadding", durationWithPadding);

            if (service && service.serviceprice && option) {
              let serviceprice = service.serviceprice;
              let generalPrice = serviceprice;
              let depositsPriceState = service && service.deposit_booked_price ? parseFloat(service.deposit_booked_price) : 0;
              let gprice = generalPrice && generalPrice.length === 1 ? generalPrice[0].price : "0.00";
              // let add_on_price = generalPrice && generalPrice.length === 1 ? generalPrice[0].add_on_price : "0.00";
              // let totalprice = parseFloat(gprice) + parseFloat(add_on_price); //client need only gprice 16-12-2022
              let totalprice = parseFloat(gprice);
              form.setFieldValue('cost', String(totalprice));
              const updatedServiceDeposit = [
                { service_id: option && option.value, deposit_price: service.deposit_booked_price, service_type: "primary", total_cost: service && service.serviceprice && service.serviceprice.length > 0 ? service.serviceprice[0].price : "0" },
              ];
              form.setFieldValue("service_deposit", updatedServiceDeposit);
              if (service && depositsPriceState && depositsPriceState > 0 && service.deposit_booked_online == 1) {
                form.setFieldValue("totaldeposit", depositsPriceState);
                dispatch(OpenDepositPayTab(true));
              } else {
                form.setFieldValue("totaldeposit", 0);
                dispatch(OpenDepositPayTab(false));
              }

            }
            const dateof = form.values.dateof && form.values.dateof && moment(form.values.dateof, "dddd, DD MMMM YYYY").isValid() ? moment(form.values.dateof, "dddd, DD MMMM YYYY").format("YYYY-MM-DD") + " " + moment().format("HH:mm:ss") : "";
            const obj = {
              appointment_id: "",
              busytime_id: "",
              dateof: dateof,
              start_datetime: form.values.start_datetime,
              duration: duration,
              padding_time: padding_time,
              durationWithPadding: durationWithPadding,
              listview: "Appointment",
              salon_id: form.values.salon_id,
              service_id: option && option.value,
              staff_id: form.values.staff_id,
              resource_item_id: form.values.resource_item_id,
              uid: 1,
              formtype: "appointmentautofillForm",
              selectedOptionType: form.values.selectedOptionType,
              formName: "appointmentautofillForm/service"
            }
            form.setFieldValue("available_time_obj", obj);

            if (form.values.selectedOptionType === "staff") {
              dispatch(AppointmentResourceItemsApi({
                option: { valueField: "id", labelField: "resource_item" },
                service_id: option && option.value,
                availebleTimeObj: obj
              })).then((action) => {
                if (action.meta.requestStatus === "fulfilled" && action.payload.length === 0) {
                  dispatch(BookNextAvailableResourceApi(obj)).then((BookNextAction) => {

                    if (BookNextAction.meta.requestStatus === "fulfilled") {
                      const availableNextData = BookNextAction && BookNextAction.payload;
                      if (availableNextData && (availableNextData.start_datetime && availableNextData.time.length > 0)) {
                        // if(availableNextData.service_assign_resource){
                        const FormattedDateOf = availableNextData.dateof ? moment(availableNextData.dateof, "YYYY-MM-DD HH:mm:ss").format("dddd, D MMMM YYYY") : "";
                        const FormattedStartTime = availableNextData.dateof ? moment(availableNextData.dateof, "YYYY-MM-DD HH:mm:ss").format("HH:mm") : "";
                        const StartDateTime = availableNextData.start_datetime ? availableNextData.start_datetime : "";
                        const ResourceItemId = availableNextData.resource_item_id ? availableNextData.resource_item_id : "";
                        const SalonId = availableNextData.salon_id ? availableNextData.salon_id : "";
                        const serviceId = availableNextData.service_id ? availableNextData.service_id : "";
                        const StaffId = availableNextData.staff_id ? availableNextData.staff_id : "";
                        const ServiceDurationWithPadding = availableNextData.durationWithPadding ? availableNextData.durationWithPadding : "";
                        const ServicePadding = availableNextData.padding_time ? availableNextData.padding_time : "";
                        const ServiceDuration = availableNextData.duration ? availableNextData.duration : "";
                        const time = availableNextData.time ? availableNextData.time : "";
                        const newObj = {
                          appointment_id: "",
                          busytime_id: "",
                          dateof: FormattedDateOf,
                          start_datetime: StartDateTime,
                          duration: ServiceDuration,
                          padding_time: ServicePadding,
                          durationWithPadding: ServiceDurationWithPadding,
                          listview: "Appointment",
                          salon_id: SalonId,
                          service_id: serviceId,
                          staff_id: StaffId,
                          resource_item_id: ResourceItemId,
                          uid: 1,
                          formtype: "appointmentautofillForm"
                        }
                        if (availableNextData.service_assign_resource) {
                          form.setFieldValue("available_time_obj", newObj);
                          form.setFieldValue("resource_item_id", ResourceItemId);
                          form.setFieldValue("dateof", FormattedDateOf);
                          form.setFieldValue("start_datetime", StartDateTime ? StartDateTime : "");
                          form.setFieldValue("start_time", FormattedStartTime);
                          form.setFieldValue("display_text", availableNextData.display_text ? availableNextData.display_text : "");
                          dispatch(AppointmentAvailableTime({ time: time }));
                          dispatch(BookNextModalOpen(true));
                        } else {
                          const form_start_time = moment(form.values.start_datetime, "YYYY-MM-DD HH:mm:ss").format("HH:mm:ss");
                          if (time.includes(form_start_time)) {
                            form.setFieldValue("available_time_obj", newObj);
                            form.setFieldValue("resource_item_id", ResourceItemId);
                            form.setFieldValue("dateof", FormattedDateOf);
                            form.setFieldValue("start_datetime", StartDateTime ? StartDateTime : "");
                            form.setFieldValue("start_time", FormattedStartTime);
                            form.setFieldValue("display_text", availableNextData.display_text ? availableNextData.display_text : "");
                            dispatch(AppointmentAvailableTime({ time: time }));
                          } else {
                            form.setFieldValue("start_time", "");
                            customToast.error("Staff not available");
                          }
                        }
                        // }
                      } else {
                        const SalonId = availableNextData.salon_id ? availableNextData.salon_id : "";
                        const serviceId = availableNextData.service_id ? availableNextData.service_id : "";
                        const StaffId = availableNextData.staff_id ? availableNextData.staff_id : "";

                        if (SalonId && serviceId && StaffId) {
                          customToast.error("We appreciate your patience. Unfortunately, there are no available time slots for this week's service. Please consider choosing another week or alternative options. Thank you.");
                        }
                      }
                    } else if (BookNextAction.meta.requestStatus === "rejected") {
                      const status = BookNextAction.payload && BookNextAction.payload.status;
                      const errors = BookNextAction.payload && BookNextAction.payload.message && BookNextAction.payload.message.errors;
                      const response = BookNextAction.payload && BookNextAction.payload.message && BookNextAction.payload.message;
                      if (status === 422) {
                        const NotifyContent = () => {
                          return (
                            <>
                              <p className="mb-2 text-light text-justify">{response && response.message}</p>
                              <ul className="list-unstyled">
                                {errors &&
                                  Object.keys(errors).map((a, n) => {
                                    if (errors[a].length > 0) {
                                      return (
                                        <React.Fragment key={n}>
                                          {errors[a].map((as, ns) => {
                                            return (
                                              <li key={n + "-" + ns} className="text-light form-text text-start">
                                                {as}
                                              </li>
                                            );
                                          })}
                                        </React.Fragment>
                                      );
                                    }
                                    return null;
                                  })}
                              </ul>
                            </>
                          );
                        };
                        customToast.error(<NotifyContent />);
                        // dispatch(AppointmentAvailableTime(""));
                        // form.setFieldValue("warningMessage",response && response.message ? response.message : "No resource assigned to this service.");
                      } else if (status === 410) {
                        // dispatch(AppointmentAvailableTime(""));
                        customToast.error("Failed to update");
                      }
                    }

                  });
                } else if (action.meta.requestStatus === "rejected") {
                  const status = action.payload && action.payload.status;
                  const errors = action.payload && action.payload.message && action.payload.message.errors;
                  const response = action.payload && action.payload.message && action.payload.message;
                  if (status === 422) {
                    const NotifyContent = () => {
                      return (
                        <>
                          <p className="mb-2 text-light text-justify">{response && response.message}</p>
                          <ul className="list-unstyled">
                            {errors &&
                              Object.keys(errors).map((a, n) => {
                                if (errors[a].length > 0) {
                                  return (
                                    <React.Fragment key={n}>
                                      {errors[a].map((as, ns) => {
                                        return (
                                          <li key={n + "-" + ns} className="text-light form-text text-start">
                                            {as}
                                          </li>
                                        );
                                      })}
                                    </React.Fragment>
                                  );
                                }
                                return null;
                              })}
                          </ul>
                        </>
                      );
                    };
                    customToast.error(<NotifyContent />);
                    // dispatch(AppointmentAvailableTime(""));
                    // form.setFieldValue("warningMessage",response && response.message ? response.message : "No resource assigned to this service.");
                  } else if (status === 410) {
                    // dispatch(AppointmentAvailableTime(""));
                    customToast.error("Failed to update");
                  }
                }
              });
            }

            if (form.values.selectedOptionType === "resourceItem") {
              dispatch(staffOptions({
                option: { valueField: "users.id", labelField: "CONCAT(users.first_name,' ',users.last_name)" },
                service_id: option && option.value,
                pagetype: "Booking",
                availebleTimeObj: obj
              })).then(action => {
                if (action.meta.requestStatus === "rejected") {
                  customToast.error("Staff is not avaialble");
                }
              });
            }
            // GetAvailableTime(obj, dispatch);
            // dispatch(staffOptions({ option: { valueField: "users.id", labelField: "CONCAT(users.first_name,' ',users.last_name)" }, service_id: service.id, pagetype: "Booking", singlestaffaccess: singlestaffaccess, page_name: page_name }));
            //dispatch(AddOnServicesApi({ service_id: service.id }));
            dispatch(AddOnData(""));
            dispatch(AddAnotherData(""));
          }
        });
      } else {
        if (controlId === "appointmentautofillForm-service_id") {
          form.setFieldValue("duration", "");
          form.setFieldValue("cost", "");
          form.setFieldTouched("cost", false);
          form.setFieldValue("service_deposit", []);
          form.setFieldValue("totaldeposit", "");
          // form.setFieldValue("warningMessage","");
          dispatch(AppointmentResourceItemsClears());
        }
        dispatch(AddOnServiceButtonRender(false));
        // dispatch(staffOptions({ option: { valueField: "users.id", labelField: "CONCAT(users.first_name,' ',users.last_name)" }, pagetype: "Booking", singlestaffaccess: singlestaffaccess, page_name: page_name }));
        dispatch(AddOnData(""));
        dispatch(AddAnotherData(""));
      }
    }
    if (field.name === "staff_id" && controlId === "appointmentautofillForm-staff_id") {
      if (option && option.value) {
        dispatch(servicePriceApi({ staff_id: option && option.value, service_id: service_id && service_id })).then((action) => {
          if (action.meta.requestStatus === "fulfilled") {
            const serviceData = action.payload;
            const duration = serviceData.duration && serviceData.duration > 0 ? MinutesToHours(serviceData.duration) : "";
            const padding_time = serviceData.padding_time && serviceData.padding_time > 0 ? MinutesToHours(serviceData.padding_time) : "";
            const durationWithPadding = serviceData && serviceData.padding_time ? MinutesToHours(parseInt(serviceData.padding_time) + parseInt(serviceData.duration)) : (serviceData && serviceData.duration ? MinutesToHours(serviceData.duration) : "")

            form.setFieldValue("duration", duration);
            form.setFieldValue("padding_time", padding_time);
            form.setFieldValue("durationWithPadding", durationWithPadding);
            // const duration = service && service.duration ? MinutesToHours(service.duration) : "";
            if (controlId === "appointmentautofillForm-staff_id") {

              const obj = {
                appointment_id: "",
                busytime_id: "",
                dateof: form.values.dateof,
                start_datetime: form.values.start_datetime,
                duration: duration,
                padding_time: padding_time,
                durationWithPadding: durationWithPadding,
                listview: "Appointment",
                salon_id: form.values.salon_id,
                service_id: form.values.service_id,
                staff_id: option && option.value,
                resource_item_id: form.values.resource_item_id,
                uid: 1,
                formtype: "appointmentautofillForm",
                selectedOptionType: form.values.selectedOptionType,
                formName: "appointmentautofillForm/staff"
              }
              form.setFieldValue("available_time_obj", obj);
              dispatch(AppointmentResourceItemsApi({ option: { valueField: "id", labelField: "resource_item" }, service_id: obj.service_id, availebleTimeObj: obj })).then((action) => {
                if (action.meta.requestStatus === "fulfilled" && action.payload.length === 0) {
                  dispatch(BookNextAvailableResourceApi(obj)).then((BookNextAction) => {
                    const availableNextData = BookNextAction && BookNextAction.payload;

                    // console.log(JSON.stringify(availableNextData, null, 2));
                    if (availableNextData && (availableNextData.start_datetime && availableNextData.time.length > 0 && availableNextData.resource_item && availableNextData.resource_item.id)) {
                      const FormattedDateOf = availableNextData.dateof ? moment(availableNextData.dateof, "YYYY-MM-DD HH:mm:ss").format("dddd, D MMMM YYYY") : "";
                      const FormattedStartTime = availableNextData.dateof ? moment(availableNextData.dateof, "YYYY-MM-DD HH:mm:ss").format("HH:mm") : "";
                      const StartDateTime = availableNextData.start_datetime ? availableNextData.start_datetime : "";
                      const ResourceItemId = availableNextData.resource_item_id ? availableNextData.resource_item_id : "";
                      const SalonId = availableNextData.salon_id ? availableNextData.salon_id : "";
                      const serviceId = availableNextData.service_id ? availableNextData.service_id : "";
                      const StaffId = availableNextData.staff_id ? availableNextData.staff_id : "";
                      const ServiceDurationWithPadding = availableNextData.durationWithPadding ? availableNextData.durationWithPadding : "";
                      const ServicePadding = availableNextData.padding_time ? availableNextData.padding_time : "";
                      const ServiceDuration = availableNextData.duration ? availableNextData.duration : "";
                      const time = availableNextData.time ? availableNextData.time : "";
                      const newObj = {
                        appointment_id: "",
                        busytime_id: "",
                        dateof: FormattedDateOf,
                        start_datetime: StartDateTime,
                        duration: ServiceDuration,
                        padding_time: ServicePadding,
                        durationWithPadding: ServiceDurationWithPadding,
                        listview: "Appointment",
                        salon_id: SalonId,
                        service_id: serviceId,
                        staff_id: StaffId,
                        resource_item_id: ResourceItemId,
                        uid: 1,
                        formtype: "appointmentautofillForm"
                      }

                      form.setFieldValue("available_time_obj", newObj);
                      form.setFieldValue("resource_item_id", ResourceItemId);
                      form.setFieldValue("dateof", FormattedDateOf);
                      form.setFieldValue("start_datetime", StartDateTime ? StartDateTime : "");
                      form.setFieldValue("start_time", FormattedStartTime);
                      form.setFieldValue("display_text", availableNextData.display_text ? availableNextData.display_text : "");
                      dispatch(AppointmentAvailableTime({ time: time }));
                      // form.setFieldValue("BookNextModalOpen", true);
                      // form.setFieldValue("warningMessage","All resources for this service are being used at this time.");
                      // customToast.error("All resources for this service are being used at this time.");
                      dispatch(BookNextModalOpen(true));
                    } else {
                      const SalonId = availableNextData.salon_id ? availableNextData.salon_id : "";
                      const serviceId = availableNextData.service_id ? availableNextData.service_id : "";
                      const StaffId = availableNextData.staff_id ? availableNextData.staff_id : "";

                      if (SalonId && serviceId && StaffId) {
                        customToast.error("We appreciate your patience. Unfortunately, there are no available time slots for this week's service. Please consider choosing another week or alternative options. Thank you.");
                      }
                    }
                  });
                } else if (action.meta.requestStatus === "rejected") {
                  const status = action.payload && action.payload.status;
                  const errors = action.payload && action.payload.message && action.payload.message.errors;
                  const response = action.payload && action.payload.message && action.payload.message;
                  if (status === 422) {
                    const NotifyContent = () => {
                      return (
                        <>
                          <p className="mb-2 text-light text-justify">{response && response.message}</p>
                          <ul className="list-unstyled">
                            {errors &&
                              Object.keys(errors).map((a, n) => {
                                if (errors[a].length > 0) {
                                  return (
                                    <React.Fragment key={n}>
                                      {errors[a].map((as, ns) => {
                                        return (
                                          <li key={n + "-" + ns} className="text-light form-text text-start">
                                            {as}
                                          </li>
                                        );
                                      })}
                                    </React.Fragment>
                                  );
                                }
                                return null;
                              })}
                          </ul>
                        </>
                      );
                    };
                    customToast.error(<NotifyContent />);
                    // dispatch(AppointmentAvailableTime(""));
                    // form.setFieldValue("warningMessage",response && response.message ? response.message : "No resource assigned to this service.");
                  } else if (status === 410) {
                    // dispatch(AppointmentAvailableTime(""));
                    customToast.error("Failed to update");
                  }
                }
              });
              GetAvailableTime({ ...form.values, duration: durationWithPadding, staff_id: option && option.value, listview: "Appointment", formtype: "appointmentautofillForm", isDurationCheck: true }, dispatch);
            }
            // let service = action.payload;
            // let serviceprice = service && service.serviceprice && service.serviceprice[0] && service.serviceprice[0].price ? service.serviceprice[0].price : "";
            // form.setFieldValue("cost", serviceprice);
          }
        });
      } else {
        GetAvailableTime({
          ...form.values,
          staff_id: option && option.value,
          duration: "",
          padding_time: "",
          durationWithPadding: "",
          listview: "Appointment",
          isDurationCheck: true
        }, dispatch);
      }
    }
    if (field.name === "resource_item_id" && controlId === "appointmentautofillForm-resource_item_id") {
      if (option && option.value) {
        if (controlId === "appointmentautofillForm-resource_item_id") {
          GetAvailableTime({
            ...form.values,
            resource_item_id: option && option.value,
            listview: "Appointment",
            formtype: "appointmentautofillForm",
            isDurationCheck: true,
            formName: "appointmentautofillForm/resource_item"
          }, dispatch);
        }
      } else {
        GetAvailableTime({
          ...form.values,
          resource_item_id: option && option.value,
          // duration: "",
          // padding_time: "",
          // durationWithPadding: "",
          listview: "Appointment",
          isDurationCheck: true,
          formName: "appointmentautofillForm"
        }, dispatch);
      }
    }
    if (field.name === "service_id" && controlId === "appointmentForm-service_id") {
      if (option && option.value) {
        dispatch(AddOnServiceButtonRender(false));
        dispatch(servicePriceApi({ service_id: option && option.value })).then((action) => {
          const serviceData = action && action.payload;
          if (action.meta.requestStatus === "fulfilled") {
            const duration = serviceData.duration && serviceData.duration > 0 ? MinutesToHours(serviceData.duration) : "";
            const padding_time = serviceData.padding_time && serviceData.padding_time > 0 ? MinutesToHours(serviceData.padding_time) : "";
            const durationWithPadding = serviceData && serviceData.padding_time ? MinutesToHours(parseInt(serviceData.padding_time) + parseInt(serviceData.duration)) : (serviceData && serviceData.duration ? MinutesToHours(serviceData.duration) : "")
            if (controlId === "appointmentForm-service_id") {
              form.setFieldValue("duration", duration);
              form.setFieldValue("padding_time", padding_time);
              form.setFieldValue("durationWithPadding", durationWithPadding);
              form.setFieldValue("cost", "");
              form.setFieldTouched("cost", false);
              form.setFieldValue("staff_id", "");
              form.setFieldValue("resource_item_id", "");
              form.setFieldValue("start_time", "");

              if (form.values && form.values.form_types && form.values.form_types === "add_appointment") {
                form.setFieldValue("addonservices", []);
                form.setFieldValue("addanotherservices", []);
                dispatch(AddOnData(""));
                dispatch(AddAnotherData(""));
              }

              if (serviceData && serviceData.add_on_services_count && serviceData.add_on_services_count > 0) {
                dispatch(AddOnServiceButtonRender(true));
              } else if (serviceData && serviceData.add_on_services_count && serviceData.add_on_services_count <= 0) {
                dispatch(AddOnServiceButtonRender(false));
              }
              if (controlId === "appointmentForm-service_id" && form.values && form.values.form_types === "edit_appointment") {
                if (parseFloat(serviceData.deposit_booked_price) > parseFloat(form.values.previous_deposit_paid)) {
                  const updatedServiceDeposit = [
                    { service_id: option && option.value, deposit_price: serviceData.deposit_booked_price, service_type: "primary", total_cost: serviceData && serviceData.serviceprice && serviceData.serviceprice.length > 0 ? serviceData.serviceprice[0].price : "0" },
                  ];
                  form.setFieldValue("service_deposit", updatedServiceDeposit);
                  const remainingDeposit = (parseFloat(serviceData.deposit_booked_price) - parseFloat(form.values.previous_deposit_paid)).toFixed(2);
                  const remainingDepositFormatted = remainingDeposit > 0 ? remainingDeposit : '0.00';
                  form.setFieldValue("totaldeposit", remainingDepositFormatted);
                } else {
                  form.setFieldValue("totaldeposit", "0.00")
                }
                if (serviceData && serviceData.serviceprice && serviceData.serviceprice.length > 0) {
                  let ServicePriceCost = serviceData.serviceprice.find((allservices) => allservices && allservices.pricetier && allservices.pricetier.name == "General")
                  if (ServicePriceCost && ServicePriceCost.price) {
                    form.setFieldValue("cost", ServicePriceCost.price);
                  }
                }
              } else {
                const updatedServiceDeposit = [
                  { service_id: option && option.value, deposit_price: serviceData.deposit_booked_price, service_type: "primary", total_cost: serviceData && serviceData.serviceprice && serviceData.serviceprice.length > 0 ? serviceData.serviceprice[0].price : "0" },
                ];
                form.setFieldValue("service_deposit", updatedServiceDeposit);
              }
              if (serviceData && serviceData.deposit_booked_price && parseFloat(serviceData.deposit_booked_price) > 0 && serviceData && serviceData.deposit_booked_online == 1) {
                form.setFieldValue("totaldeposit", serviceData.deposit_booked_price)
                dispatch(OpenDepositPayTab(true));
              } else {
                form.setFieldValue("totaldeposit", 0)
                dispatch(OpenDepositPayTab(false));
              }
            }
            let formName = "";
            if (form.values && form.values.form_types) {
              formName = form.values.form_types === "add_appointment" ? "appointmentForm/add/service_id" : (form.values.form_types === "edit_appointment" ? "appointmentForm/edit/service_id" : "")
            }
            // dispatch(AppointmentResourceItemsApi({ option: { valueField: "id", labelField: "resource_item" }, service_id: option && option.value }));
            dispatch(staffOptions({
              option: { valueField: "users.id", labelField: "CONCAT(users.first_name,' ',users.last_name)" },
              service_id: serviceData.id,
              pagetype: "Booking",
              singlestaffaccess: singlestaffaccess,
              page_name: page_name,
              formName
            }));

            //dispatch(AddOnServicesApi({ service_id: service.id }));
            // GetAvailableTime({
            //   ...form.values,
            //   service_id: option && option.value,
            //   duration: duration,
            //   padding_time: padding_time,
            //   durationWithPadding: durationWithPadding,
            //   isDurationCheck: true,
            //   listview: "Appointment",
            //   prev_start_datetime: form.values?.prev_start_datetime
            // }, dispatch);
            // dispatch(AddOnData(""));
            // dispatch(AddAnotherData(""));
            dispatch(AppointmentAutoFillFinalData(""));
          }
        });
      } else {
        if (controlId === "appointmentForm-service_id") {
          form.setFieldValue("duration", "");
          form.setFieldValue("padding_time", "");
          form.setFieldValue("durationWithPadding", "");
          form.setFieldValue("staff_id", "");
          form.setFieldValue("resource_item_id", "", false);
          form.setFieldValue("start_time", "");
          form.setFieldValue("cost", "");
          form.setFieldTouched("cost", false);
          if (form.values && form.values.form_types === "add_appointment") {
            form.setFieldValue("addonservices", []);
            form.setFieldValue("addanotherservices", []);
            dispatch(AddOnData(""));
            dispatch(AddAnotherData(""));
          }
          form.setFieldValue("service_deposit", "");
          form.setFieldValue("totaldeposit", "0.00");

        }
        dispatch(OpenDepositPayTab(false));
        dispatch(AppointmentResourceItemsClears());
        dispatch(AddOnServiceButtonRender(false));

        let formName = "";
        if (form.values && form.values.form_types) {
          formName = form.values.form_types === "add_appointment" ? "appointmentForm/add/service_id" : (form.values.form_types === "edit_appointment" ? "appointmentForm/edit/service_id" : "")
        }
        dispatch(staffOptions({
          option: { valueField: "users.id", labelField: "CONCAT(users.first_name,' ',users.last_name)" },
          pagetype: "Booking",
          singlestaffaccess: singlestaffaccess,
          page_name: page_name,
          formName
        }));
        // GetAvailableTime({
        //   ...form.values,
        //   service_id: option && option.value,
        //   duration: "",
        //   padding_time: "",
        //   durationWithPadding: "",
        //   isDurationCheck: true,
        //   listview: "Appointment",
        //   prev_start_datetime: form.values?.prev_start_datetime
        // }, dispatch);
        // dispatch(AddOnData(""));
        // dispatch(AddAnotherData(""));
        dispatch(AppointmentAutoFillFinalData(""));
      }
    }
    if ((field.name === "staff_id" && controlId === "appointmentForm-staff_id") || page === "newsale") {
      if (option && option.value) {
        dispatch(servicePriceApi({ staff_id: option && option.value, service_id: service_id && service_id })).then((action) => {
          if (action.meta.requestStatus === "fulfilled") {
            const serviceData = action.payload;
            // const duration = serviceData.duration && serviceData.duration > 0 ? MinutesToHours(serviceData.duration) : "";
            // const padding_time = serviceData.padding_time && serviceData.padding_time > 0 ? MinutesToHours(serviceData.padding_time) : "00:00";
            // const durationWithPadding = serviceData && serviceData.padding_time ? MinutesToHours(parseInt(serviceData.padding_time) + parseInt(serviceData.duration)) : (serviceData && serviceData.duration ? MinutesToHours(serviceData.duration) : "")
            const duration = form.values.duration && /^\d{1,2}:\d{2}$/.test(form.values.duration) ? form.values.duration : "";
            const padding_time = form.values.padding_time && /^\d{1,2}:\d{2}$/.test(form.values.padding_time) > 0 ? form.values.padding_time : "";
            let durationWithPadding = duration;
            if (padding_time) {
              const duration1 = moment.duration(duration);
              const duration2 = moment.duration(padding_time);

              const totalMinutes = duration1.asMinutes() + duration2.asMinutes();
              const hours = Math.floor(totalMinutes / 60);
              const minutes = totalMinutes % 60;

              const sumDuration = moment({ hours, minutes });
              const SumOfDuration = sumDuration ? sumDuration.format('HH:mm') : "";

              // const durationMoment = moment.duration(SumOfDuration);
              durationWithPadding = SumOfDuration
            }
            let StaffPrice = serviceData && serviceData.serviceprice;
            let ServiceCost = StaffPrice && StaffPrice.length === 1 ? StaffPrice[0].price : "0.00";
            // const duration = service && service.duration ? MinutesToHours(service.duration) : "";
            let formName = "";
            if (form.values && form.values.form_types) {
              formName = form.values.form_types === "add_appointment" ? "appointmentForm/add/staff_id" : (form.values.form_types === "edit_appointment" ? "appointmentForm/edit/staff_id" : "")
            }
            if (controlId === "appointmentForm-staff_id") {
              GetAvailableTime({
                ...form.values,
                duration: duration,
                padding_time: padding_time,
                durationWithPadding: durationWithPadding,
                staff_id: option && option.value,
                listview: "Appointment",
                isDurationCheck: true,
                prev_start_datetime: form.values?.prev_start_datetime,
                formName
              }, dispatch);
              form.setFieldValue("duration", duration);
              form.setFieldValue("padding_time", padding_time);
              form.setFieldValue("durationWithPadding", durationWithPadding);
              form.setFieldValue("resource_item_id", "");
              form.setFieldValue("start_time", "");
              form.setFieldValue("cost", parseFloat(ServiceCost));
              if (form.values && form.values.form_types === "add_appointment") {
                form.setFieldValue("addonservices", []);
                form.setFieldValue("addanotherservices", []);
                dispatch(AddOnData(""));
                dispatch(AddAnotherData(""));
              }
              if (controlId === "appointmentForm-staff_id" && form.values && form.values.form_types === "add_appointment") {
                let totalDeposits = form.values.totaldeposit;
                const updatedServiceDeposit = form.values.service_deposit && form.values.service_deposit.length > 0 && form.values.service_deposit.filter((item) => item.service_type != "add_another");
                updatedServiceDeposit && updatedServiceDeposit.length > 0 && updatedServiceDeposit.forEach((item) => {
                  totalDeposits = parseFloat(item.deposit_price);
                });
                if (serviceData && serviceData.deposit_booked_price && parseFloat(serviceData.deposit_booked_price) > 0 && serviceData && serviceData.deposit_booked_online == 1) {
                  form.setFieldValue("totaldeposit", parseFloat(totalDeposits).toFixed(2))
                } else {
                  form.setFieldValue("totaldeposit", 0)
                }
              }
              if (serviceData && serviceData.serviceprice && serviceData.serviceprice.length > 0) {
                let ServicePriceCost = serviceData.serviceprice.find((allservices) => allservices && allservices.pricetier && allservices.pricetier.name == "General")
                if (ServicePriceCost && ServicePriceCost.price) {
                  form.setFieldValue("cost", ServicePriceCost.price);
                }
              }

              dispatch(AppointmentAutoFillFinalData(""));
            }
            if (serviceData && serviceData.serviceprice && page === "newsale" && option) {
              let serviceprice = serviceData.serviceprice;
              let generalPrice = serviceprice;
              let gprice = generalPrice && generalPrice.length === 1 ? generalPrice[0].price : "0.00";
              // let add_on_price = generalPrice && generalPrice.length === 1 ? generalPrice[0].add_on_price : "0.00";
              // let totalprice = parseFloat(gprice) + parseFloat(add_on_price); //client need only gprice 16-12-2022
              let totalprice = parseFloat(gprice);
              let name = field.name;
              let field_gprice = name.replace("staff_id", "gprice");
              let field_staff_name = name.replace("staff_id", "staff_name");
              form.setFieldValue(String(field_gprice), String(totalprice));
              form.setFieldValue(String(field_staff_name), String(option.label));
              dispatch(SaleServiceToCartApi({ service_id: service_id, gprice: String(totalprice), staff: { id: option.value, name: option.label, serviceprice: serviceprice && serviceprice.length === 1 && serviceprice[0], totalprice: String(totalprice) } })).then((action1) => {
                if (action1.meta.requestStatus === "fulfilled") {
                }
              });
            }
            // let service = action.payload;
            // let serviceprice = service && service.serviceprice && service.serviceprice[0] && service.serviceprice[0].price ? service.serviceprice[0].price : "";
            // form.setFieldValue("cost", serviceprice);
          }
        });
      } else {
        let formName = "";
        if (form.values && form.values.form_types) {
          formName = form.values.form_types === "add_appointment" ? "appointmentForm/add/staff_id" : (form.values.form_types === "edit_appointment" ? "appointmentForm/edit/staff_id" : "")
        }
        GetAvailableTime({
          ...form.values,
          staff_id: option && option.value,
          duration: "",
          padding_time: "",
          durationWithPadding: "",
          listview: "Appointment",
          isDurationCheck: true,
          prev_start_datetime: form.values?.prev_start_datetime,
          formName
        }, dispatch);
        dispatch(AppointmentResourceItemsClears());
        // form.setFieldValue("duration", "");
        // form.setFieldValue("padding_time", "");
        // form.setFieldValue("durationWithPadding", "");
        form.setFieldValue("resource_item_id", "");
        form.setFieldValue("cost", "");
        form.setFieldTouched("cost", false);
        if (form.values && form.values.form_types === "add_appointment") {
          form.setFieldValue("addonservices", []);
          form.setFieldValue("addanotherservices", []);
          dispatch(AddOnData(""));
          dispatch(AddAnotherData(""));
        }
        if (form.values.form_types === "edit_appointment") {
          const updatedServiceDeposit = form.values.service_deposit && form.values.service_deposit.length > 0 && form.values.service_deposit.filter((item) => item.service_type != "add_another");
          form.setFieldValue("service_deposit", updatedServiceDeposit);
          let totalDeposit = form.values.totaldeposit;
          updatedServiceDeposit && updatedServiceDeposit.length > 0 && updatedServiceDeposit.forEach((item) => {
            totalDeposit = parseFloat(item.deposit_price) - parseFloat(form.values.previous_deposit_paid) > 0 ? parseFloat(item.deposit_price) - parseFloat(form.values.previous_deposit_paid) : 0;
          });
          const formattedTotalDeposit = totalDeposit ? typeof totalDeposit === 'number' ? parseFloat(totalDeposit).toFixed(2) : parseFloat(totalDeposit) : 0;
          form.setFieldValue("totaldeposit", formattedTotalDeposit ? formattedTotalDeposit : 0);
        } else if (form.values.form_types === "add_appointment") {
          const updatedServiceDeposit = form.values.service_deposit && form.values.service_deposit.length > 0 && form.values.service_deposit.filter((item) => item.service_type != "add_another");
          form.setFieldValue("service_deposit", updatedServiceDeposit);
          let totalDeposit = form.values.totaldeposit;
          updatedServiceDeposit && updatedServiceDeposit.length > 0 && updatedServiceDeposit.forEach((item) => {
            totalDeposit = parseFloat(item.deposit_price);
          });
          const formattedTotalDeposit = totalDeposit ? typeof totalDeposit === 'number' ? parseFloat(totalDeposit).toFixed(2) : parseFloat(totalDeposit) : 0;
          form.setFieldValue("totaldeposit", formattedTotalDeposit ? formattedTotalDeposit : 0);
        }

        dispatch(AppointmentAutoFillFinalData(""));
      }
    }
    if (field.name === "resource_item_id" && controlId === "appointmentForm-  ") {
      if (option && option.value) {
        dispatch(servicePriceApi({ staff_id: staff_id, service_id: service_id && service_id })).then((action) => {
          if (action.meta.requestStatus === "fulfilled") {
            const serviceData = action.payload;
            // const duration = serviceData.duration && serviceData.duration > 0 ? MinutesToHours(serviceData.duration) : "";
            // const padding_time = serviceData.padding_time && serviceData.padding_time > 0 ? MinutesToHours(serviceData.padding_time) : "";
            // const durationWithPadding = serviceData && serviceData.padding_time ? MinutesToHours(parseInt(serviceData.padding_time) + parseInt(serviceData.duration)) : (serviceData && serviceData.duration ? MinutesToHours(serviceData.duration) : "")
            const duration = form.values.duration && /^\d{1,2}:\d{2}$/.test(form.values.duration) ? form.values.duration : "";
            const padding_time = form.values.padding_time && /^\d{1,2}:\d{2}$/.test(form.values.padding_time) > 0 ? form.values.padding_time : "";
            let durationWithPadding = duration;
            if (padding_time) {
              const duration1 = moment.duration(duration);
              const duration2 = moment.duration(padding_time);

              const totalMinutes = duration1.asMinutes() + duration2.asMinutes();
              const hours = Math.floor(totalMinutes / 60);
              const minutes = totalMinutes % 60;

              const sumDuration = moment({ hours, minutes });
              const SumOfDuration = sumDuration ? sumDuration.format('HH:mm') : "";

              // const durationMoment = moment.duration(SumOfDuration);
              durationWithPadding = SumOfDuration
            }
            // const duration = service && service.duration ? MinutesToHours(service.duration) : "";
            if (controlId === "appointmentForm-resource_item_id") {
              let formName = "";
              if (form.values && form.values.form_types) {
                formName = form.values.form_types === "add_appointment" ? "appointmentForm/add/resource_item_id" : (form.values.form_types === "edit_appointment" ? "appointmentForm/edit/resource_item_id" : "")
              }
              GetAvailableTime({
                ...form.values,
                duration: duration,
                padding_time: padding_time,
                durationWithPadding: durationWithPadding,
                resource_item_id: option && option.value,
                listview: "Appointment",
                isDurationCheck: true,
                selectedOptionType: "resource_item_id",
                prev_start_datetime: form.values?.prev_start_datetime,
                formName
              }, dispatch);
              form.setFieldValue("duration", duration);
              if (!form.values.padding_time || !(/^\d{1,2}:\d{2}$/.test(form.values.padding_time))) {
                // If padding_time is null, undefined, empty string, or not in the correct format
                // Reset it to some default value. Assuming `padding_time` is the variable holding the default
                form.setFieldValue("padding_time", padding_time); // reset to default
              }
              form.setFieldValue("durationWithPadding", durationWithPadding);
              // form.setFieldValue("start_time", "");
              if (form.values && form.values.form_types === "add_appointment") {
                form.setFieldValue("addonservices", []);
                form.setFieldValue("addanotherservices", []);
                dispatch(AddOnData(""));
                dispatch(AddAnotherData(""));
              }

              if (controlId === "appointmentForm-staff_id" && form.values && form.values.form_types === "add_appointment") {
                let totalDeposits = form.values.totaldeposit;
                const updatedServiceDeposit = form.values.service_deposit && form.values.service_deposit.length > 0 && form.values.service_deposit.filter((item) => item.service_type != "add_another");
                updatedServiceDeposit && updatedServiceDeposit.length > 0 && updatedServiceDeposit.forEach((item) => {
                  totalDeposits = parseFloat(item.deposit_price);
                });
                form.setFieldValue("totaldeposit", parseFloat(totalDeposits).toFixed(2));
              }
              dispatch(AppointmentAutoFillFinalData(""));
            }

          }
        });
      } else {
        let formName = "";
        if (form.values && form.values.form_types) {
          formName = form.values.form_types === "add_appointment" ? "appointmentForm/add/resource_item_id" : (form.values.form_types === "edit_appointment" ? "appointmentForm/edit/resource_item_id" : "")
        }
        GetAvailableTime({
          ...form.values,
          resource_item_id: option && option.value,
          // duration: "",
          // padding_time: "",
          // durationWithPadding: "",
          isDurationCheck: true,
          listview: "Appointment",
          prev_start_datetime: form.values?.prev_start_datetime,
          formName
        }, dispatch);
        // form.setFieldValue("resource_item_id", "");
        // form.setFieldValue("duration", "");
        // form.setFieldValue("padding_time", "");
        // form.setFieldValue("durationWithPadding", "");
        // form.setFieldValue("cost", "");
        // form.setFieldTouched("cost", false);
        form.setFieldValue("addonservices", []);

        if (form.values.form_types === "edit_appointment") {
          const updatedServiceDeposit = form.values.service_deposit && form.values.service_deposit.length > 0 && form.values.service_deposit.filter((item) => item.service_type != "add_another");
          form.setFieldValue("service_deposit", updatedServiceDeposit);
          let totalDeposit = form.values.totaldeposit;
          updatedServiceDeposit && updatedServiceDeposit.length > 0 && updatedServiceDeposit.forEach((item) => {
            totalDeposit = parseFloat(item.deposit_price) - parseFloat(form.values.previous_deposit_paid) > 0 ? parseFloat(item.deposit_price) - parseFloat(form.values.previous_deposit_paid) : "0.00";
          });
          const formattedTotalDeposit = totalDeposit ? typeof totalDeposit === 'number' ? parseFloat(totalDeposit).toFixed(2) : parseFloat(totalDeposit) : 0;
          form.setFieldValue("totaldeposit", formattedTotalDeposit ? formattedTotalDeposit : 0);
        } else if (form.values.form_types === "add_appointment") {
          const updatedServiceDeposit = form.values.service_deposit && form.values.service_deposit.length > 0 && form.values.service_deposit.filter((item) => item.service_type != "add_another");
          form.setFieldValue("service_deposit", updatedServiceDeposit);
          let totalDeposit = form.values.totaldeposit;
          updatedServiceDeposit && updatedServiceDeposit.length > 0 && updatedServiceDeposit.forEach((item) => {
            totalDeposit = parseFloat(item.deposit_price);
          });
          const formattedTotalDeposit = totalDeposit ? typeof totalDeposit === 'number' ? parseFloat(totalDeposit).toFixed(2) : parseFloat(totalDeposit) : 0;
          form.setFieldValue("totaldeposit", formattedTotalDeposit ? formattedTotalDeposit : 0);
        }
        dispatch(AddOnData(""));
        dispatch(AppointmentAutoFillFinalData(""));
      }
    }
    if (field.name === "staff_id" && controlId === "busytimeForm-staff_id") {
      let formName = "";
      if (form.values && form.values.form_types) {
        formName = form.values.form_types === "add_busytime" ? "busytimeForm/add/staff_id" : (form.values.form_types === "edit_busytime" ? "busytimeForm/edit/staff_id" : "")
      }
      GetAvailableTime({
        ...form.values,
        staff_id: option && option.value,
        listview: "BusyTime",
        isDurationCheck: true,
        formtype: form.values?.formtype ? form.values?.formtype : "",
        formName
      }, dispatch);
    }
    if (field.name === "voucher_id" && controlId === "BuyVoucherForm-voucher_id") {
      if (option) {
        const vlabel = option.label ? option.label.split("-") : [];
        const vlabelAmount = vlabel.length == 2 ? vlabel[1].trim().replace("$", "") : 0;
        form.setFieldValue("amount", String(vlabelAmount), false);
      } else {
        form.setFieldValue("amount", "", false);
      }

      // form.setFieldValue("amount", "");
    }

    let getFieldName = field.name && field.name.split(".");
    let getFieldNameIndex = getFieldName && getFieldName.length == 3 ? getFieldName[1] : "";
    let getControlId = controlId && controlId.split("-");
    if (getFieldName.length == 3 && getFieldName.includes("addonservices") && getFieldName.includes("service_id") && getControlId.includes("appointmentForm") && getControlId.includes("addonservices") && getControlId.includes("service_id")) {
      if (option && option.value) {
        dispatch(AddOnServicePriceApi({ parent_service_id: form.values.service_id, parent_staff_id: form.values.staff_id, service_id: option && option.value, index_id: getFieldNameIndex })).then((action) => {
          if (action.meta.requestStatus === "fulfilled") {
            const serviceData = action.payload;
            const duration = serviceData.duration && serviceData.duration > 0 ? MinutesToHours(serviceData.duration) : "";
            const padding_time = serviceData.padding_time && serviceData.padding_time > 0 ? MinutesToHours(serviceData.padding_time) : "";
            const durationWithPadding = serviceData && serviceData.padding_time ? MinutesToHours(parseInt(serviceData.padding_time) + parseInt(serviceData.duration)) : (serviceData && serviceData.duration ? MinutesToHours(serviceData.duration) : "")

            if (controlId === `appointmentForm-addonservices-${getFieldNameIndex}-service_id`) {
              form.setFieldValue(`${getFieldName[0]}.${getFieldName[1]}.service_name`, serviceData.name);
              form.setFieldValue(`${getFieldName[0]}.${getFieldName[1]}.staff_id`, "");
              form.setFieldValue(`${getFieldName[0]}.${getFieldName[1]}.resource_item_id`, "");
              form.setFieldValue(`${getFieldName[0]}.${getFieldName[1]}.parent_id`, form?.values?.id ? form?.values?.id : null);
              form.setFieldValue(`${getFieldName[0]}.${getFieldName[1]}.duration`, duration);
              form.setFieldValue(`${getFieldName[0]}.${getFieldName[1]}.padding_time`, padding_time);
              form.setFieldValue(`${getFieldName[0]}.${getFieldName[1]}.durationWithPadding`, durationWithPadding);
              // AddonGetAvailableTime({
              //   ...form.values.addonservices[getFieldNameIndex],
              //   parent_id: form.values && form.values.id ? form.values.id : "",
              //   service_id: option && option.value,
              //   dateof: form.values.dateof,
              //   duration: duration,
              //   padding_time: padding_time,
              //   durationWithPadding: durationWithPadding,
              //   listview: "Appointment"
              // }, dispatch);

              const addonservices = form.values.addonservices && form.values.addonservices.length > 0 ? form.values.addonservices.slice(0, getFieldNameIndex) : "";

              let parentAppointmentEndTime = "";
              // let isNotInResourceIds = [];
              // if (form.values.resource_item_id) {
              //   isNotInResourceIds.push(form.values.resource_item_id);
              // }

              if (addonservices && addonservices.length > 0) {
                const totalAddOnDurationWithPadding = addonservices && addonservices.length > 0 && addonservices.reduce((sum, sduration) => {
                  // Check if sduration.durationWithPadding is a valid duration
                  const duration = moment.duration(sduration.durationWithPadding);
                  if (duration.isValid()) {
                    const sumDurationWithPadding = moment.duration(sduration.durationWithPadding);
                    // if (sduration.resource_item_id) {
                    //   isNotInResourceIds.push(sduration.resource_item_id);
                    // }
                    return sum.add(sumDurationWithPadding);
                  }
                  return sum;
                }, moment.duration(0));
                // console.log('isNotInResourceIds',isNotInResourceIds);

                const parentAppointmentDuration = form.values.durationWithPadding;
                const duration1 = moment.duration(parentAppointmentDuration);
                const duration2 = totalAddOnDurationWithPadding;

                // Check if each duration is valid before using it
                const minutes1 = duration1 && duration1.isValid() ? duration1.asMinutes() : 0;
                const minutes2 = duration2 && duration2.isValid() ? duration2.asMinutes() : 0;

                const totalMinutes = minutes1 + minutes2;
                const hours = Math.floor(totalMinutes / 60);
                const minutes = totalMinutes % 60;

                const sumDuration = moment({ hours, minutes });
                const SumOfDuration = sumDuration ? sumDuration.format('HH:mm') : "";

                const durationMoment = moment.duration(SumOfDuration);
                const appointmentStartDateTime = moment(`${form.values.dateof} ${form.values.start_time}`, "dddd, DD MMMM YYYY HH:mm");
                parentAppointmentEndTime = appointmentStartDateTime.clone().add(durationMoment).format('YYYY-MM-DD HH:mm:00');
                // isNotInResourceIds.push(form.values.resource_item_id);
              } else {
                const appointmentStartDateTime = moment(`${form.values.dateof} ${form.values.start_time}`, "dddd, DD MMMM YYYY HH:mm");
                const durationMoment = moment.duration(form.values.durationWithPadding);

                parentAppointmentEndTime = appointmentStartDateTime.clone().add(durationMoment).format('YYYY-MM-DD HH:mm:00');
              }
              form.setFieldValue(`${getFieldName[0]}.${getFieldName[1]}.parentAppointmentEndTime`, parentAppointmentEndTime);

              let formName = "";
              if (form.values && form.values.form_types) {
                formName = form.values.form_types === "add_appointment" ? "appointmentForm/add/addonservices/service_id" : (form.values.form_types === "edit_appointment" ? "appointmentForm/edit/addonservices/service_id" : "")
              }
              const obj = {
                ...form.values.addonservices[getFieldNameIndex],
                remove_addon: form?.values?.remove_addon || [],
                remove_addanother: form?.values?.remove_addanother || [],
                service_id: option && option.value,
                parent_id: form.values && form.values.id ? form.values.id : "",
                staff_id: "",
                dateof: form.values.dateof,
                duration: duration,
                padding_time: padding_time,
                durationWithPadding: durationWithPadding,
                parentAppointmentEndTime: parentAppointmentEndTime,
                listview: "Appointment",
                formtype: "appointmentform",
                formName
              }
              const dateof = form.values.dateof && form.values.dateof ? moment(form.values.dateof, "dddd, DD MMMM YYYY").format("YYYY-MM-DD") + " " + moment().format("HH:mm:ss") : "";
              const addOnData = isAddOn.find((event) => event.id === indexID);
              let addonobj = { ...addOnData, serviceprice: action.payload, duration: serviceData.duration };
              dispatch(AddonStaffOptionsApi({ option: { valueField: "users.id", labelField: "CONCAT(users.first_name,' ',users.last_name)" }, service_id: serviceData.id, pagetype: "Booking", availebleTimeObj: { ...obj, dateof: dateof } })).then((action1) => {
                if (action1.meta.requestStatus === "fulfilled") {
                  if (addonobj) {
                    dispatch(AddOnData({ ...addonobj, staff: action1.payload }));
                  }
                } else {
                  dispatch(AddOnData(addonobj));
                }
              });
            }
          }
        });
      } else {
        if (controlId) {
          form.setFieldValue(`${getFieldName[0]}.${getFieldName[1]}.staff_id`, "");
          form.setFieldValue(`${getFieldName[0]}.${getFieldName[1]}.resource_item_id`, "");
          form.setFieldValue(`${getFieldName[0]}.${getFieldName[1]}.duration`, "");
          form.setFieldValue(`${getFieldName[0]}.${getFieldName[1]}.padding_time`, "");
          form.setFieldValue(`${getFieldName[0]}.${getFieldName[1]}.durationWithPadding`, "");
          form.setFieldValue(`${getFieldName[0]}.${getFieldName[1]}.cost`, "");
          form.setFieldTouched(`${getFieldName[0]}.${getFieldName[1]}.cost`, false);
        }
        dispatch(AddOnResourceOptionsApi({ option: { valueField: "id", labelField: "resource_item" }, pagetype: "Booking" }))
        dispatch(AddonStaffOptionsApi({ option: { valueField: "users.id", labelField: "CONCAT(users.first_name,' ',users.last_name)" }, pagetype: "Booking" }));
        // AddonGetAvailableTime({
        //   ...form.values.addonservices[getFieldNameIndex],
        //   parent_id: form.values && form.values.id ? form.values.id : "",
        //   service_id: option && option.value,
        //   dateof: form.values.dateof,
        //   duration: "",
        //   padding_time: "",
        //   durationWithPadding: "",
        // }, dispatch);
      }
    }
    if (getFieldName.length == 3 && getFieldName.includes("addonservices") && getFieldName.includes("staff_id") && getControlId.includes("appointmentForm") && getControlId.includes("addonservices") && getControlId.includes("staff_id")) {
      if (option && option.value) {
        dispatch(
          AddOnServicePriceApi({
            parent_service_id: form.values.service_id,
            parent_staff_id: form.values.staff_id,
            service_id: form.values.addonservices[getFieldNameIndex].service_id,
            staff_id: option && option.value,
            index_id: getFieldNameIndex,
          }),
        ).then((action) => {
          if (action.meta.requestStatus === "fulfilled") {
            const serviceData = action.payload;
            const prevObj = form.values.addanotherservices[getFieldNameIndex]?.prevObj;
            // const duration = serviceData.duration && serviceData.duration > 0 ? MinutesToHours(serviceData.duration) : "";
            // const padding_time = serviceData.padding_time && serviceData.padding_time > 0 ? MinutesToHours(serviceData.padding_time) : "";
            // const durationWithPadding = serviceData && serviceData.padding_time ? MinutesToHours(parseInt(serviceData.padding_time) + parseInt(serviceData.duration)) : (serviceData && serviceData.duration ? MinutesToHours(serviceData.duration) : "")
            // console.log('form.values',form.values);
            const duration = form.values.addonservices[getFieldNameIndex].duration && /^\d{1,2}:\d{2}$/.test(form.values.addonservices[getFieldNameIndex].duration) ? form.values.addonservices[getFieldNameIndex].duration : "";
            const padding_time = form.values.addonservices[getFieldNameIndex].padding_time && /^\d{1,2}:\d{2}$/.test(form.values.addonservices[getFieldNameIndex].padding_time) > 0 ? form.values.addonservices[getFieldNameIndex].padding_time : "";

            let durationWithPadding = duration;
            if (padding_time) {
              const duration1 = moment.duration(duration);
              const duration2 = moment.duration(padding_time);

              const totalMinutes = duration1.asMinutes() + duration2.asMinutes();
              const hours = Math.floor(totalMinutes / 60);
              const minutes = totalMinutes % 60;

              const sumDuration = moment({ hours, minutes });
              const SumOfDuration = sumDuration ? sumDuration.format('HH:mm') : "";

              // const durationMoment = moment.duration(SumOfDuration);
              durationWithPadding = SumOfDuration
            }
            if (controlId === `appointmentForm-addonservices-${getFieldNameIndex}-staff_id`) {
              if (prevObj && prevObj.staff_id == option.value) {
                form.setFieldValue(`${getFieldName[0]}.${getFieldName[1]}.resource_item_id`, prevObj.resource_item_id);
                form.values.addonservices[getFieldNameIndex].resource_item_id = prevObj.resource_item_id;
              } else {
                form.setFieldValue(`${getFieldName[0]}.${getFieldName[1]}.resource_item_id`, "");
                form.values.addonservices[getFieldNameIndex].resource_item_id = "";
              }
              form.setFieldValue(`${getFieldName[0]}.${getFieldName[1]}.duration`, duration);
              form.setFieldValue(`${getFieldName[0]}.${getFieldName[1]}.padding_time`, padding_time);
              form.setFieldValue(`${getFieldName[0]}.${getFieldName[1]}.durationWithPadding`, durationWithPadding);
              form.setFieldValue(`${getFieldName[0]}.${getFieldName[1]}.parent_id`, form.values?.id);
              // const addonservices = [...form.values.addonservices]; // Create a copy of the array
              // Create a new array based on the index key
              const addonservices = form.values.addonservices && form.values.addonservices.length > 0 ? form.values.addonservices.slice(0, getFieldNameIndex) : "";

              let parentAppointmentEndTime = "";
              let isNotInResourceIds = [];
              if (form.values.resource_item_id) {
                isNotInResourceIds.push(form.values.resource_item_id);
              }

              if (addonservices && addonservices.length > 0) {
                const totalAddOnDurationWithPadding = addonservices && addonservices.length > 0 && addonservices.reduce((sum, sduration) => {
                  // Check if sduration.durationWithPadding is a valid duration
                  const duration = moment.duration(sduration.durationWithPadding);
                  if (duration.isValid()) {
                    const sumDurationWithPadding = moment.duration(sduration.durationWithPadding);
                    if (sduration.resource_item_id) {
                      isNotInResourceIds.push(sduration.resource_item_id);
                    }
                    return sum.add(sumDurationWithPadding);
                  }
                  return sum;
                }, moment.duration(0));
                // console.log('isNotInResourceIds',isNotInResourceIds);

                const parentAppointmentDuration = form.values.durationWithPadding;
                const duration1 = moment.duration(parentAppointmentDuration);
                const duration2 = totalAddOnDurationWithPadding;

                // Check if each duration is valid before using it
                const minutes1 = duration1 && duration1.isValid() ? duration1.asMinutes() : 0;
                const minutes2 = duration2 && duration2.isValid() ? duration2.asMinutes() : 0;

                const totalMinutes = minutes1 + minutes2;
                const hours = Math.floor(totalMinutes / 60);
                const minutes = totalMinutes % 60;

                const sumDuration = moment({ hours, minutes });
                const SumOfDuration = sumDuration ? sumDuration.format('HH:mm') : "";

                const durationMoment = moment.duration(SumOfDuration);
                const appointmentStartDateTime = moment(`${form.values.dateof} ${form.values.start_time}`, "dddd, DD MMMM YYYY HH:mm");
                parentAppointmentEndTime = appointmentStartDateTime.clone().add(durationMoment).format('YYYY-MM-DD HH:mm:00');
                // isNotInResourceIds.push(form.values.resource_item_id);
              } else {
                const appointmentStartDateTime = moment(`${form.values.dateof} ${form.values.start_time}`, "dddd, DD MMMM YYYY HH:mm");
                const durationMoment = moment.duration(form.values.durationWithPadding);

                parentAppointmentEndTime = appointmentStartDateTime.clone().add(durationMoment).format('YYYY-MM-DD HH:mm:00');
                // console.log('durationMoment',form.values.durationWithPadding, durationMoment.asMinutes(),parentAppointmentEndTime);
              }
              form.setFieldValue(`${getFieldName[0]}.${getFieldName[1]}.parentAppointmentEndTime`, parentAppointmentEndTime);

              let formName = "";
              if (form.values && form.values.form_types) {
                formName = form.values.form_types === "add_appointment" ? "appointmentForm/add/addonservices/staff_id" : (form.values.form_types === "edit_appointment" ? "appointmentForm/edit/addonservices/staff_id" : "")
              }
              const obj = {
                ...form.values.addonservices[getFieldNameIndex],
                remove_addon: form?.values?.remove_addon || [],
                remove_addanother: form?.values?.remove_addanother || [],
                parent_id: form.values && form.values.id ? form.values.id : "",
                staff_id: option && option.value,
                dateof: form.values.dateof,
                duration: duration,
                padding_time: padding_time,
                durationWithPadding: durationWithPadding,
                parentAppointmentEndTime: parentAppointmentEndTime,
                listview: "Appointment",
                formtype: "appointmentform",
                formName,
                prevObj
              }
              const dateof = form.values.dateof && form.values.dateof ? moment(form.values.dateof, "dddd, DD MMMM YYYY").format("YYYY-MM-DD") + " " + moment().format("HH:mm:ss") : "";

              const addOnData = isAddOn.find((event) => event.id === indexID);
              let addonobj = { ...addOnData, serviceprice: action.payload, duration: serviceData.duration };
              dispatch(AddOnResourceOptionsApi({ option: { valueField: "id", labelField: "resource_item" }, service_id: obj.service_id, pagetype: "Booking", isNotInResourceIds: "", availebleTimeObj: { ...obj, dateof: dateof } })).then((actionResource) => {
                if (actionResource.meta.requestStatus === "fulfilled") {
                  if (addonobj) {
                    dispatch(AddOnData({ ...addonobj, resource_items: actionResource.payload }));
                  }
                } else {
                  dispatch(AddOnData(addonobj));
                }
              });
              AddonGetAvailableTime(obj, dispatch);
            }
            if (serviceData && serviceData.serviceprice) {
              let serviceprice = serviceData.serviceprice;
              let generalPrice = serviceprice;
              // let gprice = generalPrice && generalPrice.length === 1 ? generalPrice[0].price : "0.00";
              let add_on_price = generalPrice && generalPrice.length === 1 ? generalPrice[0].add_on_price : "0.00";
              // let totalprice = parseFloat(gprice) + parseFloat(add_on_price); //client need only gprice 16-12-2022
              let totalprice = parseFloat(add_on_price);
              form.setFieldValue(`${getFieldName[0]}.${getFieldName[1]}.cost`, String(totalprice));
            }

            // const addOnData = isAddOn.find((event) => event.id === indexID);
            // let addonobj = { ...addOnData, cost: service.duration };
            // dispatch(AddOnData(addonobj));
          }
        });
      } else {
        let formName = "";
        if (form.values && form.values.form_types) {
          formName = form.values.form_types === "add_appointment" ? "appointmentForm/add/addonservices/staff_id" : (form.values.form_types === "edit_appointment" ? "appointmentForm/edit/addonservices/staff_id" : "")
        }
        AddonGetAvailableTime({
          ...form.values.addonservices[getFieldNameIndex],
          remove_addon: form?.values?.remove_addon || [],
          remove_addanother: form?.values?.remove_addanother || [],
          parent_id: form.values && form.values.id ? form.values.id : "",
          staff_id: option && option.value,
          dateof: form.values.dateof,
          duration: "",
          padding_time: "",
          durationWithPadding: "",
          formName
        }, dispatch);
        form.setFieldValue(`${getFieldName[0]}.${getFieldName[1]}.resource_item_id`, "");
        // form.setFieldValue(`${getFieldName[0]}.${getFieldName[1]}.duration`, "");
        // form.setFieldValue(`${getFieldName[0]}.${getFieldName[1]}.padding_time`, "");
        // form.setFieldValue(`${getFieldName[0]}.${getFieldName[1]}.durationWithPadding`, "");
        form.setFieldValue(`${getFieldName[0]}.${getFieldName[1]}.cost`, "");
        form.setFieldTouched(`${getFieldName[0]}.${getFieldName[1]}.cost`, false);
      }
    }
    if (getFieldName.length == 3 && getFieldName.includes("addonservices") && getFieldName.includes("resource_item_id") && getControlId.includes("appointmentForm") && getControlId.includes("addonservices") && getControlId.includes("resource_item_id")) {
      if (option && option.value) {
        dispatch(
          AddOnServicePriceApi({
            parent_service_id: form.values.service_id,
            parent_staff_id: form.values.staff_id,
            service_id: form.values.addonservices[getFieldNameIndex].service_id,
            staff_id: form.values.addonservices[getFieldNameIndex].staff_id,
            index_id: getFieldNameIndex,
          }),
        ).then((action) => {
          if (action.meta.requestStatus === "fulfilled") {
            // const serviceData = action.payload;
            // const duration = serviceData.duration && serviceData.duration > 0 ? MinutesToHours(serviceData.duration) : "";
            // const padding_time = serviceData.padding_time && serviceData.padding_time > 0 ? MinutesToHours(serviceData.padding_time) : "";
            // const durationWithPadding = serviceData && serviceData.padding_time ? MinutesToHours(parseInt(serviceData.padding_time) + parseInt(serviceData.duration)) : (serviceData && serviceData.duration ? MinutesToHours(serviceData.duration) : "")

            const serviceData = action.payload;
            const serviceDataNew = form.values.addonservices[getFieldNameIndex];
            const duraitonMinuite = serviceDataNew && serviceDataNew.duration ? HoursToMinutes(serviceDataNew.duration) : 0;
            const duration = duraitonMinuite && duraitonMinuite > 0 ? MinutesToHours(duraitonMinuite) : "";
            const padding_time = serviceData.padding_time && serviceData.padding_time > 0 ? MinutesToHours(serviceData.padding_time) : "";
            const durationWithPadding = duraitonMinuite && serviceData && serviceData.padding_time ? MinutesToHours(parseInt(serviceData.padding_time) + parseInt(duraitonMinuite)) : (duraitonMinuite && duraitonMinuite > 0 ? MinutesToHours(serviceData.duration) : "")

            if (controlId === `appointmentForm-addonservices-${getFieldNameIndex}-resource_item_id`) {
              form.setFieldValue(`${getFieldName[0]}.${getFieldName[1]}.duration`, duration);
              form.setFieldValue(`${getFieldName[0]}.${getFieldName[1]}.padding_time`, padding_time);
              form.setFieldValue(`${getFieldName[0]}.${getFieldName[1]}.durationWithPadding`, durationWithPadding);

              // const addonservices = [...form.values.addonservices]; // Create a copy of the array
              // Create a new array based on the index key
              const addonservices = form.values.addonservices && form.values.addonservices.length > 0 ? form.values.addonservices.slice(0, getFieldNameIndex) : "";

              let parentAppointmentEndTime = "";
              if (addonservices && addonservices.length > 0) {
                const totalAddOnDurationWithPadding = addonservices && addonservices.length > 0 && addonservices.reduce((sum, sduration) => {
                  // Check if sduration.durationWithPadding is a valid duration
                  const duration = moment.duration(sduration.durationWithPadding);
                  if (duration.isValid()) {
                    const sumDurationWithPadding = moment.duration(sduration.durationWithPadding);
                    return sum.add(sumDurationWithPadding);
                  }
                  return sum;
                }, moment.duration(0));


                const parentAppointmentDuration = form.values.durationWithPadding;
                const duration1 = moment.duration(parentAppointmentDuration);
                const duration2 = totalAddOnDurationWithPadding;

                // Check if each duration is valid before using it
                const minutes1 = duration1 && duration1.isValid() ? duration1.asMinutes() : 0;
                const minutes2 = duration2 && duration2.isValid() ? duration2.asMinutes() : 0;

                const totalMinutes = minutes1 + minutes2;
                const hours = Math.floor(totalMinutes / 60);
                const minutes = totalMinutes % 60;

                const sumDuration = moment({ hours, minutes });
                const SumOfDuration = sumDuration ? sumDuration.format('HH:mm') : "";

                const durationMoment = moment.duration(SumOfDuration);
                const appointmentStartDateTime = moment(`${form.values.dateof} ${form.values.start_time}`, "dddd, DD MMMM YYYY HH:mm");
                parentAppointmentEndTime = appointmentStartDateTime.clone().add(durationMoment).format('YYYY-MM-DD HH:mm:00');

              } else {
                const appointmentStartDateTime = moment(`${form.values.dateof} ${form.values.start_time}`, "dddd, DD MMMM YYYY HH:mm");
                const durationMoment = moment.duration(form.values.durationWithPadding);
                parentAppointmentEndTime = appointmentStartDateTime.clone().add(durationMoment).format('YYYY-MM-DD HH:mm:00');
              }
              form.setFieldValue(`${getFieldName[0]}.${getFieldName[1]}.parentAppointmentEndTime`, parentAppointmentEndTime);
              let formName = "";
              if (form.values && form.values.form_types) {
                formName = form.values.form_types === "add_appointment" ? "appointmentForm/add/addonservices/resource_item_id" : (form.values.form_types === "edit_appointment" ? "appointmentForm/edit/addonservices/resource_item_id" : "")
              }
              AddonGetAvailableTime({
                ...form.values.addonservices[getFieldNameIndex],
                remove_addon: form?.values?.remove_addon || [],
                remove_addanother: form?.values?.remove_addanother || [],
                parent_id: form.values && form.values.id ? form.values.id : "",
                resource_item_id: option && option.value,
                dateof: form.values.dateof,
                duration: duration,
                padding_time: padding_time,
                durationWithPadding: durationWithPadding,
                parentAppointmentEndTime: parentAppointmentEndTime,
                listview: "Appointment",
                formName
              }, dispatch);
            }
            if (serviceData && serviceData.serviceprice) {
              let serviceprice = serviceData.serviceprice;
              let generalPrice = serviceprice;
              // let gprice = generalPrice && generalPrice.length === 1 ? generalPrice[0].price : "0.00";
              let add_on_price = generalPrice && generalPrice.length === 1 ? generalPrice[0].add_on_price : "0.00";
              // let totalprice = parseFloat(gprice) + parseFloat(add_on_price); //client need only gprice 16-12-2022
              let totalprice = parseFloat(add_on_price);
              form.setFieldValue(`${getFieldName[0]}.${getFieldName[1]}.cost`, String(totalprice));
            }

            // const addOnData = isAddOn.find((event) => event.id === indexID);
            // let addonobj = { ...addOnData, cost: service.duration };
            // dispatch(AddOnData(addonobj));
          }
        });
      } else {
        let formName = "";
        if (form.values && form.values.form_types) {
          formName = form.values.form_types === "add_appointment" ? "appointmentForm/add/addonservices/resource_item_id" : (form.values.form_types === "edit_appointment" ? "appointmentForm/edit/addonservices/resource_item_id" : "")
        }
        AddonGetAvailableTime({
          ...form.values.addonservices[getFieldNameIndex],
          parent_id: form.values && form.values.id ? form.values.id : "",
          resource_item_id: option && option.value,
          dateof: form.values.dateof,
          formName
          // parentAppointmentEndTime: parentAppointmentEndTime,
          // duration: "",
          // padding_time: "",
          // durationWithPadding: "",
        }, dispatch);
        // form.setFieldValue(`${getFieldName[0]}.${getFieldName[1]}.duration`, "");
        // form.setFieldValue(`${getFieldName[0]}.${getFieldName[1]}.padding_time`, "");
        // form.setFieldValue(`${getFieldName[0]}.${getFieldName[1]}.durationWithPadding`, "");
        // form.setFieldValue(`${getFieldName[0]}.${getFieldName[1]}.cost`, "");
        // form.setFieldTouched(`${getFieldName[0]}.${getFieldName[1]}.cost`, false);
      }
    }
    //Add another services;
    if (getFieldName.length == 3 && getFieldName.includes("addanotherservices") && getFieldName.includes("service_id") && getControlId.includes("appointmentForm") && getControlId.includes("addanotherservices") && getControlId.includes("service_id")) {
      if (option && option.value) {
        dispatch(AddAnotherServicePriceApi({ parent_service_id: form.values.service_id, parent_staff_id: form.values.staff_id, service_id: option && option.value, index_id: getFieldNameIndex })).then((action) => {
          if (action.meta.requestStatus === "fulfilled") {
            let serviceData = action.payload;
            if (serviceData && serviceData.deposit_booked_price && parseFloat(serviceData.deposit_booked_price) > 0) {
              dispatch(OpenDepositPayTab(true));
            }
            const duration = serviceData.duration && serviceData.duration > 0 ? MinutesToHours(serviceData.duration) : "";
            const padding_time = serviceData.padding_time && serviceData.padding_time > 0 ? MinutesToHours(serviceData.padding_time) : "";
            const durationWithPadding = serviceData && serviceData.padding_time ? MinutesToHours(parseInt(serviceData.padding_time) + parseInt(serviceData.duration)) : (serviceData && serviceData.duration ? MinutesToHours(serviceData.duration) : "")

            let currentTotalDeposit = 0;
            if (form.values.totaldeposit && parseFloat(form.values.totaldeposit) > 0) {
              currentTotalDeposit = parseFloat(form.values.totaldeposit);
            }
            // let currentTotalDeposit = parseFloat(form.values.totaldeposit);

            let newTotalDeposit = 0;
            if (controlId === `appointmentForm-addanotherservices-${getFieldNameIndex}-service_id`) {
              form.setFieldValue(`${getFieldName[0]}.${getFieldName[1]}.service_name`, serviceData.name);
              form.setFieldValue(`${getFieldName[0]}.${getFieldName[1]}.staff_id`, "");
              form.setFieldValue(`${getFieldName[0]}.${getFieldName[1]}.parent_id`, form?.values?.id ? form?.values?.id : null);
              form.setFieldValue(`${getFieldName[0]}.${getFieldName[1]}.resource_item_id`, "");
              form.setFieldValue(`${getFieldName[0]}.${getFieldName[1]}.duration`, duration);
              form.setFieldValue(`${getFieldName[0]}.${getFieldName[1]}.padding_time`, padding_time);
              form.setFieldValue(`${getFieldName[0]}.${getFieldName[1]}.durationWithPadding`, durationWithPadding);
              if (serviceData && serviceData.deposit_booked_online == 1) {
                const depositToAdd = parseFloat(serviceData.deposit_booked_price ?? 0);
                newTotalDeposit = (parseFloat(currentTotalDeposit) + parseFloat(depositToAdd)).toFixed(2);
                form.setFieldValue("totaldeposit", newTotalDeposit);
              } else {
                form.setFieldValue("totaldeposit", currentTotalDeposit);
              }

              let updatedServiceDeposit = [];
              if (form.values.service_deposit && form.values.service_deposit.length > 0) {
                updatedServiceDeposit = [
                  ...form.values.service_deposit,
                  { service_id: serviceData && serviceData.id, deposit_price: serviceData && serviceData.deposit_booked_price, service_type: "add_another", total_cost: serviceData && serviceData.serviceprice && serviceData.serviceprice.length > 0 ? serviceData.serviceprice[0].price : "0" },
                ];
              } else {
                updatedServiceDeposit = [
                  { service_id: serviceData && serviceData.id, deposit_price: serviceData && serviceData.deposit_booked_price, service_type: "add_another", total_cost: serviceData && serviceData.serviceprice && serviceData.serviceprice.length > 0 ? serviceData.serviceprice[0].price : "0" },
                ];
              }

              const addonservices = [...form.values.addonservices];
              const addanotherservices = [...form.values.addanotherservices];

              let parentAppointmentEndTime = "";
              let isNotInResourceIds = [];
              if (form.values.resource_item_id) {
                isNotInResourceIds.push(form.values.resource_item_id);
              }
              const totalAddOnDurationWithPadding = addonservices && addonservices.length > 0 && addonservices.reduce((sum, sduration) => {
                // Check if sduration.durationWithPadding is a valid duration
                const duration = moment.duration(sduration.durationWithPadding);

                if (duration.isValid()) {
                  const sumDurationWithPadding = moment.duration(sduration.durationWithPadding);
                  if (sduration.resource_item_id) {
                    isNotInResourceIds.push(sduration.resource_item_id);
                  }
                  return sum.add(sumDurationWithPadding);
                }
                return sum;
              }, moment.duration(0));
              // console.log('form',  addanotherservices);
              // if (addanotherservices && addanotherservices.length > 1) {
              //   addanotherservices.pop();

              if (addanotherservices && addanotherservices.length > 1) {
                const addanotherservicesFilter = addanotherservices.slice(0, getFieldNameIndex);
                //console.log('addanotherservicesFilter', getFieldNameIndex, addanotherservicesFilter);
                const totalAddAnotherDurationWithPadding = addanotherservicesFilter.length > 0 && addanotherservicesFilter.reduce((sum, sduration) => {
                  // Check if sduration.durationWithPadding is a valid duration
                  const duration = moment.duration(sduration.durationWithPadding);
                  if (duration.isValid()) {
                    const sumDurationWithPadding = moment.duration(sduration.durationWithPadding);
                    if (sduration.resource_item_id) {
                      isNotInResourceIds.push(sduration.resource_item_id);
                    }
                    return sum.add(sumDurationWithPadding);
                  }
                  return sum;
                }, moment.duration(0));

                const parentAppointmentDuration = form.values.durationWithPadding;
                const duration1 = moment.duration(parentAppointmentDuration);
                const duration2 = totalAddOnDurationWithPadding;
                const duration3 = totalAddAnotherDurationWithPadding;

                // Check if each duration is valid before using it
                const minutes1 = duration1 && duration1.isValid() ? duration1.asMinutes() : 0;
                const minutes2 = duration2 && duration2.isValid() ? duration2.asMinutes() : 0;
                const minutes3 = duration3 && duration3.isValid() ? duration3.asMinutes() : 0;

                const totalMinutes = minutes1 + minutes2 + minutes3;
                const hours = Math.floor(totalMinutes / 60);
                const minutes = totalMinutes % 60;

                const sumDuration = moment({ hours, minutes });
                const SumOfDuration = sumDuration ? sumDuration.format('HH:mm') : "";

                const durationMoment = moment.duration(SumOfDuration);

                const appointmentStartDateTime = moment(`${form.values.dateof} ${form.values.start_time}`, "dddd, DD MMMM YYYY HH:mm");
                parentAppointmentEndTime = appointmentStartDateTime.clone().add(durationMoment).format('YYYY-MM-DD HH:mm:00');

              } else {
                const appointmentStartDateTime = moment(`${form.values.dateof} ${form.values.start_time}`, "dddd, DD MMMM YYYY HH:mm");
                const duration1 = moment.duration(form.values.durationWithPadding);
                const duration2 = totalAddOnDurationWithPadding;
                // Check if each duration is valid before using it
                const minutes1 = duration1 && duration1.isValid() ? duration1.asMinutes() : 0;
                const minutes2 = duration2 && duration2.isValid() ? duration2.asMinutes() : 0;

                const totalMinutes = minutes1 + minutes2;
                const hours = Math.floor(totalMinutes / 60);
                const minutes = totalMinutes % 60;

                const sumDuration = moment({ hours, minutes });
                const SumOfDuration = sumDuration ? sumDuration.format('HH:mm') : "";

                const durationMoment = moment.duration(SumOfDuration);
                parentAppointmentEndTime = appointmentStartDateTime.clone().add(durationMoment).format('YYYY-MM-DD HH:mm:00');
              }
              let formName = "";
              if (form.values && form.values.form_types) {
                formName = form.values.form_types === "add_appointment" ? "appointmentForm/add/addanotherservices/service_id" : (form.values.form_types === "edit_appointment" ? "appointmentForm/edit/addanotherservices/service_id" : "")
              }
              const obj = {
                ...form.values.addanotherservices[getFieldNameIndex],
                remove_addon: form?.values?.remove_addon || [],
                remove_addanother: form?.values?.remove_addanother || [],
                parent_id: form.values && form.values.id ? form.values.id : "",
                service_id: option && option.value,
                staff_id: "",
                dateof: form.values.dateof,
                parentAppointmentEndTime: parentAppointmentEndTime,
                duration: duration,
                padding_time: padding_time,
                durationWithPadding: durationWithPadding,
                listview: "Appointment",
                formName
              }

              const dateof = form.values.dateof && form.values.dateof ? moment(form.values.dateof, "dddd, DD MMMM YYYY").format("YYYY-MM-DD") + " " + moment().format("HH:mm:ss") : "";


              form.setFieldValue("service_deposit", updatedServiceDeposit);
              AddAnotherGetAvailableTime({
                ...form.values.addanotherservices[getFieldNameIndex],
                remove_addon: form?.values?.remove_addon || [],
                remove_addanother: form?.values?.remove_addanother || [],
                parent_id: form.values && form.values.id ? form.values.id : "",
                service_id: option && option.value,
                dateof: form.values.dateof,
                duration: duration,
                padding_time: padding_time,
                durationWithPadding: durationWithPadding,
                listview: "Appointment",
                formName
              }, dispatch);


              const addAnotherData = isAddAnother.find((event) => event.id === indexID);
              let addanotherobj = { ...addAnotherData, serviceprice: action.payload, duration: serviceData.duration };
              dispatch(AddAnotherStaffOptionsApi({ option: { valueField: "users.id", labelField: "CONCAT(users.first_name,' ',users.last_name)" }, service_id: serviceData.id, pagetype: "Booking", availebleTimeObj: { ...obj, dateof: dateof } })).then((action1) => {
                if (action1.meta.requestStatus === "fulfilled") {
                  if (addanotherobj) {
                    dispatch(AddAnotherData({ ...addanotherobj, staff: action1.payload }));
                  }
                } else {
                  dispatch(AddAnotherData(addanotherobj));
                }
              });
              // const addResourceOptionsAction = dispatch(AddAnotherResourceOptionsApi({ option: { valueField: "id", labelField: "resource_item" }, service_id: option && option.value, pagetype: "Booking" }));
              // const addStaffOptionsAction = dispatch(AddAnotherStaffOptionsApi({ option: { valueField: "users.id", labelField: "CONCAT(users.first_name,' ',users.last_name)" }, service_id: serviceData.id, pagetype: "Booking" }));
              // Promise.all([addResourceOptionsAction, addStaffOptionsAction])
              //   .then(([resources, action1]) => {
              //     if (resources.meta.requestStatus === "fulfilled" && action1.meta.requestStatus === "fulfilled") {
              //       if (addanotherobj) {
              //         dispatch(AddAnotherData({ ...addanotherobj, resource_items: resources.payload, staff: action1.payload }));
              //       }
              //     } else {
              //       dispatch(AddAnotherData(addanotherobj));
              //     }
              //   })
              //   .catch((error) => {
              //     console.error("Error:", error);
              //   });
              // without promise.all method resource_items not pushing code
              // dispatch(AddAnotherResourceOptionsApi({ option: { valueField: "id", labelField: "resource_item" }, service_id: option && option.value,pagetype: "Booking"})).then((resources) => {
              //   console.log("resources",resources)
              //   if (resources.meta.requestStatus === "fulfilled") {
              //     if (addanotherobj) {
              //       dispatch(AddAnotherData({ ...addanotherobj, resource_items: resources.payload }));
              //     }
              //   } else {
              //     dispatch(AddAnotherData(addanotherobj));
              //   }
              // });
              // dispatch(AddAnotherStaffOptionsApi({ option: { valueField: "users.id", labelField: "CONCAT(users.first_name,' ',users.last_name)" }, service_id: serviceData.id, pagetype: "Booking" })).then((action1) => {
              //   if (action1.meta.requestStatus === "fulfilled") {
              //     if (addanotherobj) {
              //       dispatch(AddAnotherData({ ...addanotherobj, staff: action1.payload }));
              //     }
              //   } else {
              //     dispatch(AddAnotherData(addanotherobj));
              //   }
              // });
            }
          }
        });
      } else {
        if (controlId) {
          form.setFieldValue(`${getFieldName[0]}.${getFieldName[1]}.staff_id`, "");
          form.setFieldValue(`${getFieldName[0]}.${getFieldName[1]}.resource_item_id`, "");
          form.setFieldValue(`${getFieldName[0]}.${getFieldName[1]}.duration`, "");
          form.setFieldValue(`${getFieldName[0]}.${getFieldName[1]}.padding_time`, "");
          form.setFieldValue(`${getFieldName[0]}.${getFieldName[1]}.durationWithPadding`, "");
          form.setFieldValue(`${getFieldName[0]}.${getFieldName[1]}.cost`, "");
          form.setFieldTouched(`${getFieldName[0]}.${getFieldName[1]}.cost`, false);
        }
        dispatch(AddAnotherResourceOptionsApi({ option: { valueField: "id", labelField: "resource_item" }, pagetype: "Booking" }))
        dispatch(AddAnotherStaffOptionsApi({ option: { valueField: "users.id", labelField: "CONCAT(users.first_name,' ',users.last_name)" }, pagetype: "Booking" }));

        // AddAnotherGetAvailableTime({
        //   ...form.values.addanotherservices[getFieldNameIndex],
        //   parent_id: form.values && form.values.id ? form.values.id : "",
        //   service_id: option && option.value,
        //   dateof: form.values.dateof,
        //   duration: "",
        //   padding_time: "",
        //   durationWithPadding: "",
        // }, dispatch);
      }
    }
    if (getFieldName.length == 3 && getFieldName.includes("addanotherservices") && getFieldName.includes("staff_id") && getControlId.includes("appointmentForm") && getControlId.includes("addanotherservices") && getControlId.includes("staff_id")) {
      if (option && option.value) {
        dispatch(
          AddAnotherServicePriceApi({
            parent_service_id: form.values.service_id,
            parent_staff_id: form.values.staff_id,
            service_id: form.values.addanotherservices[getFieldNameIndex].service_id,
            staff_id: option && option.value,
            index_id: getFieldNameIndex,
          }),
        ).then((action) => {
          if (action.meta.requestStatus === "fulfilled") {
            const serviceData = action.payload;
            const prevObj = form.values.addanotherservices[getFieldNameIndex]?.prevObj;

            // const duration = serviceData.duration && serviceData.duration > 0 ? MinutesToHours(serviceData.duration) : "";
            // const padding_time = serviceData.padding_time && serviceData.padding_time > 0 ? MinutesToHours(serviceData.padding_time) : "";
            // const durationWithPadding = serviceData && serviceData.padding_time ? MinutesToHours(parseInt(serviceData.padding_time) + parseInt(serviceData.duration)) : (serviceData && serviceData.duration ? MinutesToHours(serviceData.duration) : "")
            const duration = form.values.addanotherservices[getFieldNameIndex].duration && /^\d{1,2}:\d{2}$/.test(form.values.addanotherservices[getFieldNameIndex].duration) ? form.values.addanotherservices[getFieldNameIndex].duration : "";
            const padding_time = form.values.addanotherservices[getFieldNameIndex].padding_time && /^\d{1,2}:\d{2}$/.test(form.values.addanotherservices[getFieldNameIndex].padding_time) > 0 ? form.values.addanotherservices[getFieldNameIndex].padding_time : "";
            let durationWithPadding = duration;
            if (padding_time) {
              const duration1 = moment.duration(duration);
              const duration2 = moment.duration(padding_time);

              const totalMinutes = duration1.asMinutes() + duration2.asMinutes();
              const hours = Math.floor(totalMinutes / 60);
              const minutes = totalMinutes % 60;

              const sumDuration = moment({ hours, minutes });
              const SumOfDuration = sumDuration ? sumDuration.format('HH:mm') : "";

              // const durationMoment = moment.duration(SumOfDuration);
              durationWithPadding = SumOfDuration
            }
            // const duration = service && service.duration ? MinutesToHours(service.duration) : "";
            if (controlId === `appointmentForm-addanotherservices-${getFieldNameIndex}-staff_id`) {
              if (prevObj && prevObj.staff_id == option.value) {
                form.setFieldValue(`${getFieldName[0]}.${getFieldName[1]}.resource_item_id`, prevObj.resource_item_id);
                form.values.addanotherservices[getFieldNameIndex].resource_item_id = prevObj.resource_item_id;
              } else {
                form.setFieldValue(`${getFieldName[0]}.${getFieldName[1]}.resource_item_id`, "");
                form.values.addanotherservices[getFieldNameIndex].resource_item_id = "";
              }

              form.setFieldValue(`${getFieldName[0]}.${getFieldName[1]}.duration`, duration);
              form.setFieldValue(`${getFieldName[0]}.${getFieldName[1]}.padding_time`, padding_time);
              form.setFieldValue(`${getFieldName[0]}.${getFieldName[1]}.durationWithPadding`, durationWithPadding);
              // console.log('form', form.values, duration);

              const addonservices = [...form.values.addonservices];
              const addanotherservices = [...form.values.addanotherservices];

              let parentAppointmentEndTime = "";
              let isNotInResourceIds = [];
              if (form.values.resource_item_id) {
                isNotInResourceIds.push(form.values.resource_item_id);
              }
              const totalAddOnDurationWithPadding = addonservices && addonservices.length > 0 && addonservices.reduce((sum, sduration) => {
                // Check if sduration.durationWithPadding is a valid duration
                const duration = moment.duration(sduration.durationWithPadding);

                if (duration.isValid()) {
                  const sumDurationWithPadding = moment.duration(sduration.durationWithPadding);
                  if (sduration.resource_item_id) {
                    isNotInResourceIds.push(sduration.resource_item_id);
                  }
                  return sum.add(sumDurationWithPadding);
                }
                return sum;
              }, moment.duration(0));
              // console.log('form',  addanotherservices);
              // if (addanotherservices && addanotherservices.length > 1) {
              //   addanotherservices.pop();

              if (addanotherservices && addanotherservices.length > 1) {
                const addanotherservicesFilter = addanotherservices.slice(0, getFieldNameIndex);
                //console.log('addanotherservicesFilter', getFieldNameIndex, addanotherservicesFilter);
                const totalAddAnotherDurationWithPadding = addanotherservicesFilter.length > 0 && addanotherservicesFilter.reduce((sum, sduration) => {
                  // Check if sduration.durationWithPadding is a valid duration
                  const duration = moment.duration(sduration.durationWithPadding);
                  if (duration.isValid()) {
                    const sumDurationWithPadding = moment.duration(sduration.durationWithPadding);
                    if (sduration.resource_item_id) {
                      isNotInResourceIds.push(sduration.resource_item_id);
                    }
                    return sum.add(sumDurationWithPadding);
                  }
                  return sum;
                }, moment.duration(0));

                const parentAppointmentDuration = form.values.durationWithPadding;
                const duration1 = moment.duration(parentAppointmentDuration);
                const duration2 = totalAddOnDurationWithPadding;
                const duration3 = totalAddAnotherDurationWithPadding;

                // Check if each duration is valid before using it
                const minutes1 = duration1 && duration1.isValid() ? duration1.asMinutes() : 0;
                const minutes2 = duration2 && duration2.isValid() ? duration2.asMinutes() : 0;
                const minutes3 = duration3 && duration3.isValid() ? duration3.asMinutes() : 0;

                const totalMinutes = minutes1 + minutes2 + minutes3;
                const hours = Math.floor(totalMinutes / 60);
                const minutes = totalMinutes % 60;

                const sumDuration = moment({ hours, minutes });
                const SumOfDuration = sumDuration ? sumDuration.format('HH:mm') : "";

                const durationMoment = moment.duration(SumOfDuration);

                const appointmentStartDateTime = moment(`${form.values.dateof} ${form.values.start_time}`, "dddd, DD MMMM YYYY HH:mm");
                parentAppointmentEndTime = appointmentStartDateTime.clone().add(durationMoment).format('YYYY-MM-DD HH:mm:00');

              } else {
                const appointmentStartDateTime = moment(`${form.values.dateof} ${form.values.start_time}`, "dddd, DD MMMM YYYY HH:mm");
                const duration1 = moment.duration(form.values.durationWithPadding);
                const duration2 = totalAddOnDurationWithPadding;
                // Check if each duration is valid before using it
                const minutes1 = duration1 && duration1.isValid() ? duration1.asMinutes() : 0;
                const minutes2 = duration2 && duration2.isValid() ? duration2.asMinutes() : 0;

                const totalMinutes = minutes1 + minutes2;
                const hours = Math.floor(totalMinutes / 60);
                const minutes = totalMinutes % 60;

                const sumDuration = moment({ hours, minutes });
                const SumOfDuration = sumDuration ? sumDuration.format('HH:mm') : "";

                const durationMoment = moment.duration(SumOfDuration);
                parentAppointmentEndTime = appointmentStartDateTime.clone().add(durationMoment).format('YYYY-MM-DD HH:mm:00');
              }
              let formName = "";
              if (form.values && form.values.form_types) {
                formName = form.values.form_types === "add_appointment" ? "appointmentForm/add/addanotherservices/staff_id" : (form.values.form_types === "edit_appointment" ? "appointmentForm/edit/addanotherservices/staff_id" : "")
              }

              const obj = {
                ...form.values.addanotherservices[getFieldNameIndex],
                remove_addon: form?.values?.remove_addon || [],
                remove_addanother: form?.values?.remove_addanother || [],
                parent_id: form.values && form.values.id ? form.values.id : "",
                staff_id: option && option.value,
                dateof: form.values.dateof,
                parentAppointmentEndTime: parentAppointmentEndTime,
                duration: duration,
                padding_time: padding_time,
                durationWithPadding: durationWithPadding,
                listview: "Appointment",
                formName,
                prevObj
              }

              const dateof = form.values.dateof && form.values.dateof ? moment(form.values.dateof, "dddd, DD MMMM YYYY").format("YYYY-MM-DD") + " " + moment().format("HH:mm:ss") : "";

              const addAnotherData = isAddAnother.find((event) => event.id === indexID);

              let addanotherobj = { ...addAnotherData, serviceprice: action.payload, duration: serviceData.duration };
              dispatch(AddAnotherResourceOptionsApi({ option: { valueField: "id", labelField: "resource_item" }, service_id: obj.service_id, pagetype: "Booking", isNotInResourceIds: "", availebleTimeObj: { ...obj, dateof: dateof } })).then((actionResource) => {
                if (actionResource.meta.requestStatus === "fulfilled") {
                  if (addAnotherData) {
                    dispatch(AddAnotherData({ ...addAnotherData, resource_items: actionResource.payload }));
                  }
                } else {
                  dispatch(AddAnotherData(addanotherobj));
                }
              });
              AddAnotherGetAvailableTime(obj, dispatch);
            }

            if (serviceData && serviceData.serviceprice) {
              let serviceprice = serviceData.serviceprice;
              let generalPrice = serviceprice;
              let gprice = generalPrice && generalPrice.length === 1 ? generalPrice[0].price : "0.00";
              // let add_on_price = generalPrice && generalPrice.length === 1 ? generalPrice[0].add_another_price : "0.00";
              // let totalprice = parseFloat(gprice) + parseFloat(add_on_price); //client need only gprice 16-12-2022
              let totalprice = parseFloat(gprice);
              form.setFieldValue(`${getFieldName[0]}.${getFieldName[1]}.cost`, String(totalprice));
            }
            // const addAnotherData = isAddAnother.find((event) => event.id === indexID);
            // let addanotherobj = { ...addAnotherData, cost: service.duration };
            // dispatch(AddAnotherData(addanotherobj));
          }
        });
      } else {
        let formName = "";
        if (form.values && form.values.form_types) {
          formName = form.values.form_types === "add_appointment" ? "appointmentForm/add/addanotherservices/staff_id" : (form.values.form_types === "edit_appointment" ? "appointmentForm/edit/addanotherservices/staff_id" : "")
        }
        AddAnotherGetAvailableTime({
          ...form.values.addanotherservices[getFieldNameIndex],
          remove_addon: form?.values?.remove_addon || [],
          remove_addanother: form?.values?.remove_addanother || [],
          parent_id: form.values && form.values.id ? form.values.id : "",
          staff_id: option && option.value,
          dateof: form.values.dateof,
          duration: "",
          padding_time: "",
          durationWithPadding: "",
          formName
        }, dispatch);
        // form.setFieldValue(`${getFieldName[0]}.${getFieldName[1]}.duration`, "");
        // form.setFieldValue(`${getFieldName[0]}.${getFieldName[1]}.padding_time`, "");
        // form.setFieldValue(`${getFieldName[0]}.${getFieldName[1]}.durationWithPadding`, "");
        form.setFieldValue(`${getFieldName[0]}.${getFieldName[1]}.cost`, "");
        form.setFieldTouched(`${getFieldName[0]}.${getFieldName[1]}.cost`, false);
      }
    }
    if (getFieldName.length == 3 && getFieldName.includes("addanotherservices") && getFieldName.includes("resource_item_id") && getControlId.includes("appointmentForm") && getControlId.includes("addanotherservices") && getControlId.includes("resource_item_id")) {
      if (option && option.value) {
        dispatch(
          AddAnotherServicePriceApi({
            parent_service_id: form.values.service_id,
            parent_staff_id: form.values.staff_id,
            service_id: form.values.addanotherservices[getFieldNameIndex].service_id,
            staff_id: form.values.addanotherservices[getFieldNameIndex].staff_id,
            index_id: getFieldNameIndex,
          }),
        ).then((action) => {
          if (action.meta.requestStatus === "fulfilled") {
            const serviceData = action.payload;
            const serviceDataNew = form.values.addanotherservices[getFieldNameIndex];
            const duraitonMinuite = serviceDataNew && serviceDataNew.duration ? HoursToMinutes(serviceDataNew.duration) : 0;
            const duration = duraitonMinuite && duraitonMinuite > 0 ? MinutesToHours(duraitonMinuite) : "";
            const padding_time = serviceData.padding_time && serviceData.padding_time > 0 ? MinutesToHours(serviceData.padding_time) : "";
            const durationWithPadding = duraitonMinuite && serviceData && serviceData.padding_time ? MinutesToHours(parseInt(serviceData.padding_time) + parseInt(duraitonMinuite)) : (duraitonMinuite && duraitonMinuite > 0 ? MinutesToHours(serviceData.duration) : "")
            // console.log('serviceData',duration,padding_time,durationWithPadding);
            // const duration = service && service.duration ? MinutesToHours(service.duration) : "";
            if (controlId === `appointmentForm-addanotherservices-${getFieldNameIndex}-resource_item_id`) {
              form.setFieldValue(`${getFieldName[0]}.${getFieldName[1]}.duration`, duration);
              form.setFieldValue(`${getFieldName[0]}.${getFieldName[1]}.padding_time`, padding_time);
              form.setFieldValue(`${getFieldName[0]}.${getFieldName[1]}.durationWithPadding`, durationWithPadding);
              // console.log('form', form.values, duration);

              const addonservices = [...form.values.addonservices];
              const addanotherservices = [...form.values.addanotherservices];

              let parentAppointmentEndTime = "";

              const totalAddOnDurationWithPadding = addonservices && addonservices.length > 0 && addonservices.reduce((sum, sduration) => {
                // Check if sduration.durationWithPadding is a valid duration
                const duration = moment.duration(sduration.durationWithPadding);

                if (duration.isValid()) {
                  const sumDurationWithPadding = moment.duration(sduration.durationWithPadding);
                  return sum.add(sumDurationWithPadding);
                }
                return sum;
              }, moment.duration(0));
              // console.log('form',  addanotherservices);
              // if (addanotherservices && addanotherservices.length > 1) {
              //   addanotherservices.pop();

              if (addanotherservices && addanotherservices.length > 1) {
                const addanotherservicesFilter = addanotherservices.slice(0, getFieldNameIndex);
                //console.log('addanotherservicesFilter', getFieldNameIndex, addanotherservicesFilter);
                const totalAddAnotherDurationWithPadding = addanotherservicesFilter.length > 0 && addanotherservicesFilter.reduce((sum, sduration) => {
                  // Check if sduration.durationWithPadding is a valid duration
                  const duration = moment.duration(sduration.durationWithPadding);
                  if (duration.isValid()) {
                    const sumDurationWithPadding = moment.duration(sduration.durationWithPadding);
                    return sum.add(sumDurationWithPadding);
                  }
                  return sum;
                }, moment.duration(0));

                const parentAppointmentDuration = form.values.durationWithPadding;
                const duration1 = moment.duration(parentAppointmentDuration);
                const duration2 = totalAddOnDurationWithPadding;
                const duration3 = totalAddAnotherDurationWithPadding;

                // Check if each duration is valid before using it
                const minutes1 = duration1 && duration1.isValid() ? duration1.asMinutes() : 0;
                const minutes2 = duration2 && duration2.isValid() ? duration2.asMinutes() : 0;
                const minutes3 = duration3 && duration3.isValid() ? duration3.asMinutes() : 0;

                const totalMinutes = minutes1 + minutes2 + minutes3;
                const hours = Math.floor(totalMinutes / 60);
                const minutes = totalMinutes % 60;

                const sumDuration = moment({ hours, minutes });
                const SumOfDuration = sumDuration ? sumDuration.format('HH:mm') : "";

                const durationMoment = moment.duration(SumOfDuration);

                const appointmentStartDateTime = moment(`${form.values.dateof} ${form.values.start_time}`, "dddd, DD MMMM YYYY HH:mm");
                parentAppointmentEndTime = appointmentStartDateTime.clone().add(durationMoment).format('YYYY-MM-DD HH:mm:00');

              } else {
                const appointmentStartDateTime = moment(`${form.values.dateof} ${form.values.start_time}`, "dddd, DD MMMM YYYY HH:mm");
                const duration1 = moment.duration(form.values.durationWithPadding);
                const duration2 = totalAddOnDurationWithPadding;
                // Check if each duration is valid before using it
                const minutes1 = duration1 && duration1.isValid() ? duration1.asMinutes() : 0;
                const minutes2 = duration2 && duration2.isValid() ? duration2.asMinutes() : 0;

                const totalMinutes = minutes1 + minutes2;
                const hours = Math.floor(totalMinutes / 60);
                const minutes = totalMinutes % 60;

                const sumDuration = moment({ hours, minutes });
                const SumOfDuration = sumDuration ? sumDuration.format('HH:mm') : "";

                const durationMoment = moment.duration(SumOfDuration);
                parentAppointmentEndTime = appointmentStartDateTime.clone().add(durationMoment).format('YYYY-MM-DD HH:mm:00');
              }

              let formName = "";
              if (form.values && form.values.form_types) {
                formName = form.values.form_types === "add_appointment" ? "appointmentForm/add/addanotherservices/resource_item_id" : (form.values.form_types === "edit_appointment" ? "appointmentForm/edit/addanotherservices/resource_item_id" : "")
              }
              AddAnotherGetAvailableTime({
                ...form.values.addanotherservices[getFieldNameIndex],
                remove_addon: form?.values?.remove_addon || [],
                remove_addanother: form?.values?.remove_addanother || [],
                parent_id: form.values && form.values.id ? form.values.id : "",
                resource_item_id: option && option.value,
                dateof: form.values.dateof,
                parentAppointmentEndTime: parentAppointmentEndTime,
                duration: duration,
                padding_time: padding_time,
                durationWithPadding: durationWithPadding,
                listview: "Appointment",
                formName
              }, dispatch);
            }
            if (serviceData && serviceData.serviceprice) {
              let serviceprice = serviceData.serviceprice;
              let generalPrice = serviceprice;
              let gprice = generalPrice && generalPrice.length === 1 ? generalPrice[0].price : "0.00";
              // let add_on_price = generalPrice && generalPrice.length === 1 ? generalPrice[0].add_another_price : "0.00";
              // let totalprice = parseFloat(gprice) + parseFloat(add_on_price); //client need only gprice 16-12-2022
              let totalprice = parseFloat(gprice);
              form.setFieldValue(`${getFieldName[0]}.${getFieldName[1]}.cost`, String(totalprice));
            }
            // const addAnotherData = isAddAnother.find((event) => event.id === indexID);
            // let addanotherobj = { ...addAnotherData, cost: service.duration };
            // dispatch(AddAnotherData(addanotherobj));
          }
        });
      } else {
        let formName = "";
        if (form.values && form.values.form_types) {
          formName = form.values.form_types === "add_appointment" ? "appointmentForm/add/addanotherservices/resource_item_id" : (form.values.form_types === "edit_appointment" ? "appointmentForm/edit/addanotherservices/resource_item_id" : "")
        }
        AddAnotherGetAvailableTime({
          ...form.values.addanotherservices[getFieldNameIndex],
          parent_id: form.values && form.values.id ? form.values.id : "",
          resource_item_id: option && option.value,
          dateof: form.values.dateof,
          formName
          // duration: "",
          // padding_time: "",
          // durationWithPadding: "",
        }, dispatch);
        // form.setFieldValue(`${getFieldName[0]}.${getFieldName[1]}.resource_item_id`, "");
        // form.setFieldValue(`${getFieldName[0]}.${getFieldName[1]}.padding_time`, "");
        // form.setFieldValue(`${getFieldName[0]}.${getFieldName[1]}.durationWithPadding`, "");
        // form.setFieldValue(`${getFieldName[0]}.${getFieldName[1]}.cost`, "");
        // form.setFieldTouched(`${getFieldName[0]}.${getFieldName[1]}.cost`, false);
      }
    }

    if (field.name === "form_id" && controlId === "consulationForm-form_id") {
      let form_url = option ? config.form_url + "" + option.uid : "";
      form.setFieldValue(`form_url`, form_url);
    }
    if (field.name === "form_id" && controlId === "appointmentcampaignForm-form_id") {
      let form_url = option ? config.form_url + "" + option.uid : "";
      form.setFieldValue(`form_url`, form_url);
      const updatedDescription = form.values && form.values.description.replace(/http[s]?:\/\/\S+/g, '');
      form.setFieldValue(`description`, updatedDescription + ' ' + form_url);
    }
    if (field.name === "form_id" && controlId === "notifyForm-form_id") {
      let form_url = option ? config.form_url + "" + option.uid : "";
      form.setFieldValue(`form_url`, form_url);
      const updatedDescription = form.values && form.values.sms_template.replace(/http[s]?:\/\/\S+/g, '');
      form.setFieldValue(`sms_template`, updatedDescription + ' ' + form_url);
    }
    if (option) {
      form.setFieldValue(field.name, isMulti ? option.map((item) => item.value) : option.value);
    } else {
      form.setFieldValue(field.name, isMulti ? "" : "");
    }
  };

  const getValue = () => {
    if (options) {
      if (field.value == "") {
        return isMulti ? [] : "";
      }
      return isMulti ? options.filter((option) => field.value.indexOf(option.value) >= 0) : options.find((option) => option.value === field.value);
    } else {
      return isMulti ? [] : "";
    }
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "#fff" : "",
      borderWidth: state.isFocused ? "0" : "0",
    }),
    control: (provided, state) => ({
      ...provided,
      borderWidth: state.isFocused ? "0px" : "1px",
    }),
    menuPortal: (provided) => ({ ...provided, zIndex: 3 }),
    menu: (provided) => ({ ...provided, zIndex: 3 }),
  };
  return (
    <>
      <Select
        ref={selectRef}
        className={className}
        id={controlId}
        name={field.name}
        value={getValue()}
        onChange={onChange}
        placeholder={placeholder}
        options={options ? options : []}
        isMulti={isMulti}
        isClearable
        styles={customStyles}
        noOptionsMessage={noOptionsMessage}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            border: "1px",
            primary25: "#F4EEEB",
            primary: "#8C1C4D",
          },
        })}
      />
    </>
  );
};

CustomSelect.propTypes = {
  page_name: PropTypes.string,
  singlestaffaccess: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  className: PropTypes.string,
  placeholder: PropTypes.string,
  field: PropTypes.object,
  form: PropTypes.object,
  options: PropTypes.oneOfType([PropTypes.node, PropTypes.array, PropTypes.object]),
  isMulti: PropTypes.bool,
  controlId: PropTypes.string,
  service_id: PropTypes.oneOfType([PropTypes.node, PropTypes.array, PropTypes.object]),
  resource_item_id: PropTypes.oneOfType([PropTypes.node, PropTypes.array, PropTypes.object]),
  page: PropTypes.oneOfType([PropTypes.node, PropTypes.array, PropTypes.object]),
  isAddOn: PropTypes.oneOfType([PropTypes.node, PropTypes.array, PropTypes.object]),
  isAddAnother: PropTypes.oneOfType([PropTypes.node, PropTypes.array, PropTypes.object]),
  indexID: PropTypes.number,
};

export default CustomSelect;